import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/favicon-new.png"
import { axiosInstance, serverBaseUrl } from "ConfigAxioxinstance"
import { humanize } from "constants/common"
import Loader from "common/Loader"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
const ForgotPasswordPage = props => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [allowResend, setAllowResend] = useState(true)
  const [otpTimer, setOtpTimer] = useState()
  const [userEmail, setUserEmail] = useState("")
  const [otp, setOtp] = useState(null)

  const sendOtp = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`forgot-password`, {
        email: userEmail,
      })
      if (response && response.data.otp_sent) {
        toast.success("OTP Sent Succesfully!!")
        setIsOtpSent(true)
        setAllowResend(false)
        setOtpTimer(30)
      }
    } catch (error) {
      const msg = humanize(error.response.data.message) || error.message
      // toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  const verifyOtp = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`forgot-password`, {
        email: userEmail,
        otp,
      })
      if (response && response.data.otp_verified) {
        sessionStorage.setItem("authUser", JSON.stringify(response?.data))
        sessionStorage.setItem("user_token", response?.data?.accessToken)
        sessionStorage.setItem("user", response?.data?.user?.permissions)
        sessionStorage.setItem("userId", response?.data?.user?.id)
        const userInfo = response?.data?.user
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo))

        setIsOtpSent(false)
        setOtp(null)
        toast.success("OTP verified Succesfully!!")
        history.push("/change-password")
      }
    } catch (error) {
      setIsOtpSent(true)
      const msg = humanize(error.response.data.message) || error.message
      // toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOtpSent) {
      if (!otpTimer) {
        setAllowResend(true)
        return
      }
      setTimeout(() => {
        setOtpTimer(otpTimer - 1)
      }, 1000)
    }
  }, [otpTimer])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <MetaTags>
        <title>Forget Password | Earnnest-Admin</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password !</h5>
                        <p>You will receive an OTP to reset your password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {!isOtpSent ? (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={sendOtp}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter your email"
                            type="email"
                            onChange={e => {
                              setUserEmail(e.target.value)
                            }}
                            required
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    ) : null}

                    {isOtpSent ? (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={verifyOtp}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            disabled={true}
                            value={userEmail}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="otp"
                            label="OTP"
                            className="form-control"
                            placeholder="Enter OTP"
                            type="text"
                            maxLength={6}
                            inputmode="numeric"
                            required={true}
                            onChange={e => {
                              setOtp(e.target.value)
                            }}
                            errorMessage={"Invalid Otp"}
                          />
                        </div>
                        <Row className="mb-3">
                          <Col>
                            <button
                              className={"btn w-md btn-primary"}
                              type="button"
                              onClick={sendOtp}
                              disabled={allowResend ? false : true}
                            >
                              Resend OTP
                            </button>
                            <span className="ms-2 text-secondary">
                              {otpTimer ? otpTimer + "s" : null}
                            </span>
                          </Col>
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Verify
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Earnnest</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgotPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgotPasswordPage)
