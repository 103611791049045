import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import React from "react"
import { useHistory } from "react-router-dom"

const Home = () => {
  const history = useHistory()
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const distributorPortalAccessRestriction = DealManagementAccess(
    AccessId?.DISTRIBUTOR_PORTAL
  )
  const dashboardAccessRestriction = DealManagementAccess(AccessId?.DASHBOARD)

  // if (
  //   userInfo?.permissions === "OPS_USER" &&
  //   userInfo?.role?.department === "Distributor" &&
  //   distributorPortalAccessRestriction >= 1
  // ) {
  //   history.replace("/my-dashboard")
  // } else if (
  //   dashboardAccessRestriction >= 1 ||
  //   dashboardAccessRestriction == "SuperAdmin"
  // ) {
  //   history.replace("/dashboard")
  // }
  return null
}

export default Home
