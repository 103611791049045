import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  csvDownloadData,
  distributorUsers,
  getImgUrl,
  picUrl,
} from "../../constants/common"
import { validateEmail } from "../../constants/common"
import Loader from "../../common/Loader"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "../UsersList/styles.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { DealManagementAccess } from "common/AccessManagement"
import Unauthorized from "common/Unauthorized"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ReactSelect from "constants/ReactSelect"

const OpsUsers = () => {
  const [orders, setData] = useState([])
  const [file, setFile] = useState(null)
  const [dp, setDp] = useState("")
  const [phone, setPhone] = useState()
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [selectAll, setSelectAll] = useState()
  const [deleteItem, setDeleteItem] = useState("")
  const [forPassword, setForPassword] = useState(false)
  const [rmManager, setRmManager] = useState([])
  const [editRm, setEditRm] = useState(false)
  const [selectedRm, setSelectedRm] = useState()

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [roleFilter, setRoleFilter] = useState({})
  const [selectedRole, setSelectedRole] = useState("")

  const [finalSelectedRows, setFinalSelectedRows] = useState([])

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    roleFilter?.id ? `&roleId=${roleFilter.id}` : ``
  }`

  const getUrl = `/users?permissions=OPS_USER&$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

  const masterData = async () => {
    setLoading(true)
    try {
      const res = await axiosInstance.get(getUrl)

      if (res) {
        setTotal(res.data.total)
        let pages = Math.ceil(
          (res.data?.total || res.total) / (res.data?.limit || res.limit)
        )
        setTotalPage(pages)
        const userResponse = res.data?.data || res.data
        setData(userResponse)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "role":
        setRoleFilter(e)
        break

      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    accessRestriction === "SuperAdmin" && masterData()
  }, [limit, skip, roleFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization

  const OpsUsersColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {order?.permissions === "Admin" ? (
            <>
              <>NA</>
              <>NA</>
            </>
          ) : (
            <>
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </>
          )}
        </div>
      ),
    },
    {
      dataField: "role.role_name",
      text: "Role",
      sort: true,
      formatter: (cellContent, row) => handleRoleName(row.role),
    },
    {
      dataField: "role.department",
      text: "Department",
      sort: true,
      formatter: (cellContent, row) => handleDepartmentName(row.role),
    },
    {
      dataField: "user_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "user_pan.name",
      text: "Name on PAN",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "distributor_kyc.name",
      text: "Legal Entity Name",
      sort: true,
    },
    {
      dataField: "relManager.user_name",
      text: "Res Manager",
      sort: true,
      // formatter: (cellContent, row) => handleRes(row.relManager),
    },
    {
      dataField: "rm_calander_url",
      text: "RM calendar Url",
      sort: true,
    },
    {
      dataField: "reduced_email",
      text: "Reduced Emails",
      sort: true,
    },

    {
      dataField: "profile_picture",
      text: "Profile Pic",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleimg(row.profile_picture),
    },

    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
  ]

  const handleimg = img => {
    const date1 = img ? (
      <img
        src={img.indexOf("google") == -1 ? picUrl + img : img}
        alt="profile pic"
        style={{ maxWidth: "120px", maxHeight: "100px" }}
      ></img>
    ) : (
      "Profile pic not available"
    )
    return date1
  }

  function humanize(str) {
    var i,
      frags = str?.split("_")
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags?.join(" ")
  }

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete this user`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(`users/${order.id}`)
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const handleMultipleIds = async str => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    switch (str) {
      case "password":
        setIsEdit(true)
        setForPassword(true)
        setEditRm(false)
        toggle()
        break
      case "rm":
        const selectedUser = finalSelectedRows
        var isAllDist = true
        for (let i = 0; i < selectedUser.length; i++) {
          if (selectedUser[i]?.role?.department != "Distributor") {
            isAllDist = false
            toast.error("Please select distributor only")
            break
          }
        }
        if (isAllDist) {
          setLoading(true)
          const rmRes = await distributorUsers()
          if (rmRes) {
            const relationship_manager = rmRes?.data?.data.filter(
              ele => ele.roleId === 1
            )
            setRmManager(relationship_manager)
          } else {
            toast.error("something went wrong")
          }
          setLoading(false)
          setIsEdit(false)
          setForPassword(false)
          setEditRm(true)
          toggle()
        }
        break
      default:
        break
    }
  }

  const handleDepartmentName = overview => {
    const overviewSorted = humanize(overview?.department)
    return overviewSorted
  }
  const handleRoleName = overview => {
    const overviewSorted = humanize(overview?.role_name)
    return overviewSorted
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setForPassword(false)
    setEditRm(false)
    setSelectedRole(order.role)
    setOrderList({
      id: order.id,
      department: order.roleId,
      user_name: order.user_name,
      email: order.email,
      phone: order.phone,
      rm_calander_url: order.rm_calander_url,
      bio: order.bio,
      history: order.history,
      profile_picture: order.profile_picture,
      created_at: order.created_at,
      reduced_email: order.reduced_email,
    })

    setIsEdit(true)

    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (editRm) {
      if (!selectedRm) {
        toast.error("Please select an RM")
        setLoading(false)
        return false
      }
      var obj = {
        pocId: selectedRm.id,
      }
      if (finalSelectedRows.length) {
        for (let i = 0; i < finalSelectedRows.length; i++) {
          const user_obj = finalSelectedRows[i]
          try {
            if (user_obj.distributor_kyc) {
              await axiosInstance.patch(
                `distributor-kyc/${user_obj?.distributor_kyc?.id}`,
                obj
              )
            } else {
              obj["userId"] = user_obj.id
              await axiosInstance.post(`distributor-kyc`, obj)
            }
          } catch (error) {
            toast.error(error.message)
            setLoading(false)
            break
          }
        }
        toast.success("Successfully Updated")
        setSelectedRm()
        setFinalSelectedRows([])
        masterData()
      }
    } else if (forPassword) {
      const upadtePassword = {
        password: values["password"],
      }
      if (values?.password?.length < 4) {
        toast.error("Please enter valid password")
        setLoading(false)
        return false
      }
      if (finalSelectedRows.length) {
        for (let i = 0; i < finalSelectedRows.length; i++) {
          try {
            const response = await axiosInstance.patch(
              `users/${finalSelectedRows[i].id}`,
              upadtePassword
            )
          } catch (error) {
            toast.error(error.message)
            setLoading(false)

            break
          }
        }
        toast.success("Successfully Updated")
        // window.location.reload()
        setFinalSelectedRows([])
        masterData()
      }
    } else if (isEdit) {
      if (!validateEmail(values.email)) {
        toast.error("Please enter valid email id")
        setLoading(false)

        return false
      }
      const updateOrder = {
        roleId: values.department,
        user_name: values.user_name,
        email: values.email,
        phone: phone ? "+" + phone : orderList.phone,
        rm_calander_url: values.rm_calander_url,
        profile_picture: dp || orderList.profile_picture,
        bio: values.bio,
        history: values.history,
        created_at: values.created_at,
        reduced_email: values.reduced_email || orderList.reduced_email,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `users/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    } else {
      if (!validateEmail(values.email)) {
        toast.error("Please enter valid email id")
        setLoading(false)

        return false
      }
      if (phone?.length < 12) {
        toast.error("Please enter valid phone number")
        setLoading(false)

        return false
      }
      const newOrder = {
        roleId: values["department"],
        user_name: values["user_name"],
        email: values["email"],
        phone: "+" + phone,
        profile_picture: dp,
        permissions: "OPS_USER",
        rm_calander_url: values.rm_calander_url,
        bio: values.bio,
        history: values.history,
        created_at: values["created_at"],
        password: values["password"],
        is_email_verified: true,
        is_password_set: true,
        is_phone_verified: true,
        tnc: true,
        whtsup_tnc: true,
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `/register-user-from-admin?mark_complete=true`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    }
    setPhone("")
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setForPassword(false)
    setEditRm(false)
    setOrderList("")
    setPhone("")
    setSelectedRole("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`role?$sort[created_at]=-1`)

      if (response) {
        setRole(response?.data?.data || response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=profile_pic`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const downloadData = async fileName => {
    try {
      setLoading(true)

      const url = `/users?permissions=OPS_USER&$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = OpsUsersColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const accessRestriction = DealManagementAccess()
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="OPS Users" />
          {accessRestriction === "SuperAdmin" ? (
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {/* {(accessRestriction >= 3 ||
                      accessRestriction === "SuperAdmin") && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        style={{ marginRight: "3px" }}
                        onClick={() => handleMultipleIds("rm")}
                      >
                        <i className="mdi mdi-pencil me-1" /> Manage RM
                      </Button>
                    )} */}
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => {
                        handleMultipleIds("password")
                      }}
                      disabled={finalSelectedRows.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Update Password
                    </Button>
                    {(accessRestriction == "4" ||
                      accessRestriction === "SuperAdmin") && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => downloadData("OpsUsers")}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle me-1" />{" "}
                        Download
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={OpsUsersColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-start">
                            <Col md={4}>
                              {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                              <div className="position-relative">
                                <label>Search User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Name, Email, Phone, Pan Name, Pan, Legal Entity Name `}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                              {/* </div> */}
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Role</label>
                                <ReactSelect
                                  users={role}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "role")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["role_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={OpsUsersColumns(toggle)}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? forPassword
                                      ? "Update Password"
                                      : "Edit Ops User"
                                    : editRm
                                    ? "Manage Relationship Manager"
                                    : "Add Ops User"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        {forPassword && !editRm ? (
                                          <>
                                            <div className="mb-3">
                                              <AvField
                                                name="password"
                                                label="Password*"
                                                type="text"
                                                autoComplete="off"
                                                errorMessage="Invalid Password"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={orderList.password || ""}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <p>
                                                *Password should be 8-16
                                                characters long.
                                              </p>
                                            </div>
                                          </>
                                        ) : null}
                                        {editRm ? (
                                          <div className="mb-3">
                                            <label>Relationship Manager</label>
                                            <ReactSelect
                                              users={rmManager}
                                              selectedOption={selectedRm}
                                              setSelectedOption={setSelectedRm}
                                            />
                                          </div>
                                        ) : null}
                                        {!forPassword && !editRm ? (
                                          <>
                                            <div className="mb-3">
                                              <AvField
                                                name="department"
                                                label="Role"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid Role"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.department || ""
                                                }
                                                onChange={e => {
                                                  setSelectedRole(
                                                    role.find(
                                                      item =>
                                                        item.id ==
                                                        e.target.value
                                                    )
                                                  )
                                                }}
                                                style={{ widht: "100%" }}
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                {role.map((item, index) => (
                                                  <option
                                                    key={item.role_name}
                                                    value={item.id}
                                                  >
                                                    {item.role_name}
                                                  </option>
                                                ))}
                                              </AvField>
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="user_name"
                                                label="Name"
                                                autoComplete="off"
                                                type="text"
                                                errorMessage="Invalid Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.user_name || ""
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="text"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={orderList.email || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <p>Phone</p>
                                              <div>
                                                <PhoneInput
                                                  inputClass="form-controls"
                                                  countryCodeEditable={false}
                                                  prefix="+"
                                                  value={
                                                    phone || orderList.phone
                                                  }
                                                  country={"in"}
                                                  placeholder="phone"
                                                  onChange={phone =>
                                                    setPhone(phone)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="rm_calander_url"
                                                label="RM Calendar Url"
                                                type="text"
                                                errorMessage="Invalid RM Calendar Url"
                                                validate={{
                                                  required: {
                                                    value: false,
                                                  },
                                                }}
                                                value={
                                                  orderList.rm_calander_url ||
                                                  ""
                                                }
                                              ></AvField>
                                            </div>
                                            {selectedRole?.department ==
                                            "Distributor" ? (
                                              <div className="mb-3">
                                                <AvField
                                                  name="reduced_email"
                                                  label="Reduced Emails"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid Value"
                                                  value={
                                                    orderList.reduced_email ||
                                                    "false"
                                                  }
                                                  style={{ widht: "100%" }}
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value="false">
                                                    False
                                                  </option>
                                                  <option value="true">
                                                    True
                                                  </option>
                                                </AvField>
                                              </div>
                                            ) : null}

                                            <div className="mb-3">
                                              <AvField
                                                name="bio"
                                                label="Bio"
                                                type="textarea"
                                                errorMessage="Invalid Bio"
                                                validate={{
                                                  required: {
                                                    value: false,
                                                  },
                                                }}
                                                value={orderList.bio || ""}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="history"
                                                label="History"
                                                type="textarea"
                                                errorMessage="Invalid History"
                                                validate={{
                                                  required: {
                                                    value: false,
                                                  },
                                                }}
                                                value={orderList.history || ""}
                                              ></AvField>
                                            </div>

                                            {!isEdit ? (
                                              <>
                                                <div className="mb-3">
                                                  <AvField
                                                    name="password"
                                                    label="Password*"
                                                    type="text"
                                                    autoComplete="off"
                                                    errorMessage="Invalid Password"
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                    value={
                                                      orderList.password || ""
                                                    }
                                                    minLength={8}
                                                    maxLength={16}
                                                  ></AvField>
                                                </div>
                                                <div className="mb-3">
                                                  <p>
                                                    *Password should be 8-16
                                                    characters long.
                                                  </p>
                                                </div>
                                              </>
                                            ) : null}

                                            {orderList.profile_picture ? (
                                              <img
                                                src={getImgUrl(
                                                  picUrl,
                                                  orderList.profile_picture
                                                )}
                                                alt="Image"
                                                style={{
                                                  maxWidth: "100px",
                                                  maxHeight: "150px",
                                                }}
                                              ></img>
                                            ) : null}
                                            {/* <div
                                                  className="mt-3"
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <Label
                                                    htmlFor="formFile"
                                                    className="form-label"
                                                  >
                                                    {orderList.profile_picture
                                                      ? "Update Profile Pic"
                                                      : "Upload Profile Pic"}
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    onChange={handleFileUpload}
                                                  />
                                                </div> */}
                                          </>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}${
                                finalSelectedRows.length
                                  ? ` | Selected rows: ${finalSelectedRows.length}`
                                  : ""
                              }
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Unauthorized />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

OpsUsers.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(OpsUsers)
