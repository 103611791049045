import React from "react"
import "./loader.scss"

function Loader() {
  return (
    <div>
      <div className="hawk_loder_outer">
        <div className="hwk_loder_inner">
          <div className="hawk-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
