import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"

const DistributorAgreement = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [agreementFormState, setAgreementFormState] = useState({})
  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [file, setFile] = useState(null)
  const [selectedAgreement, setSelectedAgreement] = useState({})
  const [isAgreementExists, setIsAgreementExists] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [agreementDocUrl, setAgreementDocUrl] = useState("")

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-agreement?$sort[updated_at]=-1`
      )

      if (response) {
        setOrders(response.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    const usersListRes = await distributorUsers()
    const usersList = usersListRes?.data?.data || usersListRes?.data
    if (usersList) {
      const distributors = usersList.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleActionClick = row => {
    setSelectedAgreement(row)
    toggleUploadModal()
  }

  const AgreementColumns = download => {
    const cols = []
    if (!download) {
      cols.push(
        {
          dataField: "action",
          isDummyField: true,
          text: "Edit",
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, order) => (
            <>
              <div className="d-flex gap-3">
                {accessRestriction >= 3 ||
                accessRestriction === "SuperAdmin" ? (
                  <Link
                    to="#"
                    className="text-success"
                    onClick={() => handleOrderClick(order)}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ) : (
                  "NA"
                )}
                {/* {accessRestriction === "SuperAdmin" ? (
              <Link
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )} */}
              </div>
            </>
          ),
        },
        {
          dataField: "",
          text: "Action",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            !row.is_agreement_signed ? (
              <Link to="#" className="text-primary">
                <Button
                  type="button"
                  color="primary"
                  outline
                  className="btn-md btn-rounded"
                  onClick={() => handleActionClick(row)}
                >
                  Upload
                </Button>
              </Link>
            ) : (
              "NA"
            ),
        }
      )
    }
    cols.push(
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      { dataField: "distributor.email", text: "Email", sort: true },
      {
        dataField: "effective_date",
        text: "Effective Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row?.effective_date),
      },
      {
        dataField: "is_agreement_signed",
        text: "Is Agreement Signed",
        sort: true,
      },
      {
        dataField: "unsigned_doc_url",
        text: "Unsigned Agreement",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.unsigned_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.unsigned_doc_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "agreement_doc_url",
        text: "Signed Agreement",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {order.agreement_doc_url ? (
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.agreement_doc_url)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },
      { dataField: "distributor.user_name", text: "User Name", sort: true },
      { dataField: "distributor.phone", text: "Phone", sort: true },
      {
        dataField: "registered_address",
        text: "Registered Address",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.registered_address ||
          order?.registered_address,
      },
      { dataField: "notice_period", text: "Notice Period", sort: true },
      {
        dataField: "signatory",
        text: "Authorised Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.signatory || "",
      }
    )
    return cols
  }

  const handleOrderClick = selected => {
    setIsAgreementExists(false)
    setSelectedOption({ id: selected.distributorId, ...selected.distributor })
    setSelectedAgreement(selected)
    setAgreementFormState({
      effectiveDate: selected?.effective_date,
      noticePeriod: selected?.notice_period,
      isAgreementSigned: selected?.is_agreement_signed,
      distributorId: selected?.distributorId,
    })
    toggle()
  }

  const setAgreementUser = async e => {
    if (e.id !== selectedOption?.id) {
      setLoading(true)
      try {
        const data = await axiosInstance.get(
          `/distributor-agreement?distributorId=${e.id}`
        )
        const selected = data?.data?.data?.[0]
        setSelectedOption(e)
        if (selected) {
          setIsAgreementExists(true)
          toast.error("Agreement already exists")
          if (e?.is_agreement_signed) {
            toast.error("Agreement Already Signed")
          }
        } else {
          setIsAgreementExists(false)
        }
        setLoading(false)
      } catch (error) {
        toast.error(error)
        setLoading(false)
      }
    }
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("select a user")
      return false
    }
    setLoading(true)
    const updateOrder = {
      is_agreement_signed:
        values.is_agreement_signed || orders.is_agreement_signed,
      agreement_doc_url:
        values.agreement_doc_url ||
        (orders.agreement_doc_url && orders.is_agreement_signed)
          ? values.agreement_doc_url || orders.agreement_doc_url
          : null,
    }
    const distributorAgreementData = {
      effective_date: agreementFormState.effectiveDate,
      notice_period: agreementFormState.noticePeriod,
      is_agreement_signed: agreementFormState.isAgreementSigned,
      distributorId: selectedOption?.id,
    }
    try {
      const response = selectedAgreement?.id
        ? await axiosInstance.patch(
            `distributor-agreement/${selectedAgreement?.id}`,
            distributorAgreementData
          )
        : await axiosInstance.post(
            `distributor-agreement`,
            distributorAgreementData
          )
      if (response) {
        toast.success(
          `Agreement ${
            selectedAgreement?.id ? "edited" : "created"
          } successfully`
        )
        try {
          const pdfResponse = await axiosInstance.post(
            "/generate-distributor-pdf",
            {
              type: "agreement",
              distributorAgreementId: response?.data?.id,
            }
          )
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        } catch (error) {
          throw Error("Failed to generate PDF")
        } finally {
          masterData()
          toggle()
        }
      }
    } catch (error) {
      toast.error(error.message)
    }

    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      // let currentUserInv = users?.data.find(
      //   user => user?.id == selectedOption?.id
      // )
      // setSelectedOption(currentUserInv)
    } else {
      toast.error("something went wrong")
    }
    setLoading(false)
  }
  const toggle = () => {
    setModal(!modal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const handleUploadAgreement = async () => {
    const updateDoc = {
      agreement_doc_url: agreementDocUrl,
      is_agreement_signed: true,
    }
    try {
      const response = await axiosInstance.patch(
        `distributor-agreement/${selectedAgreement?.id}`,
        updateDoc
      )
      if (response) {
        toast.success("Successfully Updated")
        masterData()
        toggleUploadModal()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  // const handleDeleteOrder = async order => {
  //   setLoading(true)
  //   var r = confirm(`Are you sure want to delete this user`)
  //   if (r == true) {
  //     try {
  //       const response = await axiosInstance.delete(
  //         `distributor-agreement/${order.id}`
  //       )
  //       if (response) {
  //         masterData()
  //         toast.success("Successfully Deleted")
  //       }
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  //   }
  //   setLoading(false)
  // }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = AgreementColumns(true)
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    arr?.forEach(item => {
      item.legal_entity_name = item.distributor?.distributor_kyc?.name || ""
      item.registered_address =
        item?.distributor?.distributor_kyc?.registered_address ||
        item?.registered_address
      item.signatory = item?.distributor?.distributor_kyc?.signatory || ""
    })
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleInitiateAgreement = async () => {
    setIsAgreementExists(false)
    setSelectedAgreement({})
    setLoading(true)
    setSelectedOption({})
    setAgreementFormState({})
    setLoading(false)
    toggle()
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "effectiveDate":
        setAgreementFormState({
          ...agreementFormState,
          effectiveDate: value,
        })
        break
      case "noticePeriod":
        setAgreementFormState({
          ...agreementFormState,
          noticePeriod: value < 0 ? Math.abs(value) : value,
        })
        break
      case "isAgreementSigned":
        setAgreementFormState({
          ...agreementFormState,
          isAgreementSigned: value,
        })
        break
      default:
        break
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAgreementDocUrl(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Agreement" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={AgreementColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <Col className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <div className="col-auto">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleInitiateAgreement}
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-plus me-1" /> Add
                              </Button>
                            )}
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Agreement Data",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField={(row, index) => index}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={AgreementColumns()}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={uploadModal}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Signed Agreement
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleUploadAgreement}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <Col className="col-12">
                                      <div className="mt-3">
                                        {loading && <Loader />}
                                        <AvField
                                          name="agreementDocUrl"
                                          label="Upload"
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          onChange={handleFileUpload}
                                        ></AvField>
                                        <p className="mt-3">
                                          {agreementDocUrl
                                            ? `File uploaded: ${agreementDocUrl
                                                .split("/")
                                                .pop()}`
                                            : ""}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-3"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          SAVE
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {selectedAgreement?.id
                                  ? "Edit Distributor Agreement"
                                  : "Distributor Agreement"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>User</label>
                                        {selectedOption.id ? (
                                          <ReactSelect
                                            users={users}
                                            selectedOption={{
                                              user_name:
                                                selectedOption?.distributor_kyc
                                                  ?.name,
                                              email: selectedOption.email,
                                              phone: selectedOption.phone,
                                            }}
                                            isDisabled={
                                              selectedAgreement?.id
                                                ? true
                                                : false
                                            }
                                            setSelectedOption={setAgreementUser}
                                          />
                                        ) : (
                                          <ReactSelect
                                            users={users}
                                            setSelectedOption={setAgreementUser}
                                            isDisabled={
                                              selectedAgreement?.id
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="effectiveDate"
                                          label="Effective Date"
                                          type="date"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          disabled={isAgreementExists}
                                          value={
                                            agreementFormState?.effectiveDate ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="noticePeriod"
                                          label="Notice Period"
                                          type="number"
                                          errorMessage="Invalid Value"
                                          min={0}
                                          disabled={isAgreementExists}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            agreementFormState.noticePeriod ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="isAgreementSigned"
                                          label="Is Agreement Signed"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid value"
                                          disabled={isAgreementExists}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            agreementFormState.isAgreementSigned?.toString() ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          <option value={true}>TRUE</option>
                                          <option value={false}>FALSE</option>
                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!selectedOption.is_agreement_signed && (
                                      <Col>
                                        <div className={"text-center mt-2"}>
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              selectedOption.is_agreement_signed ||
                                              isAgreementExists
                                            }
                                          >
                                            SAVE
                                          </button>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorAgreement
