import { toast } from "react-toastify"

export const isObject = item => {
  return item && typeof item === "object" && !Array.isArray(item)
}

export const isObjectKeyPresent = (item, key) => {
  return isObject(item) && item.hasOwnProperty(key)
}

export const showToastSuccess = msg => toast.success(msg)

export const showToastError = error => toast.error(error)

export const showApiError = (errors = []) => {
  Array.isArray(errors) && errors.forEach(err => showToastError(err))
}

export const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}
