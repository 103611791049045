import React from "react"

function Unauthorized() {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        color: "red",
      }}
    >
      <h1>You are not authorized to access this page !!</h1>
    </div>
  )
}

export default Unauthorized
