import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData } from "constants/common"
import { AccessId, benPosReportTypes } from "constants/ConstantFields"
import nodata from "../../assets/images/nodata/no-data.svg"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"
import paginationFactory from "react-bootstrap-table2-paginator"

const BenPosReport = () => {
  const [loading, setLoading] = useState(false)

  const [selectReportType, setSelectedReportType] = useState({})

  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [orders, setData] = useState([])

  const [investments, setInvestments] = useState()
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [benPosFilter, setBenPosFilter] = useState()

  const [userFilter, setUserFilter] = useState("")

  const accessRestriction = DealManagementAccess(AccessId?.ASSET_MANAGEMENT)
  const dollarIndianLocale = Intl.NumberFormat("en-IN")

  const { SearchBar } = Search

  const handleAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const BenPosReportColumns = download => {
    let columns = [
      {
        dataField: "project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "benpos_date",
        text: "BenPos Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.benpos_date),
      },
      { dataField: "investor_name", text: "BenPos User Name", sort: true },
      { dataField: "pan_number", text: "BenPos Pan", sort: true },
      { dataField: "debentures", text: "BenPos Debentures", sort: true },
      { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
      {
        dataField: "bank_account_number",
        text: "BenPos Account Number",
        sort: true,
      },
      {
        dataField: "bank_ifsc",
        text: "BenPos IFSC",
        sort: true,
      },
      { dataField: "demat_id", text: "BenPos Demat Id", sort: true },
      { dataField: "name_on_pan", text: "Investor Name", sort: true },
      { dataField: "lot_size", text: "Investor Lot Size", sort: true },
      {
        dataField: "user_account_number",
        text: "Investor Account Number",
        sort: true,
      },
      { dataField: "user_ifsc_code", text: "Investor IFSC Code", sort: true },
      {
        dataField: "user_demat_id",
        text: "Investor Demat Id",
        sort: true,
      },
      {
        dataField: "nil_tds_applicable",
        text: "NIL TDS applicable?",
        sort: true,
      },
      { dataField: "user_exists", text: "Pan Found?", sort: true },
      { dataField: "name_matched", text: "Name Matched?", sort: true },
      { dataField: "investment_exists", text: "Investment found?", sort: true },
      {
        dataField: "debenture_matched",
        text: "Debentures Matched?",
        sort: true,
      },
      {
        dataField: "bank_account_matched",
        text: "Bank Account Matched?",
        sort: true,
      },
      {
        dataField: "ifsc_matched",
        text: "IFSC Matched?",
        sort: true,
      },
      {
        dataField: "demat_matched",
        text: "Demat Matched?",
        sort: true,
      },
      // { dataField: "userInvestmentId", text: "", sort: true },
    ]

    if (selectReportType && selectReportType.id == "interestCalculation") {
      columns = [
        { dataField: "investment_lot_name", text: "Tranche" },
        { dataField: "investor_name", text: "Investor Name" },
        { dataField: "pan_number", text: "Pan Number" },
        {
          dataField: "depository_type",
          text: "Depository",
        },
        { dataField: "lot_size", text: "Debentures" },
        { dataField: "gross_interest", text: "Gross Interest Due" },
        { dataField: "tds", text: "TDS" },
        { dataField: "net_interest", text: "Net Interest" },
        { dataField: "bank_name", text: "Bank Name" },
        { dataField: "bank_account_number", text: "Account Number" },
        { dataField: "bank_ifsc", text: "IFSC Code" },
        { dataField: "user_ifsc", text: "IFSC Code(As per system)" },
        {
          dataField: "user_account_number",
          text: "Account Number(As per system)",
        },
      ]
    }
    return columns
  }

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const masterData = async (e = selectReportType) => {
    setData([])
    if (!e?.endpoint) {
      return
    }
    setLoading(true)
    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
    }${benPosFilter ? `&benpos_date=${benPosFilter}` : ""}`

    // MasterData fn to call the data from backend.
    const getUrl = `/${e.endpoint}?$limit=${limit}&$skip=${skip}${filterUrl}`

    try {
      if (investmentFilter?.id && benPosFilter) {
        const response = await axiosInstance.get(getUrl)

        if (response) {
          setTotal(response.data.total)
          const data = response?.data?.data || response?.data
          setData(data)
          let pages = Math.ceil(
            (response.data?.total || response.total) /
              (response.data?.limit || response.limit)
          )
          setTotalPage(pages)
        }
      }

      const investmentRes = await axiosInstance.get(`investment`)
      const investmentData = investmentRes?.data?.data || investmentRes?.data
      setInvestments(investmentData)
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, investmentFilter, benPosFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "reportType":
        setSelectedReportType(e)
        masterData(e)
        // setInvestmentFilter(null)
        break
      case "projectType":
        setInvestmentFilter(e)
        setUserFilter("")
        setBenPosFilter("")
        break
      case "benPosDate":
        setBenPosFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  // useEffect(() => {
  //   if (selectReportType?.id) {
  //     if (selectReportType?.id === "upfront") {
  //       setStartDate("")
  //       setEndDate("")
  //       masterData()
  //     } else if (selectReportType?.id === null) {
  //       setData([])
  //     }
  //   }
  // }, [selectReportType, investmentFilter, selectedDistributor, selectedRm])

  const downloadExcel = async (id, lots, date) => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        benPosFilter ? `&benpos_date=${benPosFilter}` : ""
      }`

      const downloadUrl = `/download-excel?type=${selectReportType.endpoint}&$sort[created_at]=-1${filterUrl}`

      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        benPosFilter ? `&benpos_date=${benPosFilter}` : ""
      }`

      const getUrl = `/benpos-report?$limit=${limit}&$skip=${skip}${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = BenPosReportColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="BENPOS REPORT" />
          <Row>
            <Col xs="12">
              <Row className="mb-2">
                <Col md={3}>
                  <div className="mb-3">
                    <label>Report Type</label>
                    <ReactSelect
                      users={benPosReportTypes}
                      setSelectedOption={e =>
                        handleFilterChange(e, "reportType")
                      }
                      multiOptionLabel={true}
                      optionLabelKeys={["statusText"]}
                      isClearable={true}
                    />
                  </div>
                </Col>
                {selectReportType?.id ? (
                  <>
                    <Col md={3}>
                      <div className="mb-3">
                        <label>Project Name</label>
                        <ReactSelect
                          users={investments}
                          setSelectedOption={e =>
                            handleFilterChange(e, "projectType")
                          }
                          multiOptionLabel={true}
                          optionLabelKeys={["project_name"]}
                          isClearable={true}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="benpos_date">BenPos Date</Label>
                        <Input
                          name="benpos_date"
                          id="benpos_date"
                          type="date"
                          value={benPosFilter}
                          disabled={!investmentFilter?.id}
                          onChange={e => handleFilterChange(e, "benPosDate")}
                          className="form-control custom-input-height"
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
                {selectReportType?.id ? (
                  <div className="col-md-3">
                    {(accessRestriction >= "3" ||
                      accessRestriction === "SuperAdmin") && (
                      <div className="d-flex justify-content-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-3 mt-4 me-2"
                          onClick={() => {
                            downloadExcel("BenPos Report")
                          }}
                          style={{ marginRight: "3px" }}
                          disabled={
                            !investmentFilter?.id ||
                            !benPosFilter ||
                            !orders.length
                          }
                        >
                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                          Download
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
              </Row>
              <Card>
                <CardBody>
                  {selectReportType?.id ? (
                    investmentFilter?.id && benPosFilter ? (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={BenPosReportColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {selectReportType?.id == "interestCalculation" ? (
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="justify-content-between">
                                <Col md={3}>
                                  <div className="position-relative mb-3">
                                    <label>User</label>
                                    <input
                                      onChange={e =>
                                        handleFilterChange(e, "user")
                                      }
                                      id="search-bar-0"
                                      type="text"
                                      className="form-control rounded custom-input-height"
                                      placeholder={`Search by Name`}
                                      value={userFilter || ""}
                                    />
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="d-flex flex-row-reverse">
                                    <select
                                      className="form-select w-75"
                                      value={limit}
                                      onChange={e =>
                                        handleFilterChange(e, "limit")
                                      }
                                    >
                                      {[10, 30, 50, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                          Show {pageSize}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            )}

                            <Row>
                              <Col xl="12">
                                {investmentFilter?.id ? (
                                  <BootstrapTable
                                    keyField="id"
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={BenPosReportColumns()}
                                    data={orders}
                                    pagination={
                                      selectReportType?.id ==
                                      "interestCalculation"
                                        ? paginationFactory(pageOptions)
                                        : null
                                    }
                                    wrapperClasses={"table-responsive mb-4"}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                            {selectReportType?.id ==
                            "interestCalculation" ? null : (
                              <Row className="justify-content-md-space-between justify-content-center align-items-center">
                                <Col className="col-12 col-md-auto mb-3">
                                  {`Showing ${total ? skip + 1 + " to" : ""} ${
                                    limit > total || limit + skip > total
                                      ? total
                                      : limit + skip
                                  } rows of ${total}
                 `}
                                </Col>
                                <Col>
                                  <Row className="justify-content-md-end justify-content-center align-items-center">
                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() => handlePrevious(limit)}
                                          disabled={currentPage == 1}
                                        >
                                          {"<<"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() => handlePrevious(skip)}
                                          disabled={currentPage == 1}
                                        >
                                          {"<"}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                      Page{" "}
                                      <strong>{`${
                                        currentPage ? currentPage : 1
                                      } of ${
                                        totalPage ? totalPage : 1
                                      }`}</strong>
                                    </Col>
                                    <Col className="col-md-auto">
                                      <Input
                                        type="number"
                                        min={1}
                                        style={{ width: 70 }}
                                        max={total == 0 ? 1 : totalPage}
                                        value={currentPage || 1}
                                        defaultValue={1}
                                        onChange={onChangePagination}
                                        disabled={total == 0}
                                      />
                                    </Col>

                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() => handleNext(skip)}
                                          disabled={
                                            currentPage == totalPage ||
                                            total == 0
                                          }
                                        >
                                          {">"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            handleNext((totalPage - 2) * limit)
                                          }
                                          disabled={
                                            currentPage == totalPage ||
                                            total == 0
                                          }
                                        >
                                          {">>"}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Row>
                        <Col lg="12">
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col sm="4">
                                <div className="maintenance-img">
                                  <img
                                    src={nodata}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <h4 className="mb-4">
                              Please select{" "}
                              {!investmentFilter?.id
                                ? `a Project`
                                : "BenPos Date"}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    )
                  ) : (
                    <Row>
                      <Col lg="12">
                        <div className="text-center">
                          <Row className="justify-content-center">
                            <Col sm="4">
                              <div className="maintenance-img">
                                <img
                                  src={nodata}
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                          <h4 className="my-2">Please select Report Type</h4>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BenPosReport.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(BenPosReport)
