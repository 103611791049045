import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessDenied,
  investmentData,
  Pagination,
} from "../../constants/common"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { sortingByAlphabet } from "constants/sort"
import { AccessId } from "constants/ConstantFields"
import Unauthorized from "common/Unauthorized"
import ReactSelect from "constants/ReactSelect"

const InvestmentTranche = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [bankAccountType, setBankAccountType] = useState("")
  const [accessManagement, setAccessManagement] = useState("")
  const [investmentLotList, setInvestmentLotList] = useState([])

  const [transferorMasterList, setTransferorMasterList] = useState([])
  const [selectedTransferor, setSelectedTransferor] = useState(null)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-tranche?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
      const transferorMasterRes = await axiosInstance.get(`transferor-master`)

      const transferorMasterData =
        transferorMasterRes?.data?.data || transferorMasterRes?.data
      setTransferorMasterList(transferorMasterData)

      const invRes = await investmentData()

      if (invRes) {
        setSponser(invRes?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  useEffect(() => {
    const AccessManagement = DealManagementAccess()
    setAccessManagement(AccessManagement)
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const toggleViewModal = () => setModal1(!modal1)

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteOrder(order)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "open_date",
      text: "Open Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.open_date),
    },

    {
      dataField: "close_date",
      text: "Close Date",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.close_date),
    },

    {
      dataField: "open_status",
      text: "Open Status",
      sort: true,
    },
    {
      dataField: "activeInvestmentLot.investment_lot_name",
      text: "Active Investment Lot",
      sort: true,
    },
    {
      dataField: "distributor_open_status",
      text: "Distributor Open Status",
      sort: true,
    },

    {
      dataField: "bank_account_type",
      text: "Bank Account Type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.bank_account_type == "VPA_AND_CUSTOM"
          ? "VPA & CUSTOM"
          : row.bank_account_type,
    },
    {
      dataField: "vpa_threshold_amount",
      text: "VPA Threshold Amount",
      sort: true,
    },
    {
      dataField: "transferor.transferor_name",
      text: "Transferor Name",
      sort: true,
    },
    {
      dataField: "transferor.bank_name",
      text: "Bank Name",
      sort: true,
    },
    {
      dataField: "transferor.branch_name",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "transferor.bank_account_name",
      text: "Bank Account Name",
      sort: true,
    },
    {
      dataField: "transferor.bank_account_number",
      text: "Bank Account Number",
      sort: true,
    },

    {
      dataField: "transferor.bank_ifsc",
      text: "Bank IFSC",
      sort: true,
    },

    {
      dataField: "show_pg",
      text: "Is Online Payment Allowed",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent = true, row) =>
        handleValidDate(row.updated_at, cellContent),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    const order = arg

    setSelectedTransferor(order.transferor)

    setBankAccountType(order?.bank_account_type)

    handleInvestmentId({ target: { value: order.investmentId } })
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      tranche_amount: order.tranche_amount,
      open_date: order.open_date,
      close_date: order.close_date,
      open_status: order.open_status,
      distributor_open_status: order.distributor_open_status,
      show_pg: order.show_pg,
      vpa_threshold_amount: order.vpa_threshold_amount,
      created_at: order.created_at,
      bank_account_name: order?.transferor?.bank_account_name,
      bank_ifsc: order?.transferor?.bank_ifsc,
      bank_account_number: order?.transferor?.bank_account_number,
      bank_name: order?.transferor?.bank_name,
      branch_name: order?.transferor?.branch_name,
      investment_lot_id: order.activeInvestmentLotId,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-tranche/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (bankAccountType != "VPA") {
      if (!selectedTransferor?.id) {
        toast.error("Please select a transferor")
        return
      }
      if (!selectedTransferor?.bank_account_number) {
        toast.error("Bank Account details not found!")
        return
      }
    }
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        tranche_amount: values.tranche_amount,
        open_date: values.open_date,
        close_date: values.close_date,
        open_status: values.open_status,
        distributor_open_status: values.distributor_open_status,
        show_pg: values.show_pg,
        bank_account_type: values.bank_account_type,
        vpa_threshold_amount: values.vpa_threshold_amount,
        // bank_account_name:
        //   bankAccountType == "VPA"
        //     ? ""
        //     : values.bank_account_name?.toUpperCase(),
        // bank_ifsc:
        //   bankAccountType == "VPA" ? "" : values.bank_ifsc?.toUpperCase(),
        // bank_account_number:
        //   bankAccountType == "VPA" ? "" : values.bank_account_number,
        // bank_name:
        //   bankAccountType == "VPA" ? "" : values.bank_name?.toUpperCase(),
        // branch_name:
        //   bankAccountType == "VPA" ? "" : values.branch_name?.toUpperCase(),
        activeInvestmentLotId:
          values.investment_lot_id == "null" ? null : values.investment_lot_id,
        transferorId: selectedTransferor?.id || null,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-tranche/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values.project_name,
        tranche_amount: values.tranche_amount,
        open_date: values.open_date,
        close_date: values.close_date,
        open_status: values.open_status || true,
        distributor_open_status: values.distributor_open_status || true,
        show_pg: values.show_pg,
        bank_account_type: values.bank_account_type,
        vpa_threshold_amount: values.vpa_threshold_amount,
        // bank_account_name:
        //   bankAccountType == "VPA"
        //     ? ""
        //     : values.bank_account_name.toUpperCase(),
        // bank_ifsc:
        //   bankAccountType == "VPA" ? "" : values.bank_ifsc.toUpperCase(),
        // bank_account_number:
        //   bankAccountType == "VPA" ? "" : values.bank_account_number,
        // bank_name:
        //   bankAccountType == "VPA" ? "" : values.bank_name.toUpperCase(),
        // branch_name:
        //   bankAccountType == "VPA" ? "" : values.branch_name.toUpperCase(),
        activeInvestmentLotId:
          values.investment_lot_id == "null" ? null : values.investment_lot_id,
        transferorId: selectedTransferor?.id || null,
      }

      try {
        const response = await axiosInstance.post(
          `investment-tranche`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success(" Tranche Successfully Added")
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setBankAccountType("")
    setIsEdit(false)
    setInvestmentLotList([])
    setInvestId("")
    setSelectedTransferor(null)
    toggle()
  }

  const handleValidDate = (date, cellContent) => {
    const date1 = moment(date).format(
      cellContent ? "DD MMM Y hh:mm a" : "DD MMM Y"
    )
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [investId, setInvestId] = useState("")
  const handleInvestmentId = async e => {
    setInvestId(e.target.value)
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-lot?$sort[created_at]=-1&investmentId=${e.target.value}`
      )
      if (response) {
        setInvestmentLotList(response?.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  const [sponser, setSponser] = useState([])

  useEffect(() => {
    if (selectedTransferor?.id) {
      setOrderList({
        ...orderList,
        bank_account_name: selectedTransferor?.bank_account_name,
        bank_ifsc: selectedTransferor?.bank_ifsc,
        bank_account_number: selectedTransferor?.bank_account_number,
        bank_name: selectedTransferor?.bank_name,
        branch_name: selectedTransferor?.branch_name,
      })
    } else {
      setOrderList({
        ...orderList,
        bank_account_name: null,
        bank_ifsc: null,
        bank_account_number: null,
        bank_name: null,
        branch_name: null,
      })
    }
  }, [selectedTransferor])

  useEffect(() => {
    if (bankAccountType == "VPA") {
      setSelectedTransferor(null)
    }
  }, [bankAccountType])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Investment Tranche" />
          {accessManagement === "SuperAdmin" ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={EcommerceOrderColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={EcommerceOrderColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className="modal-lg"
                              >
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edit Tranche" : "Add Tranche"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="row">
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                            onChange={handleInvestmentId}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(sponser).map(
                                              (item, index) => (
                                                <option
                                                  key={item.project_name}
                                                  value={item.id}
                                                >
                                                  {item.project_name}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="investment_lot_id"
                                            label="Investment Lot"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Investment Lot"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList?.investment_lot_id || ""
                                            }
                                            // onChange={handleInvestmentId}
                                            disabled={!investId}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="null">None</option>
                                            {sortingByAlphabet(
                                              investmentLotList
                                            ).map((item, index) => (
                                              <option
                                                key={item.investment_lot_name}
                                                value={item.id}
                                              >
                                                {item.investment_lot_name} (Lot
                                                size: {item.lot_size})
                                              </option>
                                            ))}
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="open_date"
                                            label="Open Date"
                                            type="date"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.open_date || ""}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="close_date"
                                            label="Close Date"
                                            type="date"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.close_date || ""}
                                          />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="open_status"
                                            label="Open Status"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.open_status?.toString() ||
                                              ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="distributor_open_status"
                                            label="Distributor Open Status"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.distributor_open_status?.toString() ||
                                              ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="show_pg"
                                            label="Is Online Payment Allowed"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.show_pg?.toString() ||
                                              ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="bank_account_type"
                                            label="Bank Account Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Value"
                                            onChange={e =>
                                              setBankAccountType(e.target.value)
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={bankAccountType || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="VPA">VPA</option>
                                            <option value="CUSTOM">
                                              CUSTOM
                                            </option>
                                            <option value="VPA_AND_CUSTOM">
                                              VPA & CUSTOM
                                            </option>
                                          </AvField>
                                        </div>
                                        {bankAccountType &&
                                        bankAccountType == "VPA_AND_CUSTOM" ? (
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="vpa_threshold_amount"
                                              label="VPA Threshold Amount"
                                              type="number"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.vpa_threshold_amount ||
                                                ""
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {bankAccountType &&
                                        bankAccountType !== "VPA" ? (
                                          <>
                                            <div className="mb-3 col-md-6">
                                              <label>Select Transferor</label>
                                              <ReactSelect
                                                users={transferorMasterList}
                                                setSelectedOption={
                                                  setSelectedTransferor
                                                }
                                                multiOptionLabel={true}
                                                optionLabelKeys={[
                                                  "transferor_name",
                                                  "transferor_depository_name",
                                                  "dp_name",
                                                  "dp_id",
                                                  "client_id",
                                                ]}
                                                selectedOption={
                                                  selectedTransferor
                                                }
                                              />
                                            </div>
                                            {selectedTransferor?.id ? (
                                              <>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="bank_name"
                                                    label="Bank Name"
                                                    type="text"
                                                    errorMessage="Invalid Value"
                                                    disabled
                                                    value={
                                                      selectedTransferor?.bank_name ||
                                                      ""
                                                    }
                                                    className="text-uppercase"
                                                  />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="bank_ifsc"
                                                    label="Bank IFSC Code"
                                                    type="text"
                                                    errorMessage="Invalid Value"
                                                    disabled
                                                    value={
                                                      selectedTransferor?.bank_ifsc ||
                                                      ""
                                                    }
                                                    className="text-uppercase"
                                                  />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="bank_account_name"
                                                    label="Bank Account Name"
                                                    type="text"
                                                    errorMessage="Invalid Value"
                                                    disabled
                                                    value={
                                                      selectedTransferor?.bank_account_name ||
                                                      ""
                                                    }
                                                    className="text-uppercase"
                                                  />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="bank_account_number"
                                                    label="Bank Account Number"
                                                    type="text"
                                                    errorMessage="Invalid Value"
                                                    disabled
                                                    value={
                                                      selectedTransferor?.bank_account_number ||
                                                      ""
                                                    }
                                                  />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="branch_name"
                                                    label="Branch Name"
                                                    type="text"
                                                    errorMessage="Invalid Value"
                                                    disabled
                                                    value={
                                                      selectedTransferor?.branch_name ||
                                                      ""
                                                    }
                                                    className="text-uppercase"
                                                  />
                                                </div>
                                              </>
                                            ) : null}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Unauthorized />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(InvestmentTranche)
