import React from "react"
import PropTypes from "prop-types"
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "155px",
      }}
    >
      <h1>Uh-Oh Something went wrong !!</h1>
      {/*<pre>{error.message}</pre>*/}
      <button
        type="button"
        className="btn btn-secondary mt-2"
        onClick={resetErrorBoundary}
      >
        Try again
      </button>
    </div>
  )
}
ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
}
export default ErrorFallback
