import React from "react"
import UserListingTable from "pages/UsersList/UserListingTable"

function GetMyUsers() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const rmId = userInfo?.id
  return (
    <div>
      <UserListingTable rmId={rmId} />
    </div>
  )
}

export default GetMyUsers
