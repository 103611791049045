import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsersData } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
const DistributorKycList = () => {
  const [orders, setData] = useState([])
  const [kycStatus, setKycStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [startKyc, setStartKyc] = useState(false)
  const [file, setFile] = useState(null)
  const [addressDoc, setAddressDoc] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [panName, setPanName] = useState("")
  const [errorDetail, setErrorDeatail] = useState(false)
  const [personlInfo, setPersonalInfo] = useState(false)
  const [corporateInfo, setCorporateInfo] = useState(false)
  const [hufInfo, setHufInfo] = useState(false)
  const [panValue, setPanValue] = useState("")
  const [docfile, setDocFile] = useState()
  const [addressFile, setaddressFile] = useState()
  const [dematDetails, setDematDetails] = useState(false)
  const [dematFile, setDematFile] = useState()
  const [dematDoc, setDematDoc] = useState(null)
  const [bankDetails, setBankDetails] = useState(false)
  const [accountName, setAccountName] = useState("")
  const [disableKyc, setDisableKyc] = useState(true)
  const [panType, setPanType] = useState("")
  const [dob, setDob] = useState("")
  const [address, setAddress] = useState("")
  const [kartaPanValue, setKartaPanValue] = useState()
  const [kartaPanFile, setKartaPanFile] = useState("")
  const [kartaPanDoc, setKartaPanDoc] = useState(null)
  const [isKartaNameSame, setKartaNameSame] = useState(true)
  const [kartaVerfiDoc, setKartaVerfiDoc] = useState(null)
  const [kartaVerfiFile, setKartaVerfiFile] = useState("")
  const [mcrDoc, setMcrDoc] = useState(null)
  const [mcrFile, setMcrFile] = useState(null)
  const [brDoc, setBrDoc] = useState(null)
  const [brFile, setBrFile] = useState(null)
  const [authPanDoc, setAuthPanDoc] = useState(null)
  const [authPanFile, setAuthPanFile] = useState(null)
  const [authAadharDoc, setAuthAadharDoc] = useState(null)
  const [authAadharFile, setAuthAadharFile] = useState(null)
  const [isAuthorisedName, setIsAuthorisedName] = useState(true)
  const [authorisedName, setAuthorisedName] = useState("")
  const [editAddress, setEditAddress] = useState(false)
  const [isAddressUploadReq, setIsAddressUploadReq] = useState(false)
  const [addressConsent, setAddressConsent] = useState(false)

  const masterData = async () => {
    setLoading(true)
    try {
      const localUser = JSON.parse(localStorage.getItem("userInfo"))
      const response = await axiosInstance.get(
        `/users?$sort[updated_at]=-1&createdById=${localUser.id}&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER`
        // `/users?kyc_status[$nin][]=NOT_SUBMIT&$sort[updated_at]=-1`
      )

      if (response) {
        setData(response?.data || response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    const usersRes = await distributorUsersData()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      setUsers(users)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)
  const KYCColumns = toggleModal => [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "kyc_status",
      text: "Kyc Status",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.created_at),
    },
  ]

  const setKycUser = e => {
    if (e.id !== selectedOption.id) {
      setPanName("")
      setPanValue("")
      setDocFile(null)
      setaddressFile(null)
      setDematFile(null)
      setAccountName("")
      setSelectedOption(e)
      setMcrFile(null)
      setBrFile(null)
      setAuthAadharFile(null)
      setAuthPanFile(null)
      setAuthorisedName("")
      setIsAuthorisedName(true)
      setKartaPanValue(null)
      setKartaPanFile(null)
      setKartaVerfiFile(null)
      setEditAddress(false)
      if (e?.kyc_status == "VERIFIED") {
        toast.error("KYC Already Verified")
      }
    }
  }

  // const setKycData = () => {

  // }

  const handleDocList = file => {
    handleDoc(file)
  }

  const handleKartaFileUpload = e => {
    setKartaPanFile(e.target.value)
  }

  const handleKartaPanValue = e => {
    setKartaPanValue(e.target.value)
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }
  const nextKycStep = () => {
    if (
      startKyc &&
      !(personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        setCorporateInfo(true)
      } else if (panType === "Hindu Undivided Family (HUF)") {
        setHufInfo(true)
      } else {
        setPersonalInfo(true)
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      setDematDetails(true)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      setBankDetails(true)
    }
  }
  const prevKycStep = () => {
    if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      dematDetails &&
      bankDetails
    ) {
      setBankDetails(false)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      setDematDetails(false)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        setCorporateInfo(false)
      } else if (panType === "Hindu Undivided Family (HUF)") {
        setHufInfo(false)
      } else {
        setPersonalInfo(false)
      }
    }
  }
  const handleEditAddress = () => {
    setEditAddress(true)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    const updateOrder = {
      kyc_status: values.kyc_status || orderList.kyc_status,
      kyc_notes:
        values.kyc_notes ||
        (orderList.kyc_notes &&
          orderList.kyc_status &&
          values.kyc_status == "RE_SUBMIT")
          ? values.kyc_notes || orderList.kyc_notes
          : null,
    }

    //   // update order
    if (
      !startKyc &&
      !(personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      try {
        const response = await axiosInstance.patch(
          `users/${orderList.id}`,
          updateOrder
        )
        if (response) {
          toast.success("Successfully Updated")
        }
      } catch (error) {
        toast.error(error.message)
      }
      setKycStatus("")
      toggle()
    }
    if (!selectedOption?.id && !orderList?.id) {
      toast.error("Please select a user.")
      setLoading(false)
      return
    }
    if (
      startKyc &&
      !(personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      const userPan = {
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
        pan_number: panValue.toUpperCase(),
        pan_status: "VALID",
        pan_type: panType,
        name: panName,
        document_file: docfile,
        userId: selectedOption?.id,
      }
      const user = {
        dob: dob,
        address: address,
      }
      try {
        const response = selectedOption.user_pan?.id
          ? await axiosInstance.patch(
              `user-pan/${selectedOption.user_pan.id}`,
              userPan
            )
          : await axiosInstance.post(`user-pan`, userPan)
        if (response) {
          toast.success("Successfully Updated")
          if (
            panType === "Company" ||
            panType === "Firm/ Limited Liability Partnership"
          ) {
            setCorporateInfo(true)
          } else if (panType === "Hindu Undivided Family (HUF)") {
            setHufInfo(true)
          } else {
            setPersonalInfo(true)
          }
        }
      } catch (error) {
        toast.error(error.message)
      }
      if (panType !== "Company") {
        try {
          const response = selectedOption.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (response) {
            toast.success("Successfully Updated")
            // setPersonalInfo(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        const userCorporate = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          authorised_name: authorisedName,
          authorised_aadhar_file: authAadharFile,
          authorised_pan_file: authPanFile,
          userId: selectedOption?.id,
        }
        const userCorporateSame = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          userId: selectedOption?.id,
        }
        try {
          const response = selectedOption.user_corporate_kyc?.id
            ? await axiosInstance.patch(
                `user-corporate-kyc/${selectedOption.user_corporate_kyc.id}`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
            : await axiosInstance.post(
                `user-corporate-kyc`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else if (panType === "Hindu Undivided Family (HUF)") {
        const userHuf = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          karta_doc_proof: kartaVerfiFile,
          userId: selectedOption?.id,
        }
        const userHufSame = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          userId: selectedOption?.id,
        }
        const user = {
          aadhaar_consent: addressConsent,
          address: address,
          address_file: addressFile,
        }
        try {
          const response = selectedOption.user_huf_kyc?.id
            ? await axiosInstance.patch(
                `user-huf-kyc/${selectedOption.user_huf_kyc.id}`,
                isKartaNameSame ? userHuf : userHufSame
              )
            : await axiosInstance.post(
                `user-huf-kyc`,
                isKartaNameSame ? userHuf : userHufSame
              )
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
        try {
          const response = selectedOption?.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else {
        const userAddress = {
          dob: values.dob,
          res_status: values.res_status,
          aadhaar_consent: addressConsent,
          address: values.address,
          address_file: addressFile,
        }
        try {
          const response = await axiosInstance.patch(
            `users/${selectedOption?.id}`,
            userAddress
          )
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      const userDemat = {
        demat_id: values.demat,
        document_file: dematFile,
        userId: selectedOption?.id,
      }
      try {
        const response = selectedOption.user_demat?.id
          ? await axiosInstance.patch(
              `user-demat/${selectedOption.user_demat.id}`,
              userDemat
            )
          : await axiosInstance.post(`user-demat`, userDemat)
        if (response) {
          toast.success("Successfully Updated")
          setBankDetails(true)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo) &&
      dematDetails &&
      bankDetails
    ) {
      const userBank = {
        Account: values.account_number,
        IFSC: values.ifsc,
        consent: "Y",
        userId: selectedOption?.id,
      }
      try {
        const response = selectedOption.user_bank_account?.id
          ? await axiosInstance.patch(
              `user-bank-account/${selectedOption.user_bank_account.id}`,
              userBank
            )
          : await axiosInstance.post(`user-bank-account`, userBank)
        if (response) {
          toast.success("Successfully Updated")
          setAccountName(response?.data?.account_name)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    masterData()
    setLoading(false)
  }
  const isValidKycData = () => {
    if (selectedOption?.id) {
      if (
        panValue &&
        panName &&
        docfile &&
        ((selectedOption.res_status && dob && address) ||
          (kartaPanValue && kartaPanFile && address) ||
          (mcrFile && brFile)) &&
        selectedOption.user_demat?.demat_id &&
        dematFile &&
        selectedOption.user_bank_account?.ifsc_code &&
        selectedOption.user_bank_account?.account_number &&
        accountName
      ) {
        setDisableKyc(false)
      } else {
        setDisableKyc(true)
      }
    } else {
      setDisableKyc(true)
    }
  }
  const finalSubmit = async () => {
    if (!disableKyc) {
      const data = { kyc_status: "IN_VERIFICATION" }

      try {
        const response = await axiosInstance.patch(
          `users/${selectedOption?.id}`,
          data
        )
        if (response) {
          toast.success("KYC successfully completed")
          masterData()
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      toast.error("Fill out all details first")
    }
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      kyc_status: order.kyc_status,
      kyc_notes: order?.kyc_status === "RE_SUBMIT" ? order.kyc_notes : null,
    })
    setStartKyc(false)
    setKycStatus(order.kyc_status)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downladableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = KYCColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downladableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleInitiateKYC = async () => {
    setLoading(true)
    const localUser = JSON.parse(localStorage.getItem("userInfo"))
    const usersRes = await axiosInstance.get(
      `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER&createdById=${localUser.id}&$or[0][kyc_status]=RE_SUBMIT&$or[0][kyc_status]=NOT_SUBMIT&is_registered=true`
    )
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      setUsers(users)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setErrorDeatail(false)
    setSelectedOption({})
    setPanName("")
    setPanValue("")
    setDocFile(null)
    setaddressFile(null)
    setDematFile(null)
    setAccountName("")
    setPersonalInfo(false)
    setCorporateInfo(false)
    setHufInfo(false)
    setDematDetails(false)
    setBankDetails(false)
    setStartKyc(true)
    setOrderList({})
    toggle()
  }

  const handleKartaPanUpload = e => {
    setKartaPanDoc(e.target.files[0])
  }
  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    isValidKycData()
  }, [
    panValue,
    panName,
    docfile,
    selectedOption,
    addressFile,
    dematFile,
    accountName,
  ])
  useEffect(() => {
    if (selectedOption?.id) {
      if (selectedOption.user_pan?.id) {
        setPanValue(selectedOption.user_pan.pan_number)
        setPanName(selectedOption.user_pan.name)
        setDocFile(selectedOption.user_pan.document_file)
        setPanType(selectedOption.user_pan.pan_type)
      }
      if (selectedOption.user_corporate_kyc?.id) {
        // setKartaPanFile(selectedOption.user)
        setAuthAadharFile(
          selectedOption.user_corporate_kyc.authorised_aadhar_file
        )
        setAuthorisedName(selectedOption.user_corporate_kyc.authorised_name)
        setAuthPanFile(selectedOption.user_corporate_kyc.authorised_pan_file)
        setBrFile(selectedOption.user_corporate_kyc.br_file)
        setIsAuthorisedName(
          selectedOption.user_corporate_kyc.is_authorised_name
        )
        setMcrFile(selectedOption.user_corporate_kyc.mcr_file)
      }
      if (selectedOption.user_huf_kyc?.id) {
        setKartaPanFile(selectedOption.user_huf_kyc.karta_pan_file)
        setKartaPanValue(selectedOption.user_huf_kyc.karta_pan_number)
        setKartaVerfiFile(selectedOption.user_huf_kyc.karta_doc_proof)
        setKartaNameSame(selectedOption.user_huf_kyc.is_karta_same_as_huf)
      }
      setDob(moment(selectedOption?.dob).format("YYYY-MM-DD"))
      setAddress(selectedOption?.address)
      setaddressFile(selectedOption.address_file)
      if (selectedOption.user_demat?.id) {
        setDematFile(selectedOption.user_demat.document_file)
      }
      if (selectedOption.user_bank_account?.id) {
        setAccountName(selectedOption.user_bank_account.account_name)
      }
    }
  }, [selectedOption])

  useEffect(() => {
    if (selectedOption?.id) {
      const newData = users.find(el => el.id === selectedOption.id)
      setSelectedOption(newData)
    }
  }, [users])

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDocFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const handlePanInput = async e => {
    setErrorDeatail(false)
    setPanName("")
    const panInput = e.target.value
    if (panInput?.length == 10) {
      setPanValue(panInput)
      const panData = {
        pan: panInput.toUpperCase(),
        userId: selectedOption?.id,
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
      }
      setLoading(true)
      try {
        const response = await axiosInstance.post(`pan-verification`, panData)
        if (response) {
          setPanName(response?.data?.name)
          setPanType(response?.data.pan_type)
          setDob(moment(response?.data.dob, "DD-MM-YYYY").format("YYYY-MM-DD"))
          setAddress(response?.data.address)
        }
      } catch (error) {
        setErrorDeatail(true)
      }
      setLoading(false)
    }
  }

  const handleAddressUpload = e => {
    setAddressDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (addressDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", addressDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setaddressFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [addressDoc])
  useEffect(async () => {
    if (kartaPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaPanDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaPanDoc])
  useEffect(async () => {
    if (kartaVerfiDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaVerfiDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaVerfiFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaVerfiDoc])
  useEffect(async () => {
    if (mcrDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", mcrDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setMcrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [mcrDoc])

  useEffect(async () => {
    if (brDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", brDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setBrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [brDoc])

  useEffect(async () => {
    if (authAadharDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authAadharDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthAadharFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authAadharDoc])

  useEffect(async () => {
    if (authPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authPanDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authPanDoc])

  const handleDematUpload = e => {
    setDematDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (dematDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", dematDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDematFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [dematDoc])
  useEffect(() => {
    if (!modal) {
      setEditAddress(false)
    }
  }, [modal])
  useEffect(() => {
    if (selectedOption?.address) {
      if (address != selectedOption?.address) {
        setIsAddressUploadReq(true)
      } else {
        setIsAddressUploadReq(false)
      }
    } else {
      setIsAddressUploadReq(false)
    }
  }, [address])

  useEffect(() => {
    if (addressFile) {
      setIsAddressUploadReq(true)
    } else {
      setIsAddressUploadReq(false)
    }
  }, [addressFile])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Investor Kyc" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={KYCColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <Col className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <div className="col-auto">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleInitiateKYC}
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-plus me-1" /> Add/Update
                              </Button>
                            )}
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downladableArr,
                                    "KYC Data",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={KYCColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {startKyc &&
                                !(personlInfo || corporateInfo || hufInfo) &&
                                !dematDetails &&
                                !bankDetails
                                  ? "PAN Details"
                                  : startKyc &&
                                    personlInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "Personal Information"
                                  : startKyc &&
                                    corporateInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "Corporate Information"
                                  : startKyc &&
                                    hufInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "HUF Information"
                                  : startKyc &&
                                    (personlInfo || corporateInfo || hufInfo) &&
                                    dematDetails &&
                                    !bankDetails
                                  ? "Demat Details"
                                  : startKyc &&
                                    (personlInfo || corporateInfo || hufInfo) &&
                                    dematDetails &&
                                    bankDetails
                                  ? "Bank Account Details"
                                  : orderList.kyc_status == "VERIFIED"
                                  ? "KYC Verified"
                                  : "Update KYC Status"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color:
                                        orderList.kyc_status == "VERIFIED" &&
                                        !startKyc
                                          ? "green"
                                          : "grey",
                                    }}
                                  >
                                    <Col className="col-12">
                                      {!startKyc && (
                                        <div className="mb-3">
                                          <AvField
                                            name="kyc_status"
                                            label="Kyc Status"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Status"
                                            onChange={e =>
                                              setKycStatus(e.target.value)
                                            }
                                            value={orderList.kyc_status || ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            style={{ widht: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="IN_VERIFICATION">
                                              In Verification
                                            </option>
                                            <option value="VERIFIED">
                                              Verified
                                            </option>
                                            <option value="RE_SUBMIT">
                                              Re Submit
                                            </option>
                                          </AvField>
                                        </div>
                                      )}
                                      {!startKyc && kycStatus === "RE_SUBMIT" && (
                                        <div className="mb-3">
                                          <AvField
                                            name="kyc_notes"
                                            label="Kyc Note"
                                            type="textarea"
                                            errorMessage="Invalid Note"
                                            value={orderList.kyc_notes || ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                      )}
                                      {startKyc &&
                                        !(
                                          personlInfo ||
                                          corporateInfo ||
                                          hufInfo
                                        ) &&
                                        !dematDetails &&
                                        !bankDetails && (
                                          <>
                                            <div className="mb-3">
                                              <label>User</label>
                                              {selectedOption?.id ? (
                                                <ReactSelect
                                                  users={users}
                                                  selectedOption={{
                                                    user_name:
                                                      selectedOption.user_name,
                                                    email: selectedOption.email,
                                                    phone: selectedOption.phone,
                                                  }}
                                                  setSelectedOption={setKycUser}
                                                />
                                              ) : (
                                                <ReactSelect
                                                  users={users}
                                                  setSelectedOption={setKycUser}
                                                />
                                              )}
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                                name="panValue"
                                                label="Enter your PAN"
                                                type="text"
                                                errorMessage="Enter a valid input"
                                                value={panValue || ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                maxLength="10"
                                                onChange={handlePanInput}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p style={{ color: "red" }}>
                                                {errorDetail &&
                                                  "Please enter valid input"}
                                              </p>
                                            </div>
                                            <div className=" mb-3">
                                              <AvField
                                                name="panName"
                                                label="Name as per PAN"
                                                type="text"
                                                errorMessage="Enter a valid input"
                                                value={panName || ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mt-3">
                                              {loading && <Loader />}
                                              <Label
                                                htmlFor="formFile"
                                                className="form-label"
                                              >
                                                Upload PAN Card
                                              </Label>

                                              <Input
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={handleFileUpload}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <p className="mt-3">
                                                {docfile
                                                  ? `File uploaded: ${docfile
                                                      .split("/")
                                                      .pop()}`
                                                  : ""}
                                              </p>
                                              <p className="mt-3">
                                                Kindly upload copy of PAN card
                                                front side in pdf/jpg/png format
                                                (max file size 1MB)
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      {startKyc &&
                                        personlInfo &&
                                        !dematDetails &&
                                        !bankDetails && (
                                          <>
                                            <div className="mb-3">
                                              <AvField
                                                name="res_status"
                                                label="Residential Status"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Select valid input"
                                                value={
                                                  selectedOption?.res_status
                                                    ? selectedOption?.res_status
                                                    : ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option disabled value="">
                                                  Select an option
                                                </option>
                                                <option value="Resident">
                                                  Resident
                                                </option>
                                                <option value="NRI">NRI</option>
                                                <option value="Non-Resident">
                                                  Non-Resident
                                                </option>
                                              </AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="dob"
                                                label="Date of Birth"
                                                type="date"
                                                errorMessage="Select valid input"
                                                value={dob ? dob : ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="address"
                                                label="Address"
                                                type="textArea"
                                                errorMessage="Please Enter valid input"
                                                value={address ? address : ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e =>
                                                  setAddress(e.target.value)
                                                }
                                                disabled={
                                                  !editAddress ||
                                                  selectedOption.kyc_status ==
                                                    "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p
                                                style={{
                                                  color: "#34c38f",
                                                  cursor: "pointer",
                                                  textAlign: "end",
                                                }}
                                                onClick={() =>
                                                  handleEditAddress()
                                                }
                                              >
                                                Edit
                                              </p>
                                              {/* <button
                                                    type="button"
                                                    className="btn btn-success save-user"
                                                    onClick={finalSubmit}
                                                    disabled={disableKyc}
                                                  >
                                                    {"EDIT"}
                                                  </button> */}
                                            </div>
                                            {editAddress && (
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: isAddressUploadReq
                                                        ? true
                                                        : false,
                                                    },
                                                  }}
                                                  onChange={handleAddressUpload}
                                                  name="addressFile"
                                                  label="Upload Address Proof"
                                                  disabled={
                                                    !editAddress ||
                                                    selectedOption.kyc_status ==
                                                      "VERIFIED"
                                                      ? true
                                                      : false
                                                  }
                                                ></AvField>
                                                <p className="mt-3">
                                                  {addressFile
                                                    ? `File uploaded: ${addressFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                            )}
                                            {isAddressUploadReq && (
                                              <div className="mb-3">
                                                <AvField
                                                  name="addressConsent"
                                                  label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                  type="checkbox"
                                                  errorMessage="Please check this"
                                                  value={
                                                    addressConsent || false
                                                  }
                                                  disabled={
                                                    selectedOption.kyc_status ==
                                                    "VERIFIED"
                                                      ? true
                                                      : false
                                                  }
                                                  validate={{
                                                    required: {
                                                      value:
                                                        !isKartaNameSame ||
                                                        isAddressUploadReq
                                                          ? true
                                                          : false,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setAddressConsent(
                                                      e.target.checked
                                                    )
                                                  }
                                                ></AvField>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      {startKyc &&
                                        corporateInfo &&
                                        !dematDetails &&
                                        !bankDetails && (
                                          <>
                                            <div className="mt-3">
                                              {loading && <Loader />}
                                              <AvField
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                errorMessage="Please Enter valid input"
                                                validate={{
                                                  required: {
                                                    value: mcrFile
                                                      ? false
                                                      : true,
                                                  },
                                                }}
                                                onChange={e =>
                                                  setMcrDoc(e.target.files[0])
                                                }
                                                name="mcrFile"
                                                label=" Upload Company Master Data"
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p className="mt-3">
                                                {mcrFile
                                                  ? `File uploaded: ${mcrFile
                                                      .split("/")
                                                      .pop()}`
                                                  : null}
                                              </p>
                                            </div>
                                            <div className="mt-3">
                                              {loading && <Loader />}
                                              <AvField
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                errorMessage="Please Enter valid input"
                                                validate={{
                                                  required: {
                                                    value: brFile
                                                      ? false
                                                      : true,
                                                  },
                                                }}
                                                onChange={e =>
                                                  setBrDoc(e.target.files[0])
                                                }
                                                name="brFile"
                                                label="Upload Board Resolution"
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p className="mt-3">
                                                {brFile
                                                  ? `File uploaded: ${brFile
                                                      .split("/")
                                                      .pop()}`
                                                  : null}
                                              </p>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="isAuthorisedName"
                                                label="Is the authorised person as per the Board Resolution same as in Company Master Data."
                                                type="checkbox"
                                                errorMessage="Please check this"
                                                value={
                                                  isAuthorisedName || false
                                                }
                                                onChange={e =>
                                                  setIsAuthorisedName(
                                                    e.target.checked
                                                  )
                                                }
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>
                                            {!isAuthorisedName && (
                                              <>
                                                <div className="mb-3">
                                                  <AvField
                                                    name="authorisedName"
                                                    label="Authorised Person Name"
                                                    type="text"
                                                    errorMessage="Please Enter valid input"
                                                    value={
                                                      authorisedName
                                                        ? authorisedName
                                                        : ""
                                                    }
                                                    validate={{
                                                      required: {
                                                        value: !isAuthorisedName
                                                          ? true
                                                          : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAuthorisedName(
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={
                                                      selectedOption.kyc_status ==
                                                      "VERIFIED"
                                                        ? true
                                                        : false
                                                    }
                                                  ></AvField>
                                                </div>
                                                <div className="mt-3">
                                                  {loading && <Loader />}
                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value: !isAuthorisedName
                                                          ? authAadharFile
                                                            ? false
                                                            : true
                                                          : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAuthAadharDoc(
                                                        e.target.files[0]
                                                      )
                                                    }
                                                    name="authAddressProof"
                                                    label="Authorised Person
                                                        Address Proof"
                                                    disabled={
                                                      selectedOption.kyc_status ==
                                                      "VERIFIED"
                                                        ? true
                                                        : false
                                                    }
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {authAadharFile
                                                      ? `File uploaded: ${authAadharFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                                <div className="mt-3">
                                                  {loading && <Loader />}
                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value: !isAuthorisedName
                                                          ? authPanFile
                                                            ? false
                                                            : true
                                                          : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAuthPanDoc(
                                                        e.target.files[0]
                                                      )
                                                    }
                                                    name="authPanCard"
                                                    label="Authorised Person PAN
                                                        Card"
                                                    disabled={
                                                      selectedOption.kyc_status ==
                                                      "VERIFIED"
                                                        ? true
                                                        : false
                                                    }
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {authPanFile
                                                      ? `File uploaded: ${authPanFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      {startKyc &&
                                        hufInfo &&
                                        !dematDetails &&
                                        !bankDetails && (
                                          <>
                                            <div className="mb-3">
                                              <AvField
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                                name="kartaPanValue"
                                                label="Enter Karta's PAN"
                                                type="text"
                                                errorMessage="Enter a valid input"
                                                value={kartaPanValue || ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                maxLength="10"
                                                onChange={handleKartaPanValue}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p style={{ color: "red" }}>
                                                {errorDetail &&
                                                  "Please enter valid input"}
                                              </p>
                                            </div>
                                            <div className="mt-3">
                                              {loading && <Loader />}
                                              <AvField
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                errorMessage="Please Enter valid input"
                                                validate={{
                                                  required: {
                                                    value: kartaPanFile
                                                      ? false
                                                      : true,
                                                  },
                                                }}
                                                onChange={handleKartaPanUpload}
                                                name="kartaPanFile"
                                                label="Upload Karta PAN"
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p className="mt-3">
                                                {kartaPanFile
                                                  ? `File uploaded: ${kartaPanFile
                                                      .split("/")
                                                      .pop()}`
                                                  : ""}
                                              </p>
                                              {/* <p className="mt-3">
                                                    Kindly upload copy of PAN
                                                    card front side in
                                                    pdf/jpg/png format (max file
                                                    size 1MB)
                                                  </p> */}
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="address"
                                                label="Address"
                                                type="textArea"
                                                errorMessage="Please Enter valid input"
                                                value={address ? address : ""}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e =>
                                                  setAddress(e.target.value)
                                                }
                                                disabled={
                                                  !editAddress ||
                                                  selectedOption.kyc_status ==
                                                    "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                              <p
                                                style={{
                                                  color: "#34c38f",
                                                  cursor: "pointer",
                                                  textAlign: "end",
                                                }}
                                                onClick={() =>
                                                  handleEditAddress()
                                                }
                                              >
                                                Edit
                                              </p>
                                            </div>
                                            {editAddress && (
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: isAddressUploadReq
                                                        ? addressFile
                                                          ? false
                                                          : true
                                                        : false,
                                                    },
                                                  }}
                                                  onChange={handleAddressUpload}
                                                  name="addressFile"
                                                  label="Upload Address Proof"
                                                  disabled={
                                                    !editAddress ||
                                                    selectedOption.kyc_status ==
                                                      "VERIFIED"
                                                      ? true
                                                      : false
                                                  }
                                                ></AvField>
                                                <p className="mt-3">
                                                  {addressFile
                                                    ? `File uploaded: ${addressFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                            )}
                                            <div className="mb-3">
                                              <AvField
                                                name="isAuthorisedName"
                                                label="Is Karta Name same as HUF Name"
                                                type="checkbox"
                                                errorMessage="Please check this"
                                                value={isKartaNameSame || false}
                                                onChange={e =>
                                                  setKartaNameSame(
                                                    e.target.checked
                                                  )
                                                }
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>
                                            {!isKartaNameSame && (
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: !isKartaNameSame
                                                        ? kartaVerfiFile
                                                          ? false
                                                          : true
                                                        : false,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setKartaVerfiDoc(
                                                      e.target.files[0]
                                                    )
                                                  }
                                                  name="kartVerifyDoc"
                                                  label="Karta Verification
                                                      Document"
                                                  disabled={
                                                    selectedOption.kyc_status ==
                                                    "VERIFIED"
                                                      ? true
                                                      : false
                                                  }
                                                ></AvField>
                                                <p className="mt-3">
                                                  {kartaVerfiFile
                                                    ? `File uploaded: ${kartaVerfiFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                            )}
                                            {(!isKartaNameSame ||
                                              isAddressUploadReq) && (
                                              <div className="mb-3">
                                                <AvField
                                                  name="addressConsent"
                                                  label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                  type="checkbox"
                                                  errorMessage="Please check this"
                                                  value={
                                                    addressConsent || false
                                                  }
                                                  validate={{
                                                    required: {
                                                      value:
                                                        !isKartaNameSame ||
                                                        isAddressUploadReq
                                                          ? true
                                                          : false,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setAddressConsent(
                                                      e.target.checked
                                                    )
                                                  }
                                                  disabled={
                                                    selectedOption.kyc_status ==
                                                    "VERIFIED"
                                                      ? true
                                                      : false
                                                  }
                                                ></AvField>
                                              </div>
                                            )}
                                          </>
                                        )}

                                      {startKyc &&
                                        (personlInfo ||
                                          corporateInfo ||
                                          hufInfo) &&
                                        dematDetails &&
                                        !bankDetails && (
                                          <>
                                            <div className="mb-3">
                                              <AvField
                                                name="demat"
                                                label="DEMAT ACCOUNT NUMBER"
                                                type="text"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  selectedOption?.user_demat
                                                    ?.demat_id
                                                    ? selectedOption?.user_demat
                                                        ?.demat_id
                                                    : ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>

                                            <div className="mt-3">
                                              {loading && <Loader />}
                                              <Label
                                                htmlFor="formFile"
                                                className="form-label"
                                              >
                                                Upload Document
                                              </Label>

                                              <Input
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={handleDematUpload}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <p className="mt-3">
                                                {dematFile
                                                  ? `File uploaded: ${dematFile
                                                      .split("/")
                                                      .pop()}`
                                                  : null}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      {startKyc &&
                                        (personlInfo ||
                                          corporateInfo ||
                                          hufInfo) &&
                                        dematDetails &&
                                        bankDetails && (
                                          <>
                                            <div className="mb-3">
                                              {loading && <Loader />}
                                              <AvField
                                                name="ifsc"
                                                label="IFSC Code"
                                                type="text"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  selectedOption
                                                    ?.user_bank_account
                                                    ?.ifsc_code
                                                    ? selectedOption
                                                        ?.user_bank_account
                                                        ?.ifsc_code
                                                    : ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="account_number"
                                                label="Account Number"
                                                type="text"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  selectedOption
                                                    ?.user_bank_account
                                                    ?.account_number
                                                    ? selectedOption
                                                        ?.user_bank_account
                                                        ?.account_number
                                                    : ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedOption.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="account_name"
                                                label="Account Name (will be autofilled)"
                                                type="text"
                                                disabled
                                                value={accountName || ""}
                                              ></AvField>
                                            </div>
                                          </>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {startKyc ? (
                                      <Col>
                                        <div className="text-start mt-3">
                                          <button
                                            type="button"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              (startKyc &&
                                                !(
                                                  personlInfo ||
                                                  corporateInfo ||
                                                  hufInfo
                                                ) &&
                                                !dematDetails &&
                                                !bankDetails)
                                                ? true
                                                : false
                                            }
                                            onClick={prevKycStep}
                                          >
                                            {"PREVIOUS"}
                                          </button>
                                        </div>
                                      </Col>
                                    ) : null}
                                    {selectedOption.kyc_status !=
                                      "VERIFIED" && (
                                      <Col>
                                        <div
                                          className={
                                            !startKyc
                                              ? "text-end mt-3"
                                              : "text-center mt-3"
                                          }
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              selectedOption.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          >
                                            {!startKyc ||
                                            (startKyc &&
                                              (personlInfo ||
                                                corporateInfo ||
                                                hufInfo) &&
                                              dematDetails &&
                                              bankDetails)
                                              ? "SAVE"
                                              : "SAVE & CONTINUE"}
                                          </button>
                                        </div>
                                      </Col>
                                    )}
                                    {startKyc &&
                                    (personlInfo || corporateInfo || hufInfo) &&
                                    dematDetails &&
                                    bankDetails &&
                                    selectedOption.kyc_status != "VERIFIED" ? (
                                      <Col>
                                        <div className="text-center mt-3">
                                          <button
                                            type="button"
                                            className="btn btn-success save-user"
                                            onClick={finalSubmit}
                                            disabled={
                                              loading ||
                                              disableKyc ||
                                              selectedOption.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          >
                                            {"SUBMIT"}
                                          </button>
                                        </div>
                                      </Col>
                                    ) : null}
                                    {startKyc ? (
                                      <Col>
                                        <div className="text-end mt-3">
                                          <button
                                            type="button"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              (startKyc &&
                                                (personlInfo ||
                                                  corporateInfo ||
                                                  hufInfo) &&
                                                dematDetails &&
                                                bankDetails)
                                                ? true
                                                : false
                                            }
                                            onClick={nextKycStep}
                                          >
                                            {"NEXT"}
                                          </button>
                                        </div>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorKycList
