import React, { useState, useEffect } from "react"
import { Modal } from "reactstrap"
import logoLightSvg from "assets/images/apple-touch-icon.png"
import PropTypes from "prop-types"

function ErrorModal({ text, setQuery }) {
  const [modal_backdrop, setmodal_backdrop] = useState(true)
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
  }

  return (
    <div>
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop()
        }}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <img
            style={{
              maxWidth: "30px",
              marginLeft: "212px",
            }}
            src={logoLightSvg}
            alt="earnnest"
          ></img>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_backdrop(false)
              setQuery("")
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h3>{text}</h3>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setmodal_backdrop(false)
              setQuery("")
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ErrorModal
ErrorModal.propTypes = {
  text: PropTypes.string,
  setQuery: PropTypes.func,
}
