import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Logon/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgotPassword"

// Dashboard
import MyDashboard from "../pages/MyDashboard/MyDashboard"
import UserListing from ".././pages/UsersList/UserListingTable"
import BrokerUserListing from ".././pages/BrokerUsers/BrokerUserListing"
import InvestInterest from "pages/Invest-Interest/InvestInterestListing"
import ContactUs from "pages/ContactUs/ContactUsListing"
import EmailSubscribe from "pages/EmailSubscribe/EmailSubscribeLsting"
import GeoDataTable from "pages/GeoData/GeoDataTable"
import RolePermission from "pages/RolePermission/RolePermission"
import Investment from "pages/Investment/InvetmentTable"
import InvestmentResource from "pages/InvestmentResource/InvestmentResource"
import Permission from "pages/Permission/Permission"
import InvestmentRisk from "pages/InvestmentRisk/InvestmentRisk"
import WhyInvest from "pages/WhyInvest/WhyInvest"
import ScheduleAcall from "pages/ScheduleAcall/ScheduleAcall"
import SponserOtherProjects from "pages/SponserOtherProjects/SponserOtherProjects"
import SponserPromoter from "pages/SponserPromoter/SponserPromoter"
import InvestmentFaq from "pages/InvestmentFAQ/InvestmentFaq"
import SponserMaster from "pages/SponsorMaster/SponsorMaster"
import KycList from "pages/Kyc/KycList"
import InvestmentPerfHistory from "pages/InvestmentPerfHistory/InvestmentPerfHistory"
import UnderlyingAsset from "pages/UnderlyingAsset/UnderlyingAsset"
import UnderlyingAssetLocationData from "pages/UnderlyingAssetLocationData/UnderlyingAssetLocationData"
import AssetImage from "pages/AssetImage/AssetImage"
import Role from "pages/Role/Role"
import OpsUsers from "pages/OpsUsers/OpsUsers"
import InvestmentCreationPolicy from "pages/InvestmentCreationPolicy/InvestmentCreationPolicy"
import InvestmentCreationRequest from "pages/InvestmentCreationRequest/InvestmentCreationRequest"
import InvestmentPublishingWorkflow from "pages/InvestmentPublishingWorkflow/InvestmentPublishingWorkflow"
import InvestmentCashFlows from "pages/InvestmentCashFlows/InvestmentCashFlows"
import TransactionDump from "pages/TransactionsWebhookDumb/TransactionsDump"
import InvestmentAttribute from "pages/InvestmentAttribute/InvestmentAttribute"
import CreationApprovalQueue from "pages/CreationApprovalQueue/CreationApprovalQueue"
import PublishingApprovalQueue from "pages/PublishingApprovalQueue/PublishingApprovalQueue"
import Transactions from "pages/UserInvestment/Transactions"
import userIvestment from "pages/UserInvestment/userIvestment"
import SectionHeader from "pages/SectionHeader/SectionHeader"
import InvestmentTranche from "pages/InvestmentTranche/InvestmentTranche"
import CMSListingTable from "pages/CMS/CMSListingTable"
import PortfolioDocument from "pages/PotfolioDocument/PortfolioDocument"
import PortfolioDocumentType from "pages/PortfolioDocumentType/PortfolioDocumentType"
import Repayment from "pages/Repayment/Repayment"
import InvestmentAssetManagement from "pages/PortfolioManagement/InvestmentAssetManagement"
import InvestmentProgressPhoto from "pages/PortfolioManagement/InvestmentProgressPhoto"
import InvestmentProjection from "pages/PortfolioManagement/InvestmentProjection"
import GetMyUsers from "pages/GetMyUsers/GetMyUsers"
import DistributorKycList from "pages/DistributorKyc/DistributorKycList"
import DistributorKyc from "pages/ManageDistributor/DistributorKyc"
import DistributorUserInvestment from "pages/DistributorUserInvestment/distributorUserInvestment"
import DistributorRepayment from "pages/DistributorUserInvestment/DistributorRepayment"
import DistributorTransactions from "pages/DistributorUserInvestment/DistributorTransactions"
import DistributorUserInvestmentTopup from "pages/DistributorUserInvestment/DistributorUserInvestmentTopup"
import DistributorReport from "pages/DistributorReport/DistributorReport"
import DealCashflowReturns from "pages/DealCashflowReturns/DealCashflowReturns"
import DistributorAgreement from "pages/ManageDistributor/DistributorAgreement"
import DistributorAddendum from "pages/ManageDistributor/DistributorAddendum"
import Kyc from "pages/DistributorKyc/Kyc"
import DistributorLeads from "pages/DistributorLeads/DistributorLeads"
import DistributorUserKyc from "pages/DistributorKyc/DistributorUserKyc"
import InvestorDashboard from "pages/InvestorDashboard/InvestorDashboard"
import TcsTransaction from "pages/UserInvestment/TcsTransaction"
import Dashboard from "pages/Dashboard/Dashboard"
import Home from "pages/Home/Home"
import Reports from "pages/Reports/Reports"
import InvestorWiseExposure from "pages/InvestorWiseExposure/InvestorWiseExposure"
import UserInvestmentTopUp from "pages/UserInvestmentTopUp/UserInvestmentTopUp"
import TransactionsTopUp from "pages/UserInvestmentTopUp/TransactionsTopUp"
import TopUpTcsTransaction from "pages/UserInvestment/TopUpTcsTransaction"
import ProjectsCashflow from "pages/ProjectsCashflow/ProjectsCashflow"
import BondPriceMaster from "pages/BondPriceMaster/BondPriceMaster"
import DistributorTransactionTopup from "pages/DistributorUserInvestment/DistributorTransactionTopup"
import CompletedUserInvestment from "pages/CompletedUserInvestment/CompletedUserInvestment"
import InvestmentLot from "pages/InvestmentLot/InvestmentLot"
import BondInventoryManagement from "pages/BondInventoryManagement/BondInventoryManagement"
import EmailLogsListing from "pages/EmailLogs/EmailLogsListing"
import TradeLogsListing from "pages/TradeLogs/TradeLogsListing"
import MasterDashboard from "pages/Dashboard/MasterDashboard"
import RmUserInvestment from "pages/RM/RmUserInvestment"
import RmUserInvestmentTopup from "pages/RM/RmUserInvestmentTopup"
import VpaReports from "pages/VpaReports/VpaReports"
import DebentureTrusteeMaster from "pages/DebentureTrusteeMaster/DebentureTrusteeMaster"
import DepositoryMaster from "pages/DepositoryMaster/DepositoryMaster"
import TransferorMaster from "pages/TransferorMaster/TransferorMaster"
import StfDocuments from "pages/StfDocuments/StfDocuments"
import RmWebhookDump from "pages/RmWebhookDump/RmWebhookDump"
import DematBeneficiaryDocuments from "pages/DematBeneficiary/DematBeneficiaryDocuments"
import DematBeneficiaryReport from "pages/DematBeneficiary/DematBeneficiaryReport"
import BenPosMaster from "pages/BenPos/BenPosMaster"
import BenPosReport from "pages/BenPos/BenPosReport"
import RmStfStatus from "pages/RM/RmStfStatus"
import ChangePassword from "pages/Authentication/ChangePassword"
import UniqueClientCode from "pages/UniqueClientCode/UniqueClientCode"
import CampaignLeads from "pages/CampaignLeads/CampaignLeads"
import XirrReport from "pages/Reports/XirrReport/XirrReport"
import InvestorCountSummary from "pages/Reports/InvestorCountSummary/InvestorCountSummary"
import DematTransfers from "pages/DematTransfers/DematTransfers"

const authProtectedRoutes = [
  { path: "/my-dashboard", exact: true, component: MyDashboard },
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/platform-dashboard", exact: true, component: MasterDashboard },
  { path: "/investor-dashboard", exact: true, component: InvestorDashboard },
  {
    path: "/deal-cashflow-returns",
    component: DealCashflowReturns,
  },

  //profile page
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  //UserListing page
  { path: "/users", component: UserListing },
  {
    path: "/demat-beneficiary-documents",
    component: DematBeneficiaryDocuments,
  },
  {
    path: "/unique-client-code",
    component: UniqueClientCode,
  },
  {
    path: "/demat-beneficiary-report",
    component: DematBeneficiaryReport,
  },
  //Broker User
  { path: "/distributor-users", component: BrokerUserListing },

  { path: "/distributor-kyc-list", component: DistributorKycList },
  { path: "/distributor-kyc", component: DistributorKyc },
  { path: "/distributor-user-kyc/:userId", component: DistributorUserKyc },
  { path: "/distributor-agreement", component: DistributorAgreement },
  { path: "/distributor-addendum", component: DistributorAddendum },
  {
    path: "/distributor-user-investment",
    component: DistributorUserInvestment,
  },
  {
    path: "/distributor-topup-user-investment",
    component: DistributorUserInvestmentTopup,
  },
  {
    path: "/reports/investment-transactions",
    component: DistributorTransactions,
  },
  {
    path: "/reports/completed-investments",
    component: CompletedUserInvestment,
  },
  {
    path: "/reports/investor-count-summary",
    component: InvestorCountSummary,
  },
  { path: "/reports/interest-and-repayments", component: DistributorRepayment },
  {
    path: "/distributor-topup-transaction",
    component: DistributorTransactionTopup,
  },
  { path: "/distributor-report", component: DistributorReport },
  { path: "/investor-wise-exposure", component: InvestorWiseExposure },

  { path: "/reports", component: Reports },
  { path: "/kyc", component: Kyc },

  // RM User
  { path: "/rm-users", component: BrokerUserListing },
  { path: "/rm-user-kyc/:userId", component: DistributorUserKyc },
  {
    path: "/rm-user-investment",
    component: RmUserInvestment,
  },
  {
    path: "/rm-user-investment-topup",
    component: RmUserInvestmentTopup,
  },
  {
    path: "/rm-stf-status",
    component: RmStfStatus,
  },
  {
    path: "/campaign-leads",
    component: CampaignLeads,
  },

  //CMS listing page
  { path: "/cms", component: CMSListingTable },
  { path: "/distributor-leads", component: DistributorLeads },
  { path: "/invest-interest", component: InvestInterest },

  { path: "/contact-us", component: ContactUs },
  { path: "/email-logs", component: EmailLogsListing },
  { path: "/trade-logs", component: TradeLogsListing },
  { path: "/email-subscribe", component: EmailSubscribe },

  //CMS create entry page

  // KYC listing
  { path: "/kyc-list", component: KycList },

  { path: "/geo-data", component: GeoDataTable },
  { path: "/role-permission", component: RolePermission },
  { path: "/investment", component: Investment },
  { path: "/permission", component: Permission },

  { path: "/investment-resource", component: InvestmentResource },
  { path: "/investment-risk", component: InvestmentRisk },
  { path: "/why-invest", component: WhyInvest },
  { path: "/schedule-call", component: ScheduleAcall },
  { path: "/sponsor-other-projects", component: SponserOtherProjects },
  { path: "/sponsor-promoter", component: SponserPromoter },
  { path: "/investment-faq", component: InvestmentFaq },
  { path: "/sponsor-master", component: SponserMaster },
  { path: "/investment-perf-history", component: InvestmentPerfHistory },
  { path: "/underlying-asset", component: UnderlyingAsset },
  { path: "/underlying-asset-media", component: AssetImage },
  { path: "/investment-cash-flows", component: InvestmentCashFlows },
  { path: "/cash-flow-master", component: ProjectsCashflow },
  { path: "/bond-price-master", component: BondPriceMaster },

  {
    path: "/underlying-asset-location-data",
    component: UnderlyingAssetLocationData,
  },
  { path: "/role", component: Role },
  { path: "/ops-users", component: OpsUsers },
  { path: "/vpa-reports", component: VpaReports },
  { path: "/depository-master", component: DepositoryMaster },
  { path: "/debenture-trustee-master", component: DebentureTrusteeMaster },
  { path: "/transferor-master", component: TransferorMaster },
  { path: "/stf-documents", component: StfDocuments },
  { path: "/demat-transfers", component: DematTransfers },
  {
    path: "/investment-creation-policy",
    component: InvestmentCreationPolicy,
  },
  {
    path: "/investment-creation-request",
    component: InvestmentCreationRequest,
  },
  {
    path: "/investment-publishing-workflow",
    component: InvestmentPublishingWorkflow,
  },

  {
    path: "/transaction-dump",
    component: TransactionDump,
  },
  {
    path: "/rm-va-transactions",
    component: RmWebhookDump,
  },
  {
    path: "/transactions",
    component: Transactions,
  },

  {
    path: "/user-investment",
    component: userIvestment,
  },
  {
    path: "/tcs-transactions",
    component: TcsTransaction,
  },
  {
    path: "/top-up-tcs-transactions",
    component: TopUpTcsTransaction,
  },
  {
    path: "/transactions-topup",
    component: TransactionsTopUp,
  },

  {
    path: "/user-investment-topup",
    component: UserInvestmentTopUp,
  },

  {
    path: "/investment-attribute",
    component: InvestmentAttribute,
  },

  {
    path: "/creation-approval-queue",
    component: CreationApprovalQueue,
  },

  {
    path: "/publishing-approval-queue",
    component: PublishingApprovalQueue,
  },

  {
    path: "/publishing-approval-queue",
    component: PublishingApprovalQueue,
  },

  {
    path: "/investment-section-header",
    component: SectionHeader,
  },

  {
    path: "/investment-tranche",
    component: InvestmentTranche,
  },
  {
    path: "/investment-lot",
    component: InvestmentLot,
  },
  {
    path: "/bond-inventory-management",
    component: BondInventoryManagement,
  },
  {
    path: "/portfolio-document",
    component: PortfolioDocument,
  },

  {
    path: "/portfolio-document-type",
    component: PortfolioDocumentType,
  },

  {
    path: "/benpos-master",
    component: BenPosMaster,
  },

  {
    path: "/benpos-report",
    component: BenPosReport,
  },

  {
    path: "/repayment",
    component: Repayment,
  },

  {
    path: "/irr-report",
    component: XirrReport,
  },

  {
    path: "/investment-asset-management",
    component: InvestmentAssetManagement,
  },

  {
    path: "/investment-progress-media",
    component: InvestmentProgressPhoto,
  },

  {
    path: "/investment-projection",
    component: InvestmentProjection,
  },

  {
    path: "/get-my-users",
    component: GetMyUsers,
  },

  {
    path: "/",
    exact: true,
    component: Home,
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password", component: ChangePassword },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
