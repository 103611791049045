import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import ReactHtmlParser from "react-html-parser"
import { picUrl } from "../../constants/common"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import ReactQuill from "react-quill"
import EditorToolbar, { modules, formats } from "./EditorToolbar"
import "react-quill/dist/quill.snow.css"
import "./styles.css"
import Loader from "common/Loader"
import Cropper from "react-easy-crop"
import Slider from "@material-ui/core/Slider"
import getCroppedImg from "../AssetImage/cropImage"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { Pagination } from "constants/common"

const SponserOtherProjects = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [imgAltDescModal, setImgAltDescModal] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `sponsor-other-projects?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [dp, setDp] = useState(orderList.display_pic_url)
  const [state, setState] = useState({ value: null })

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
  const SpnsorOtherProjectsColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction >= 4 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "sponsor_master.sponsor_name",
      text: "Sponsor Name",
      sort: true,
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
    },

    {
      dataField: "year_completed",
      text: "Year Completed",
      sort: true,
    },
    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "display_pic_url",
      text: "Display Pic",
      sort: true,
      formatter: (cellContent, row) => handleimg(row.display_pic_url),
    },
    {
      dataField: "image_alt_desc",
      text: "Display Pic Alt Description",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleimg = img => {
    const date1 = (
      <img
        src={`${picUrl}${img}`}
        alt="Display pic"
        style={{ maxWidth: "100px", maxHeight: "120px" }}
      ></img>
    )
    return date1
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    const order = arg
    setImage(null)
    setZoom(1)
    setState({ value: null })
    setOrderList({
      id: order.id,
      sponsor_name: order.sponsorMasterId,
      project_name: order.project_name,
      location: order.location,
      year_completed: order.year_completed,
      disp_order: order.disp_order,
      status: order.status,
      display_pic_url: order.display_pic_url,
      breif: order.breif,
      image_alt_desc: order.image_alt_desc,
      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete ${order.project_name}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `sponsor-other-projects/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (isEdit) {
      const updateOrder = {
        sponsorMasterId: values.sponsor_name,
        project_name: values.project_name,
        location: values.location,
        year_completed: values.year_completed,
        disp_order: values.disp_order,
        status: values.status,
        display_pic_url: dp || orderList.display_pic_url,
        breif: state.value || orderList.breif,
        created_at: values.created_at,
        image_alt_desc: values.image_alt_desc,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `sponsor-other-projects/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setState({ value: null })
          setDp(null)
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        sponsorMasterId: values["sponsor_name"],
        project_name: values["project_name"],
        location: values["location"],
        year_completed: values["year_completed"],
        disp_order: values["disp_order"],
        status: values["Status"],
        display_pic_url: dp,
        breif: state.value,
        created_at: values["created_at"],
        image_alt_desc: values.image_alt_desc,
      }
      // save new order

      if (!newOrder.display_pic_url) {
        toast.error("Please upload valid display pic ")
        return false
      }

      try {
        const response = await axiosInstance.post(
          `sponsor-other-projects`,
          newOrder
        )
        if (response) {
          setState({ value: null })
          setDp(null)
          masterData()
          toast.success(" Project Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
  }

  const handleOrderClicks = () => {
    setImage(null)
    setZoom(1)
    setState({ value: null })
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)

      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=underlying_asset_image`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  useEffect(async () => {
    try {
      const response = await axiosInstance.get(
        `sponsor-master?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }, [])

  const handleChange = value => {
    setState({ value })
  }

  /*Image Crop */

  const inputRef = React.useRef()

  const triggerFileSelectPopup = () => inputRef.current.click()

  const [image, setImage] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.addEventListener("load", () => {
        setImage(reader.result)
      })
    }
  }

  const onDownload = () => {
    const response = getCroppedImg(image, croppedArea)
    if (response) {
      response.then(file => setFile(file))
      setImage(null)
      setZoom(1)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Sponsor Other Projects" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={SpnsorOtherProjectsColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={SpnsorOtherProjectsColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              className="modal-lg"
                              isOpen={modal}
                              toggle={toggle}
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Project" : "Add Project"}
                              </ModalHeader>
                              <ModalBody>
                                {loading && <Loader />}
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="sponsor_name"
                                            label="Sponsor Master"
                                            type="select"
                                            className="form-select"
                                            errormessage="Invalid Sponsor Master"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.sponsor_name || ""}
                                            style={{ widht: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sponser
                                              .sort((a, b) =>
                                                a.sponsor_name?.toLowerCase() >
                                                b.sponsor_name?.toLowerCase()
                                                  ? 1
                                                  : -1
                                              )
                                              .map((item, index) => (
                                                <option
                                                  key={item.sponsor_name}
                                                  value={item.id}
                                                >
                                                  {item.sponsor_name}
                                                </option>
                                              ))}
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="text"
                                            errormessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div className="mb-3">
                                            <AvField
                                              name="location"
                                              label="Location"
                                              type="text"
                                              errormessage="Invalid Location"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.location || ""}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="Status"
                                            label="Status"
                                            type="text"
                                            errormessage="Invalid Payment Status"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.status || ""}
                                            style={{ widht: "100%" }}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="year_completed"
                                            label="Year Completed"
                                            type="text"
                                            errormessage="Invalid Year Completed"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.year_completed || ""
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="disp_order"
                                            label="Disp Order"
                                            type="number"
                                            errormessage="Invalid Disp Order"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.disp_order || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          {orderList.display_pic_url || dp ? (
                                            <img
                                              src={`${picUrl}${
                                                dp || orderList.display_pic_url
                                              }`}
                                              alt="Display Pic"
                                              style={{
                                                maxWidth: "100px",
                                                maxHeight: "120px",
                                              }}
                                            ></img>
                                          ) : null}

                                          <div className="container">
                                            <div className="container-cropper">
                                              {image ? (
                                                <>
                                                  <div className="cropper">
                                                    <Cropper
                                                      image={image}
                                                      crop={crop}
                                                      zoom={zoom}
                                                      aspect={1.58}
                                                      onCropChange={setCrop}
                                                      onZoomChange={setZoom}
                                                      onCropComplete={
                                                        onCropComplete
                                                      }
                                                      style={{
                                                        containerStyle: {
                                                          height: 240,
                                                        },
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                    className="slider"
                                                    style={{
                                                      marginTop: "190px",
                                                    }}
                                                  >
                                                    <Slider
                                                      min={1}
                                                      max={3}
                                                      step={0.1}
                                                      value={zoom}
                                                      onChange={(e, zoom) =>
                                                        setZoom(zoom)
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>

                                            <div
                                              className="container-buttons"
                                              style={{
                                                marginLeft: "-20px",
                                              }}
                                            >
                                              <input
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                                onChange={onSelectFile}
                                                style={{ display: "none" }}
                                              />

                                              <label>
                                                Other Projects Display Pic
                                              </label>

                                              <div
                                                style={{
                                                  marginTop: "50px",
                                                }}
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={
                                                    triggerFileSelectPopup
                                                  }
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  {orderList.display_pic_url
                                                    ? "Update Image"
                                                    : "Upload Image"}
                                                </Button>
                                                {image && (
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onDownload}
                                                  >
                                                    Save
                                                  </Button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <Modal
                                            isOpen={imgAltDescModal}
                                            toggle={() => {
                                              setImgAltDescModal(
                                                !imgAltDescModal
                                              )
                                            }}
                                          >
                                            <ModalHeader
                                              toggle={() => {
                                                setImgAltDescModal(
                                                  !imgAltDescModal
                                                )
                                              }}
                                              tag="h3"
                                            >
                                              Display Pic Alt Description
                                            </ModalHeader>
                                            <ModalBody>
                                              <p className="font-size-16">
                                                This will not appear on the
                                                website but it is required for
                                                SEO.
                                              </p>
                                            </ModalBody>
                                          </Modal>
                                          <Label>
                                            Display Pic Alt Description{" "}
                                            <i
                                              className="fas fa-info-circle"
                                              id="TooltipTop"
                                              onClick={() => {
                                                setImgAltDescModal(true)
                                              }}
                                            ></i>
                                          </Label>
                                          <AvField
                                            name="image_alt_desc"
                                            type="textarea"
                                            errorMessage="Invalid input"
                                            value={
                                              orderList.image_alt_desc || ""
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                      </div>
                                    </div>
                                    <Col className="col-12">
                                      <CardTitle
                                        style={{
                                          marginTop: "8px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Brief
                                      </CardTitle>
                                      {/* <CKEditor
                                            editor={ClassicEditor}
                                            onChange={handleEditorChange}
                                         />*/}
                                      {/* <div
                                            style={{
                                              marginTop: "2px",
                                              width: "100%",
                                              maxWidth: "200px",
                                            }}
                                          >
                                            {editorState
                                              ? ReactHtmlParser(editorState)
                                              : ReactHtmlParser(
                                                  orderList.breif
                                                )}
                                              </div> */}
                                      <div
                                        className="text-editor"
                                        style={{
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <EditorToolbar />
                                        <ReactQuill
                                          theme="snow"
                                          value={
                                            state?.value ||
                                            orderList?.breif ||
                                            " "
                                          }
                                          onChange={handleChange}
                                          placeholder={
                                            "Write something awesome..."
                                          }
                                          modules={modules}
                                          formats={formats}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        {!image && (
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SponserOtherProjects.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(SponserOtherProjects)
