import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import { humanize } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Label,
  Input,
} from "reactstrap"
import { isEmpty } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import paginationFactory from "react-bootstrap-table2-paginator"
import getBase64 from "base64"
import Breadcrumbs from "components/Common/Breadcrumb"

const DepositoryMaster = () => {
  const [loading, setLoading] = useState(false)
  const [dpData, setDpData] = useState([])
  const [base64, setBase64] = useState("")
  const [file, setFile] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [projectData, setProjectData] = useState({})
  const [prevDpData, setPrevDpData] = useState([])
  const [total, setTotal] = useState(0)
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const { SearchBar } = Search
  const [upload, setUpload] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [dpIdFilter, setdpIdFilter] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [addModal, setAddModal] = useState(false)
  const [dp_id, setDpId] = useState("")
  const [dp_name, setDpName] = useState("")
  const [depository, setDepository] = useState("")
  const [editData, setEditData] = useState(null)

  // State variables for Upload CSV modal
  const [uploadModal, setUploadModal] = useState(false)

  const accessRestriction = DealManagementAccess(AccessId?.ASSET_MANAGEMENT)
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const handleAddDataSubmit = async e => {
    e.preventDefault()

    const dataToAdd = {
      dp_id,
      dp_name,
      depository,
    }

    try {
      if (editData) {
        // Perform PATCH request to update data using axiosInstance
        const response = await axiosInstance.patch(
          `/depository-master/${editData.id}`,
          dataToAdd
        )
        if (response.status === 201 || 200) {
          masterData()
          toast.success("Data updated successfully")
        } else {
          toast.error("Failed to update data")
        }
      } else {
        // Perform POST request to add data using axiosInstance
        const response = await axiosInstance.post(
          "/depository-master",
          dataToAdd
        )
        if (response.status === 201 || 200) {
          masterData()
          toast.success("Data added successfully")
        } else {
          toast.error("Failed to add data")
        }
      }

      setDpId("")
      setDpName("")
      setDepository("")
      setEditData(null)
      toggleAddModal()
    } catch (error) {
      const msg =
        error?.response?.data?.errors[0]?.message || "An error occurred"
      toast.error(humanize(msg))
    }
  }
  const sampleCsv = [
    {
      dp_id: "12081600",
      dp_name: "Zerodha Broking Limited",
      depository: "Central Depository Services (India) Ltd.",
    },
    {
      dp_id: "IN300126",
      dp_name: "HDFC BANK LIMITED",
      depository: "National Securities Depository Ltd.",
    },
  ]
  const handleValidOrderSubmit = async (e, values) => {
    const dataToUpload = {
      dp_data: base64.replace("data:text/csv;base64,", ""),
    }
    if (base64) {
      try {
        const uploadResponse = await axiosInstance.post(
          "/upload-base-64?type=depository",
          dataToUpload
        )
        if (uploadResponse) {
          masterData()

          toast.success("Sucessfully Uploaded")
          setBase64("")
        }
      } catch (error) {
        const msg = error?.response?.data.message
        toast.error(msg)
      }
    } else {
      toast.error("Please select valid file")
      setLoading(false)
      return false
    }
    toggleUploadModal()
    setLoading(false)
  }
  useEffect(() => {
    if (editData) {
      setDpId(editData.dp_id || "")
      setDpName(editData.dp_name || "")
      setDepository(editData.depository || "")
    } else {
    }
  }, [editData])

  useEffect(() => {
    if (!dpIdFilter || dpIdFilter?.length >= 3) {
      masterData()
    }
  }, [dpIdFilter])

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClicks = async () => {
    setFile("")
    setBase64("")
    setIsEdit(false)
    toggleUploadModal()
  }

  const handleAddDataClick = () => {
    setDpId("")
    setDpName("")
    setDepository("")
    toggleAddModal()
  }
  useEffect(() => {
    setOrderList(dpData)
  }, [dpData])

  useEffect(() => {
    if (!isEmpty(dpData) && !!isEdit) {
      setOrderList(dpData)
      setIsEdit(false)
    }
  }, [dpData])

  const handleOrderClick = arg => {
    const order = arg
    setUpload(false)
    setBase64("")
    setEditData({
      id: order.id,
      dp_id: order.dp_id,
      dp_name: order.dp_name,
      depository: order.depository,
      created_at: moment(order.created_at).format("YYYY-MM-DD"),
    })
    setIsEdit(true)
    toggleAddModal()
  }

  const getUrl = `depository-master`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setTotal(response.data.total)
        setDpData(response?.data?.data || response?.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `depository-master/${order.id}`
        )
        if (response) {
          masterData()
          // setDpData((prevData) => prevData.filter(item => item.id !== dpData.id));
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }

    setLoading(false)
  }

  const DpDataColumn = () => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, dpData) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(dpData)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction >= 4 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(dpData)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "dp_id",
      text: "DP ID",
      sort: true,
    },
    {
      dataField: "dp_name",
      text: "DP Name",
      sort: true,
    },
    {
      dataField: "depository",
      text: "Depository",
      sort: true,
    },
  ]

  const downloadData = (data, name, csvTableHeaders) => {
    const downloadableArr = data.map(item => ({
      dp_id: item.dp_id,
      dp_name: item.dp_name,
      depository: item.depository,
    }))
    JSONToCSVConvertor(downloadableArr, name, true)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Depository`, true)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Depository Master" />
          <Row>
            <Col xs="12">
              {
                <>
                  <Card>
                    <CardBody>
                      <React.Fragment>
                        <Row>
                          <Col xs="12">
                            <ToolkitProvider
                              keyField="id"
                              data={dpData}
                              columns={DpDataColumn()}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2 justify-content-between ">
                                    <Col sm="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <div className="col-auto">
                                      {(accessRestriction >= 1 ||
                                        accessRestriction === "SuperAdmin") && (
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleAddDataClick}
                                        >
                                          <i className="mdi mdi-plus me-1" />{" "}
                                          Add
                                        </Button>
                                      )}
                                      {(accessRestriction >= 1 ||
                                        accessRestriction === "SuperAdmin") && (
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleOrderClicks}
                                        >
                                          <i className="mdi mdi-arrow-up-bold-circle" />{" "}
                                          Upload
                                        </Button>
                                      )}
                                      {accessRestriction >= 1 ||
                                      accessRestriction === "SuperAdmin" ? (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={() =>
                                            handleCsvDownload(
                                              sampleCsv,
                                              "Sample",
                                              true
                                            )
                                          }
                                          style={{ marginRight: "3px" }}
                                        >
                                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                          Sample CSV
                                        </Button>
                                      ) : null}
                                      {(accessRestriction >= 1 ||
                                        accessRestriction === "SuperAdmin") && (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={() => {
                                            downloadData(
                                              dpData,
                                              `depository`,
                                              DpDataColumn
                                            )
                                          }}
                                          style={{ marginRight: "3px" }}
                                        >
                                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                          Download
                                        </Button>
                                      )}
                                    </div>
                                    <Col xl="12">
                                      <BootstrapTable
                                        keyField="id"
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        columns={DpDataColumn()}
                                        data={dpData}
                                        pagination={paginationFactory(
                                          pageOptions
                                        )}
                                        wrapperClasses={"table-responsive mb-4"}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </Col>
                        </Row>
                      </React.Fragment>
                    </CardBody>
                  </Card>
                </>
              }
            </Col>
          </Row>
          <Modal isOpen={addModal} toggle={toggleAddModal}>
            <ModalHeader toggle={toggleAddModal} tag="h4">
              {isEdit ? "Update Despository Data" : "Add Depository Data"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleAddDataSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      type="text"
                      name="dp_id"
                      label="DP ID"
                      id="dp_id"
                      value={dp_id}
                      onChange={e => setDpId(e.target.value)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      type="text"
                      name="dp_name"
                      label="DP Name"
                      id="dp_name"
                      value={dp_name}
                      onChange={e => setDpName(e.target.value)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      className="form-select"
                      name="depository"
                      label="Depository"
                      id="depository"
                      value={depository}
                      onChange={e => setDepository(e.target.value)}
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option disabled value="">
                        Select
                      </option>
                      <option value="National Securities Depository Limited">
                        National Securities Depository Limited
                      </option>
                      <option value="Central Depository Services (I) Limited">
                        Central Depository Services (I) Limited
                      </option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-user"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          {/* Upload CSV Modal */}
          <Modal isOpen={uploadModal} toggle={toggleUploadModal}>
            <ModalHeader toggle={toggleUploadModal} tag="h4">
              Upload Depository Data
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidOrderSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <Label htmlFor="formFile" className="form-label">
                      Upload CSV *
                    </Label>
                    <Input
                      className="form-control"
                      type="file"
                      accept=".csv"
                      id="formFile"
                      onChange={onSelectFile}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-user"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DepositoryMaster
