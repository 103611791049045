import React from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { getOptionLabel, getSearchOptionLabel } from "./common"

export default function ReactSelect({
  users: optionList,
  setSelectedOption,
  selectedOption,
  roles,
  transaction,
  investment,
  multiOptionLabel,
  optionLabelKeys,
  isDisabled = false,
  isClearable = false,
  searchValue,
  setSearchValue,
}) {
  return (
    <Select
      defaultValue={selectedOption}
      value={selectedOption}
      onChange={setSelectedOption}
      options={optionList}
      getOptionLabel={option => {
        if (multiOptionLabel) {
          return getSearchOptionLabel({ option, optionLabelKeys })
        } else {
          return getOptionLabel({
            option,
            transaction,
            investment,
            roles,
            keys: optionLabelKeys,
          })
        }
      }}
      getOptionValue={option => option.id}
      isDisabled={isDisabled}
      isClearable={isClearable}
      inputValue={searchValue}
      onInputChange={setSearchValue}
    />
  )
}

ReactSelect.propTypes = {
  users: PropTypes.array,
  setSelectedOption: PropTypes.func,
  selectedOption: PropTypes.object,
  transaction: PropTypes.bool,
  roles: PropTypes.array,
  multiOptionLabel: PropTypes.bool,
  optionLabelKeys: PropTypes.array,
  investment: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
}
