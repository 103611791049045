import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"

import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { csvDownloadData } from "constants/common"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions"
import Loader from "../../common/Loader"

const ScheduleAcall = props => {
  const dispatch = useDispatch()

  const [orders, setData] = useState([])
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [exportData, setExportData] = useState([])
  const [loading, setLoading] = useState(false)

  const masterData = async () => {
    setLoading(true)
    try {
      const down = await axiosInstance.get(`schedule-call?$sort[created_at]=-1`)

      if (down) {
        setData(down?.data)
        setExportData(down?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.SUPPORT)

  const toggleViewModal = () => setModal1(!modal1)

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },

    {
      dataField: "user.user_name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "user.email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "user.phone",
      text: "Phone",
      sort: true,
    },

    {
      dataField: "call_time",
      text: "Call Time",
      sort: true,
      formatter: (cellContent, row) => handleCallTime(row.call_time),
    },
    {
      dataField: "text",
      text: "Text",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
  ]

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders())
    }
  }, [dispatch, orders])

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const rawDate = milliseconds => {
    var d = new Date(parseInt(milliseconds, 10))
    var ds = d.toString("MM/dd/yy HH:mm:ss")
    return ds
  }

  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = EcommerceOrderColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleCallTime = time => {
    return time
      ? moment(rawDate(time)).format("MMM Do yy hh:mm a")
      : "Time Not Selected"
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Schedule Call" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={EcommerceOrderColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            {accessRestriction == "4" ||
                            accessRestriction === "SuperAdmin" ? (
                              <button
                                type="button"
                                className="btn btn-primary btn-rounded"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Schedule Call",
                                    true
                                  )
                                }
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </button>
                            ) : null}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={EcommerceOrderColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Promoter" : "Add Promoter"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="name"
                                          label="Name"
                                          type="text"
                                          errorMessage="Invalid Name"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.name || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="email"
                                          label="Email"
                                          type="text"
                                          errorMessage="Invalid Email"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.email || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="contact"
                                          label="Contact"
                                          type="number"
                                          errorMessage="Invalid Contact"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.contact || ""}
                                          style={{ widht: "100%" }}
                                        ></AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="address"
                                          label="Address"
                                          type="text"
                                          errorMessage="Invalid Total"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.address || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="networth"
                                          label="Networth"
                                          type="number"
                                          errorMessage="Invalid Payment Status"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.networth}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="networth_date"
                                          label="Networth Date "
                                          type="date"
                                          errorMessage="Invalid Brief"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.networth_date || ""}
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ScheduleAcall.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(ScheduleAcall)
