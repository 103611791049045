import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactHtmlParser from "react-html-parser"
import ReactQuill from "react-quill"
import EditorToolbar, {
  modules,
  formats,
} from "../SponserOtherProjects/EditorToolbar"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { Pagination } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"

const SponserMaster = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [state, setState] = useState({ value: null })
  const [loading, setLoading] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `sponsor-master?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)

  const SpnsorMasterColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction >= 4 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "sponsor_name",
      text: "Sponsor Name",
      sort: true,
    },
    {
      dataField: "sponsor_experience_years",
      text: "Sponsor Experience Years",
      sort: true,
    },

    {
      dataField: "sponsor_experience_volume",
      text: "Sponsor Experience Volume",
      sort: true,
    },
    {
      dataField: "sponsor_experience_ui",
      text: "Sponsor Experience Ui",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = str => {
    return str.toLowerCase()
  }

  const handleOrderClick = arg => {
    const order = arg
    setState({ value: null })
    setOrderList({
      id: order.id,
      sponsor_name: order.sponsor_name,
      sponsor_experience_years: order.sponsor_experience_years,
      sponsor_experience_volume: order.sponsor_experience_volume,
      sponsor_experience_ui: order.sponsor_experience_ui,
      sponsor_overview: order.sponsor_overview,
      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete ${order.sponsor_name}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `sponsor-master/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Deleted Successfully")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        id: orderList.id,
        sponsor_name: values.sponsor_name,
        sponsor_experience_years: values.sponsor_experience_years,
        sponsor_experience_volume: values.sponsor_experience_volume,
        sponsor_experience_ui: values.sponsor_experience_ui,
        sponsor_overview: state.value || orderList.sponsor_overview,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `sponsor-master/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setState({ value: null })
          masterData()
          toast.success("Edited Successfully")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        sponsor_name: values["sponsor_name"],
        sponsor_experience_years: values["sponsor_experience_years"],
        created_at: values["created_at"],
        sponsor_experience_volume: values["sponsor_experience_volume"],
        sponsor_experience_ui: values["sponsor_experience_ui"],
        sponsor_overview: state.value,
      }
      try {
        const response = await axiosInstance.post(`sponsor-master`, newOrder)
        if (response) {
          setState({ value: null })
          masterData()
          toast.success(" Sponsor Added Successfully")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setState({ value: null })
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const [editorState, setEditorState] = useState(orderList.sponsor_overview)
  const Overview = ReactHtmlParser(editorState)

  const handleChange = value => {
    setState({ value })
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Sponsor Master" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={SpnsorMasterColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={SpnsorMasterColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Sponsor" : "Add Sponsor"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="sponsor_name"
                                          label="Sponsor Name"
                                          type="text"
                                          errormessage="Invalid Sponsor Name"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.sponsor_name || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="sponsor_experience_years"
                                          label="Sponsor Experience Years"
                                          type="text"
                                          errormessage="Invalid Sponsor Experience Years "
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            orderList.sponsor_experience_years ||
                                            ""
                                          }
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="sponsor_experience_volume"
                                          label="Sponsor Experience Volume"
                                          type="text"
                                          errormessage="Invalid Sponsor Experience Volume "
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            orderList.sponsor_experience_volume ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="sponsor_experience_ui"
                                          label="Sponsor Experience Ui"
                                          type="text"
                                          errormessage="Invalid Sponsor Experience Ui"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            orderList.sponsor_experience_ui ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      {/*<div className="mb-3">
                                            <AvField
                                              name="sponsor_overview"
                                              label="Sponsor Overview "
                                              type="text"
                                              errorMessage="Invalid Badge Class"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                editorState
                                                  ? ReactHtmlParser(editorState)
                                                  : ReactHtmlParser(
                                                      orderList.sponsor_overview ||
                                                        ""
                                                    )
                                              }
                                            ></AvField>
                                            </div> */}
                                      <CardTitle
                                        style={{
                                          marginTop: "8px",
                                          fontWeight: "normal",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Sponsor Overview
                                      </CardTitle>

                                      {/* <CKEditor
                                            editor={ClassicEditor}
                                            onChange={handleEditorChange}
                                          />
                                          <div style={{ marginTop: "2px" }}>
                                            {editorState ? (
                                              ReactHtmlParser(editorState)
                                            ) : (
                                              <p>
                                                {" "}
                                                {ReactHtmlParser(
                                                  orderList.sponsor_overview
                                                )}
                                              </p>
                                            )}
                                                </div> */}
                                      <div
                                        className="text-editor"
                                        style={{
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <EditorToolbar />
                                        <ReactQuill
                                          theme="snow"
                                          value={
                                            state?.value ||
                                            orderList?.sponsor_overview ||
                                            " "
                                          }
                                          onChange={handleChange}
                                          placeholder={
                                            "Write something awesome..."
                                          }
                                          modules={modules}
                                          formats={formats}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SponserMaster.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(SponserMaster)
