import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import moment from "moment"
import CsvDownload from "react-json-to-csv"
import { JSONToCSVConvertor } from "common/jsontocsv"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import Loader from "common/Loader"
const headers_name = ["text", "type", "created_at", "user", "email"]

const GeoData = props => {
  // const { data } = props
  const history = useHistory()
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [exportData, setExportData] = useState([])
  const [loading, setLoading] = useState(false)

  function humanize(str) {
    var i,
      frags = str?.split("_")
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags?.join(" ")
  }

  const handleNext = prev => {
    setSkip(prev => prev + 50)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 50)
  }

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `geo-data?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }, [skip])

  let resObj = {}
  function flattenObj(obj) {
    for (let key in obj) {
      if (!(typeof obj[key] == "object")) {
        resObj[key] = obj[key]
      } else {
        flattenObj(obj[key])
      }
    }

    return resObj
  }

  const x = exportData.map(function (x) {
    return flattenObj(x)
  })
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={16}>
              <Card>
                <CardBody>
                  <CardTitle>Geo Data</CardTitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead></thead>
                      <thead>
                        <tr style={{ fontSize: "15px" }}>
                          <th>Post Office Name</th>
                          <th>District</th>
                          <th>Pincode</th>
                          <th>City</th>
                          <th>state</th>
                          <th>Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.length &&
                          data.map((item, index) => (
                            <tr key={index}>
                              <th scope="row" className="drag-pointer">
                                {item.post_office_name}
                              </th>
                              <th>{item.district}</th>
                              <th>{item.pincode}</th>
                              <th>{item.city}</th>
                              <th>{item.state}</th>
                              <th>
                                {moment(item.created_at).format(
                                  "MMM Do yy hh:mm a"
                                )}
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ float: "right", fontSize: "15px" }}>
                    {" "}
                    {limit?.total > 0
                      ? `Showing ${skip + 1} to ${
                          limit?.total >= 50
                            ? skip + limit?.limit
                            : limit?.total
                        } of
                    ${limit?.total}`
                      : `No Data Available`}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handlePrevious}
                      style={{ margin: "10px" }}
                      disabled={skip == 0 ? true : false}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleNext}
                      disabled={limit?.total > skip + 50 ? false : true}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

GeoData.propTypes = {
  data: PropTypes.array,
}

export default GeoData
