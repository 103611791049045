import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  CardText,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import Loader from "common/Loader"
import { Card, CardBody, Col, Row } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import ReactSelect from "constants/ReactSelect"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import moment from "moment"
import SimpleBar from "simplebar-react"
import { map } from "lodash"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import nodata from "../../assets/images/nodata/no-data.svg"
import {
  csvDownloadData,
  distributorUserInvData,
  usersData,
  enumSelector,
  getChartColorsArray,
  picUrl,
} from "constants/common"
import ReactApexChart from "react-apexcharts"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ReactEcharts from "echarts-for-react"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"

const InvestorDashboard = () => {
  const [loading, setLoading] = useState(false)

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [userDashboardData, setUserDashboardData] = useState()

  const [userSearch, setUserSearch] = useState("")

  const [investments, setInvestments] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState(null)
  const [investmentDashboardData, setInvestmentDashboardData] = useState()

  const [allTransactions, setAllTransactions] = useState([])
  const [summary, setSummary] = useState([])
  const [xirr, setXirr] = useState(null)
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [upcomingTransaction, setUpcomingTransaction] = useState([])
  const [document, setDocument] = useState([])
  const [investmentDetails, setInvestmentDetails] = useState()

  const [cashflowSummary, setCashflowSummary] = useState()
  const [transactionSummary, setTransactionSummary] = useState()
  const [projectionSummary, setProjectionSummary] = useState()

  /* Graph States Start */
  const [summaryTotalPieData, setSummaryTotalPieData] = useState([])

  const [transactionDisplay, setTransactionDisplay] = useState("Table")
  const [projectionDisplay, setProjectionDisplay] = useState("Graph")

  const [investmentData, setInvestmentData] = useState([])
  const [interestData, setInterestData] = useState([])
  const [repaymentData, setRepaymentData] = useState([])
  const [accessManagement, setAccessManagement] = useState("")

  const [selectedTransactionYear, setSelectedTransactionYear] = useState(
    parseInt(moment().format("YY"))
  )
  const [allTransactionYearOptions, setAllTransactionYearOptions] = useState([])

  const [investmentProjectionData, setInvestmentProjectionData] = useState([])
  const [interestProjectionData, setInterestProjectionData] = useState([])
  const [repaymentProjectionData, setRepaymentProjectionData] = useState([])

  const [selectedProjectionYear, setSelectedProjectionYear] = useState(
    parseInt(moment().format("YY"))
  )
  const [projectionYearOptions, setProjectionYearOptions] = useState([])
  /* Graph States End */

  const [projectData, setProjectData] = useState({})

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { SearchBar } = Search

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(() => {
    const AccessManagement = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
    setAccessManagement(AccessManagement)
  }, [])

  const userBoxData = [
    { title: "Purchase Value", key: "commitmentAmount" },
    { title: "Amount Invested", key: "investedAmount" },
    { title: "To be Invested", key: "toBeInvestedAmount" },
    { title: "Total Investments", key: "investmentCount" },
    { title: "Principal Invested", key: "faceValue" },
    { title: "Interest Paid", key: "interestPayment" },
    { title: "Principal Repaid", key: "principalRepayment" },
    { title: "Outstanding Principal", key: "continuingExposure" },
  ]

  const investmentBoxData = [
    {
      title: "Purchase Value",
      key: "purchaseValue",
    },
    {
      title: "Amount Invested",
      key: "amountInvested",
    },
    {
      title: "To be Invested",
      key: "toBeInvested",
    },
    {
      title: "No. of Bonds",
      key: "lotSize",
    },
    {
      title: "Principal Invested",
      key: "paidUpPrincipal",
    },
    {
      title: "Interest Paid",
      key: "interestPaid",
    },
    {
      title: "Principal Repaid",
      key: "principalRepaid",
    },
    {
      title: "Outstanding Principal",
      key: "outstandingPrincipal",
    },
  ]

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const TransactionColumns = () => [
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.project_name}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "transaction_amount",
      text: "Amount (In Rs)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleTransactionAmount(row.transaction_amount)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },

    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{enumSelector(row.payment_type)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "transaction_type",
      text: "Transaction Type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{enumSelector(row.transaction_type)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const SummaryColumns = () => [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        moment(row.Date).isValid() ? handleValidDate(row.Date) : row.Date,
    },
    {
      dataField: "Investment",
      text: "Investment",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleSummaryAmount(row, "Investment")}</div>
      ),
    },
    {
      dataField: "Interest",
      text: "Interest",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleSummaryAmount(row, "Interest")}</div>
      ),
    },
    {
      dataField: "TDS",
      text: "TDS",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleSummaryAmount(row, "TDS")}</div>
      ),
    },
    {
      dataField: "Repayment",
      text: "Repayment",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleSummaryAmount(row, "Repayment")}</div>
      ),
    },

    {
      dataField: "Total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleSummaryAmount(row, "Total")}</div>
      ),
    },
    {
      dataField: "Type",
      text: "Type",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.Type}</div>
      ),
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleTransactionAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount / 100))
    return `₹ ${
      amount > 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const handleProjectionAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const handleSummaryAmount = (data, key) => {
    let amount = data[key]
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(Math.abs(amount)) + ")"
        : dollarIndianLocale.format(amount)
    } `
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const userInvestmentIdFromUrl = queryParams.get("userInvestmentId")
  const userIdFromUrl = queryParams.get("userId")

  // Check whether Distributor or not
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department === "Distributor"
  const isRm = localUser?.role?.department == "Relationship_Manager"

  const masterData = async () => {
    setLoading(true)
    if (isDist) {
      // Fetch Users and find selected user data
      try {
        const usersRes = await distributorUserInvData()
        if (usersRes) {
          const users = usersRes?.data?.data || usersRes?.data
          if (userIdFromUrl) {
            setSelectedUser(users?.find(item => item.id === userIdFromUrl))
          }
          setUsers(users)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (userIdFromUrl) {
      const userRes = await axiosInstance.get(`/users/${userIdFromUrl}`)
      if (userRes) {
        setSelectedUser(userRes.data)
      }
    }
    setLoading(false)
  }

  const handleUserSearch = async e => {
    setUserSearch(e)
    if (e.length >= 3 && !isDist) {
      setLoading(true)
      // Fetch Users and find selected user data
      try {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER&name=${e}&$limit=50&$skip=0${
            isDist
              ? "&createdById=" + localUser.id
              : isRm
              ? "&relManagerId=" + localUser.id
              : ""
          }${isDist || isRm ? "&kycStatus=VERIFIED" : ""}`
        )
        if (usersRes) {
          const users = usersRes?.data?.data || usersRes?.data
          // if (userIdFromUrl) {
          //   setSelectedUser(users?.find(item => item.id === userIdFromUrl))
          // }
          setUsers(users)
        }
      } catch (error) {
        toast.error(error.message)
      }
      setLoading(false)
    }
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
      if (tab == 1 && selectedInvestment) {
        getAllTransaction()
      } else if (tab == 2 && selectedInvestment) {
        getSummary()
      }
    }
  }

  const handleDoc = async file => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  const getUserInvestments = async userId => {
    setLoading(true)

    // Fetch User Investments and find selected user investment data
    try {
      const getUrl = `admin-user-investment-listing?$sort[created_at]=-1&inv_stage=Completed${
        isDist
          ? `&distributorId=` + localUser.id
          : isRm
          ? `&relManagerId=` + localUser.id
          : ""
      }&userId=${userId}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        if (userInvestmentIdFromUrl) {
          setSelectedInvestment(
            response.data?.data?.find(
              item => item.id == userInvestmentIdFromUrl
            ) || null
          )
        }
        setInvestments(response.data?.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const getAllTransaction = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `admin-transaction-listing?$sort[updated_at]=-1&userInvestmentId=${selectedInvestment.id}&payment_status=success`
      )
      if (response) {
        setAllTransactions(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  const getUpcomingTransaction = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `cashflow-projections/${selectedInvestment.id}`
      )
      if (response) {
        const res = response?.data?.data || response?.data
        setUpcomingTransaction(res?.length > 0 ? res : [])
      }
      setLoading(false)
    } catch (error) {
      setUpcomingTransaction([])
      toast.error(error)
      setLoading(false)
    }
  }

  const getProjectLevelDocument = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `document?investmentId=${selectedInvestment.investmentId}&userId=${selectedInvestment.userId}`
      )
      if (response) {
        setDocument(
          response.data?.filter(
            item => item.document_type.classification === "Deal"
          )
        )
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error)
    }
  }

  const getInvestmentDetails = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment-asset-management?investmentId=${selectedInvestment.investmentId}`
      )
      if (response) {
        setInvestmentDetails(response.data?.data[0])
      }
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  const getSummary = async () => {
    setLoading(true)
    setSummary([])
    setXirr(null)
    try {
      const response = await axiosInstance.get(
        `transaction-summary?$sort[updated_at]=-1&userInvestmentId=${selectedInvestment.id}`
      )
      if (response) {
        setSummary(response?.data?.data.length > 0 ? response?.data.data : [])
        setXirr(response?.data.xirr ? response?.data.xirr : null)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  const calculateUserDashboardData = async () => {
    // if (investments.length) {
    if (investments) {
      const dashboardData = {
        investmentCount: 0,
        faceValue: 0,
        commitmentAmount: 0,
        investedAmount: 0,
        toBeInvestedAmount: 0,
        interestPayment: 0,
        principalRepayment: 0,
        continuingExposure: 0,
        releaseCommitment: 0,
      }
      investments.forEach((element, index) => {
        if (element.id && element.inv_stage == "Completed") {
          if (element.amount) {
            dashboardData.commitmentAmount =
              dashboardData.commitmentAmount + element.amount
          }
          if (element.pendings?.amountPaid) {
            dashboardData.investedAmount =
              dashboardData.investedAmount + element.pendings.amountPaid
          }
          if (element.pendings?.paid_up_capital) {
            dashboardData.faceValue =
              dashboardData.faceValue + element.pendings.paid_up_capital
          }
          if (element.lot_size) {
            dashboardData.investmentCount = dashboardData.investmentCount + 1
          }
          if (
            element.pendings &&
            element.pendings.transactionsDone &&
            element.pendings.transactionsDone.Interest_Payment
          ) {
            if (element.pendings.transactionsDone.Tds) {
              dashboardData.interestPayment =
                dashboardData.interestPayment +
                (element.pendings.transactionsDone.Interest_Payment +
                  element.pendings.transactionsDone.Tds)
            } else {
              dashboardData.interestPayment =
                dashboardData.interestPayment +
                element.pendings.transactionsDone.Interest_Payment
            }
          } else if (
            element.pendings &&
            element.pendings.transactionsDone &&
            element.pendings.transactionsDone.Tds
          ) {
            dashboardData.interestPayment =
              dashboardData.interestPayment +
              element.pendings.transactionsDone.Tds
          }
          if (element?.pendings?.transactionsDone) {
            if (
              element?.pendings.transactionsDone?.Principal_Repayment &&
              element?.pendings?.transactionsDone?.Principal_Prepayment
            ) {
              dashboardData.principalRepayment =
                dashboardData.principalRepayment +
                (element.pendings.transactionsDone.Principal_Repayment +
                  element.pendings.transactionsDone.Principal_Prepayment)
            } else if (
              element?.pendings?.transactionsDone?.Principal_Prepayment
            ) {
              dashboardData.principalRepayment =
                dashboardData.principalRepayment +
                element.pendings.transactionsDone.Principal_Prepayment
            } else if (
              element?.pendings?.transactionsDone?.Principal_Repayment
            ) {
              dashboardData.principalRepayment =
                dashboardData.principalRepayment +
                element.pendings.transactionsDone.Principal_Repayment
            }
          }
          if (element?.pendings?.investment?.release_commitment) {
            dashboardData.releaseCommitment =
              dashboardData.releaseCommitment +
              element?.pendings?.investment?.release_commitment *
                element.lot_size
          }
        }
      })
      dashboardData.toBeInvestedAmount =
        dashboardData.commitmentAmount -
        dashboardData.investedAmount -
        dashboardData.releaseCommitment
      dashboardData.continuingExposure =
        dashboardData.faceValue -
        Math.abs(dashboardData.principalRepayment / 100)
      setUserDashboardData(dashboardData)
    }
  }

  const calculateInvestmentDashboardData = () => {
    const data = {
      purchaseValue: selectedInvestment?.amount || 0,
      amountInvested: selectedInvestment?.pendings?.amountPaid || 0,
      toBeInvested: 0,
      lotSize: selectedInvestment?.lot_size || 0,
      paidUpPrincipal: selectedInvestment?.pendings?.paid_up_capital || 0,
      interestPaid: 0,
      principalRepaid: 0,
      outstandingPrincipal: 0,
      releaseCommitment:
        selectedInvestment?.pendings?.investment?.release_commitment || 0,
    }
    data.toBeInvested =
      data.purchaseValue -
      data.amountInvested -
      data.releaseCommitment * data.lotSize

    if (
      selectedInvestment?.pendings &&
      selectedInvestment?.pendings.transactionsDone &&
      selectedInvestment?.pendings.transactionsDone.Interest_Payment
    ) {
      if (selectedInvestment?.pendings.transactionsDone.Tds) {
        data.interestPaid =
          Math.abs(
            selectedInvestment.pendings.transactionsDone.Interest_Payment / 100
          ) + Math.abs(selectedInvestment.pendings.transactionsDone.Tds / 100)
      } else {
        data.interestPaid = Math.abs(
          selectedInvestment.pendings.transactionsDone.Interest_Payment / 100
        )
      }
    } else if (
      selectedInvestment?.pendings &&
      selectedInvestment?.pendings.transactionsDone &&
      selectedInvestment?.pendings.transactionsDone.Tds
    ) {
      data.interestPaid = Math.abs(
        selectedInvestment.pendings.transactionsDone.Tds / 100
      )
    }
    if (selectedInvestment?.pendings?.transactionsDone) {
      if (
        selectedInvestment?.pendings.transactionsDone?.Principal_Repayment &&
        selectedInvestment?.pendings?.transactionsDone?.Principal_Prepayment
      ) {
        data.principalRepaid =
          Math.abs(
            selectedInvestment?.pendings.transactionsDone.Principal_Repayment /
              100
          ) +
          Math.abs(
            selectedInvestment?.pendings.transactionsDone.Principal_Prepayment /
              100
          )
      } else if (
        selectedInvestment?.pendings?.transactionsDone?.Principal_Prepayment
      ) {
        data.principalRepaid = Math.abs(
          selectedInvestment.pendings.transactionsDone.Principal_Prepayment /
            100
        )
      } else if (
        selectedInvestment?.pendings?.transactionsDone?.Principal_Repayment
      ) {
        data.principalRepaid = Math.abs(
          selectedInvestment.pendings.transactionsDone.Principal_Repayment / 100
        )
      }
    }

    if (
      selectedInvestment?.amount &&
      selectedInvestment?.pendings.transactionsDone
    ) {
      if (
        selectedInvestment?.pendings.transactionsDone?.Principal_Repayment &&
        selectedInvestment?.pendings?.transactionsDone?.Principal_Prepayment
      ) {
        data.outstandingPrincipal =
          Math.abs(selectedInvestment.pendings.paid_up_capital) -
          (Math.abs(
            selectedInvestment.pendings.transactionsDone.Principal_Repayment /
              100
          ) +
            Math.abs(
              selectedInvestment.pendings.transactionsDone
                .Principal_Prepayment / 100
            ))
      } else if (
        selectedInvestment?.pendings.transactionsDone.Principal_Repayment
      ) {
        data.outstandingPrincipal =
          Math.abs(selectedInvestment?.pendings.paid_up_capital) -
          Math.abs(
            selectedInvestment?.pendings.transactionsDone.Principal_Repayment /
              100
          )
      } else if (
        selectedInvestment?.pendings.transactionsDone.Principal_Prepayment
      ) {
        data.outstandingPrincipal =
          Math.abs(selectedInvestment.pendings.paid_up_capital) -
          Math.abs(
            selectedInvestment.pendings.transactionsDone.Principal_Prepayment /
              100
          )
      } else {
        data.outstandingPrincipal = Math.abs(
          selectedInvestment?.pendings.paid_up_capital
        )
      }
    }

    setInvestmentDashboardData(data)
  }

  useEffect(async () => {
    if (selectedUser) {
      setSelectedInvestment(null)
      history.replace({
        search: "?userId=" + selectedUser.id,
      })
      getUserInvestments(selectedUser.id)
    }
  }, [selectedUser])

  useEffect(() => {
    calculateUserDashboardData()
  }, [investments])

  useEffect(() => {
    if (selectedInvestment) {
      calculateInvestmentDashboardData()
      history.replace({
        search: `?userId=${selectedUser?.id}&userInvestmentId=${selectedInvestment?.id}`,
      })
      if (selectedInvestment.pendings.investment.status != "Exited") {
        getUpcomingTransaction()
      }
      getProjectLevelDocument()
      getInvestmentDetails()
      getAllTransaction()
      getSummary()
    }
  }, [selectedInvestment])

  useEffect(async () => {
    masterData()
  }, [])

  let downloadableArr = []

  let arr
  if (customActiveTab == 1 && allTransactions) {
    let csvTableHeaders = TransactionColumns()
    const formatedData = allTransactions.map(obj => {
      return {
        ...obj,
        created_at: moment(obj.created_at).format("DD/MM/YYYY"),
        transaction_amount:
          obj.transaction_amount > 0
            ? -Math.round(obj.transaction_amount / 100)
            : Math.round(Math.abs(obj.transaction_amount) / 100),
        transaction_type: enumSelector(obj.transaction_type),
        payment_type: enumSelector(obj.payment_type),
      }
    })
    var str = JSON.stringify(formatedData)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  } else if (customActiveTab == 2 && summary) {
    let csvTableHeaders = SummaryColumns()
    const formattedData = [...summary]
    var str = JSON.stringify(formattedData)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  // Graph code start
  const chartColors = getChartColorsArray(
    '["--bs-primary", "--bs-success", "--bs-danger"]'
  )
  /* Total Summary Graph start */
  const pieChartSeries = summaryTotalPieData

  const pieChartOptions = {
    tooltip: {
      trigger: "item",
    },
    color: chartColors,
    legend: {
      orient: "horizontal",
      left: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: summaryTotalPieData,
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            // return param.name + " (" + param.percent + "%)"
            return param.percent + "%"
          },
        },

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  useEffect(() => {
    setSummaryTotalPieData([
      {
        value: Math.abs(summary.reduce((a, b) => a + b["Investment"], 0)),
        name: "Investment",
      },
      {
        value: Math.abs(summary.reduce((a, b) => a + b["Interest"], 0)),
        name: "Interest",
      },
      {
        value: Math.abs(summary.reduce((a, b) => a + b["Repayment"], 0)),
        name: "Repayment",
      },
    ])
  }, [summary])

  /* All Transactions Graph start */
  const allTransactionsGraphOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // type: "shadow",
      },
    },
    legend: { left: 0 },
    title: {
      subtext: "Click the metric to add/remove from graph",
      top: "5%",
      left: 0,
    },
    xAxis: [
      {
        type: "category",
        data: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    color: chartColors,
    series: [
      {
        name: "Investment",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: investmentData,
      },
      {
        name: "Interest",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: interestData,
      },
      {
        name: "Repayment",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: repaymentData,
      },
    ],
    grid: {
      left: "1%",
      right: "1%",
      bottom: "1%",
      containLabel: true,
    },
    textStyle: {
      color: ["#74788d"],
    },
    dataZoom: {
      show: true,
      start: 0,
      end: 100,
      type: "inside",
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        // dataView: { show: true, readOnly: false },
        restore: { show: true, title: "Restore" },
        saveAsImage: { show: true, title: "Save as Image" },
      },
      top: -3,
    },
  }

  const getMonthlyTransactionData = arr => {
    let newArr = new Array(12).fill(0)
    let monthArr = []
    arr.forEach(trn => {
      let monthIndex =
        parseInt(moment(trn.created_at).month()) >= 3
          ? parseInt(moment(trn.created_at).month()) - 3
          : parseInt(moment(trn.created_at).month()) + 9

      if (monthArr.includes(monthIndex)) {
        newArr[monthIndex] = Math.round(
          newArr[monthIndex] + Math.abs(trn.transaction_amount / 100)
        )
      } else {
        newArr[monthIndex] = Math.round(Math.abs(trn.transaction_amount / 100))
        monthArr.push(monthIndex)
      }
    })
    return newArr
  }

  const getFilteredTransactionDataByYear = (data, selectedYear, type) => {
    if (data) {
      return data?.filter(trn => {
        let transactionYear = parseInt(moment(trn.created_at).format("YYYY"))
        let transactionMonth = parseInt(moment(trn.created_at).format("MM"))
        if (transactionMonth > 3) {
          if (type?.length > 0) {
            return (
              type.includes(trn.transaction_type) &&
              transactionYear === selectedYear
            )
          } else {
            return transactionYear === selectedYear
          }
        } else {
          if (type?.length > 0) {
            return (
              type.includes(trn.transaction_type) &&
              transactionYear === selectedYear + 1
            )
          } else {
            return transactionYear === selectedYear + 1
          }
        }
      })
    }
  }

  const onChangeTransactionYear = year => {
    let selectedYear = parseInt(year)
    setSelectedTransactionYear(selectedYear)
    setInvestmentData(
      getMonthlyTransactionData(
        getFilteredTransactionDataByYear(
          // Returns month-wise Investment data for selected financial year
          allTransactions,
          selectedYear,
          ["Direct", "Balance_Investment", "Token", "Token_Refund"]
        )
      )
    )

    setInterestData(
      getMonthlyTransactionData(
        getFilteredTransactionDataByYear(
          // Returns month-wise Interest data for selected financial year
          allTransactions,
          selectedYear,
          ["Interest_Payment"]
        )
      )
    )

    setRepaymentData(
      getMonthlyTransactionData(
        getFilteredTransactionDataByYear(
          // Returns month-wise Repayment data for selected financial year
          allTransactions,
          selectedYear,
          ["Principal_Repayment", "Principal_Prepayment"]
        )
      )
    )
    const transactionSummary = getSummarizedTransactions(
      getFilteredTransactionDataByYear(allTransactions, selectedYear)
    )
    setTransactionSummary(transactionSummary)
  }

  const getSummarizedTransactions = data => {
    if (data) {
      let summary = {
        investment: 0,
        interest: 0,
        tds: 0,
        repayment: 0,
        netInvestment: 0,
      }
      data.forEach(transaction => {
        switch (transaction.transaction_type) {
          case "Direct":
          case "Balance_Investment":
          case "Token":
          case "Token_Refund":
            summary.investment += transaction.transaction_amount
            break
          case "Interest_Payment":
            summary.interest += transaction.transaction_amount
            break

          case "Tds":
            summary.tds += transaction.transaction_amount
            break

          case "Principal_Repayment":
          case "Principal_Prepayment":
            summary.repayment += transaction.transaction_amount
            break
        }
      })
      summary.netInvestment =
        summary.investment + summary.interest + summary.tds + summary.repayment
      return summary
    }
  }

  // Set All Transactions Chart Data on data fetch
  useEffect(() => {
    if (allTransactions) {
      let currentYear = parseInt(moment().format("YYYY"))
      let currentMonth = parseInt(moment().format("MM"))

      onChangeTransactionYear(currentMonth > 3 ? currentYear : currentYear - 1)

      const cashflowSummary = getSummarizedTransactions(allTransactions)
      setCashflowSummary(cashflowSummary)

      let years = []
      const len = allTransactions?.length
      const oldestTransaction = moment(allTransactions[len - 1]?.created_at)
      if (parseInt(oldestTransaction.format("MM")) > 3) {
        for (
          let i = currentMonth > 3 ? currentYear : currentYear - 1;
          i >= parseInt(oldestTransaction.format("YYYY"));
          i--
        ) {
          years.push(i)
        }
      } else {
        for (
          let i = currentMonth > 3 ? currentYear : currentYear - 1;
          i >= parseInt(oldestTransaction.format("YYYY")) - 1;
          i--
        ) {
          years.push(i)
        }
      }
      setAllTransactionYearOptions(years)
    }
  }, [allTransactions])

  /* Cashflow Projection Graph start */

  const projectionGraphOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // type: "shadow",
      },
    },
    legend: { left: 0 },
    title: {
      subtext: "Click the metric to add/remove from graph",
      top: "5%",
      left: 0,
    },
    xAxis: [
      {
        type: "category",
        data: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    color: chartColors,
    series: [
      {
        name: "Investment",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: investmentProjectionData,
      },
      {
        name: "Interest",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: interestProjectionData,
      },
      {
        name: "Repayment",
        type: "bar",
        barWidth: 10,
        stack: "Ad",
        selectedMode: "single",
        data: repaymentProjectionData,
      },
    ],
    grid: {
      left: "1%",
      right: "1%",
      bottom: "1%",
      containLabel: true,
    },
    dataZoom: {
      show: true,
      start: 0,
      end: 100,
      type: "inside",
    },
    textStyle: {
      color: ["#74788d"],
    },

    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        // dataView: { show: true, readOnly: false },
        restore: { show: true, title: "Restore" },
        saveAsImage: { show: true, title: "Save as Image" },
      },
      top: -3,
    },
  }

  const getSummarizedProjections = data => {
    if (data) {
      let summary = {
        investment: 0,
        interest: 0,
        repayment: 0,
      }
      data.forEach(transaction => {
        summary.investment += transaction.Investment
        summary.interest += transaction.Interest
        summary.repayment += transaction.Repayment
      })
      return summary
    }
  }

  const getMonthlyProjectionData = arr => {
    const investmentArr = new Array(12).fill(0)
    const interestArr = new Array(12).fill(0)
    const repaymentArr = new Array(12).fill(0)
    let monthArr = []
    arr.forEach(trn => {
      let monthIndex =
        parseInt(moment(trn.Date).month()) >= 3
          ? parseInt(moment(trn.Date).month()) - 3
          : parseInt(moment(trn.Date).month()) + 9

      if (monthArr.includes(monthIndex)) {
        investmentArr[monthIndex] = Math.round(
          investmentArr[monthIndex] + Math.abs(trn.Investment)
        )
        interestArr[monthIndex] = Math.round(
          interestArr[monthIndex] + Math.abs(trn.Interest)
        )
        repaymentArr[monthIndex] = Math.round(
          repaymentArr[monthIndex] + Math.abs(trn.Repayment)
        )
      } else {
        investmentArr[monthIndex] = Math.round(Math.abs(trn.Investment))
        interestArr[monthIndex] = Math.round(Math.abs(trn.Interest))
        repaymentArr[monthIndex] = Math.round(Math.abs(trn.Repayment))
        monthArr.push(monthIndex)
      }
    })
    setInvestmentProjectionData(investmentArr)
    setInterestProjectionData(interestArr)
    setRepaymentProjectionData(repaymentArr)
  }

  const getFilteredProjectionDataByYear = selectedYear => {
    if (upcomingTransaction) {
      return upcomingTransaction?.filter(trn => {
        let transactionYear = parseInt(moment(trn.Date).format("YYYY"))
        let transactionMonth = parseInt(moment(trn.Date).format("MM"))
        return transactionMonth > 3
          ? transactionYear === selectedYear
          : transactionYear === selectedYear + 1
      })
    }
  }

  const onChangeProjectionYear = year => {
    let selectedYear = parseInt(year)
    setSelectedProjectionYear(selectedYear)
    getMonthlyProjectionData(getFilteredProjectionDataByYear(selectedYear))
    const projectionSummary = getSummarizedProjections(
      getFilteredProjectionDataByYear(selectedYear)
    )
    setProjectionSummary(projectionSummary)
  }

  // Set Projections Chart Data on data fetch
  useEffect(() => {
    if (upcomingTransaction) {
      let currentYear = parseInt(moment().format("YYYY"))
      let currentMonth = parseInt(moment().format("MM"))
      setSelectedProjectionYear(currentYear)

      onChangeProjectionYear(currentMonth > 3 ? currentYear : currentYear - 1)

      let years = []
      const len = upcomingTransaction.length
      const oldestTransaction = moment(upcomingTransaction[0]?.Date)
      const latestTransaction = moment(upcomingTransaction[len - 2]?.Date)

      if (parseInt(oldestTransaction.format("MM")) > 3) {
        for (
          let i =
            parseInt(latestTransaction.format("MM")) > 3
              ? parseInt(latestTransaction.format("YYYY"))
              : parseInt(latestTransaction.format("YYYY")) - 1;
          i >= parseInt(oldestTransaction.format("YYYY"));
          i--
        ) {
          years.push(i)
        }
      } else {
        for (
          let i =
            parseInt(latestTransaction.format("MM")) > 3
              ? parseInt(latestTransaction.format("YYYY"))
              : parseInt(latestTransaction.format("YYYY")) - 1;
          i >= parseInt(oldestTransaction.format("YYYY")) - 1;
          i--
        ) {
          years.push(i)
        }
      }
      setProjectionYearOptions(years)
    }
  }, [upcomingTransaction])

  const getPercent = (data, key) => {
    if (data.investment) {
      return data[key] > 0
        ? "(" + Math.round(Math.abs(data[key] * 100) / data.investment) + "%)"
        : Math.round(Math.abs(data[key] * 100) / data.investment) + "%"
    } else {
      return 0
    }
  }

  useEffect(() => {
    const projectData = {
      imageUrl: investmentDetails?.investment?.display_pic_url,

      projectName: investmentDetails?.investment?.project_name,
      issuerName: investmentDetails?.investment?.registered_issuer_name,

      cityLabel: "City",
      city: investmentDetails?.investment?.location,

      isinLabel: "ISIN ",
      isinLinkText: "(NSDL Link)",
      isin: investmentDetails?.investment?.isin_number,

      faceValueLabel: "Face Value",
      faceValue: investmentDetails?.investment?.face_value,

      // bondsLabel: "Total Bonds",
      // bonds: investmentDetails?.investment?.total_bonds,

      dateLabel: "Expected Maturity",
      date: investmentDetails?.investment?.maturity_date,

      // issueSizeLabel: "Issue Size",
      // issueSize: investmentDetails?.investment?.issue_size,

      annualReturnLabel: "Annual Return",
      annualReturn: investmentDetails?.annual_return,
      distributorNameLabel: "Distributor Name",
      distributorName: selectedInvestment?.distributorName,
    }
    setProjectData(projectData)
  }, [investmentDetails])

  const downloadSummary = async type => {
    const isExcel = type == "excel"
    try {
      setLoading(true)
      const downloadUrl = `${
        isExcel ? "download-excel" : "download-pdf"
      }?type=transaction-summary&userInvestmentId=${selectedInvestment.id}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      const contentDispositionHeader = response.headers["content-disposition"]
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match
        ? match[1]
        : isExcel
        ? "download.xlsx"
        : "download.pdf"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })

      const downloadLink = window.document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      window.document.body.appendChild(downloadLink)
      downloadLink.click()
      window.document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <h4>Investor Dashboards</h4>
          <hr />
          <React.Fragment>
            <Col xl="12" className="p-2 h-100">
              {users?.length || !isDist ? (
                <Row className="mb-3">
                  <div className="col-md-4">
                    <label className="card-title">Select Investor</label>
                    <ReactSelect
                      users={users}
                      searchValue={userSearch}
                      setSearchValue={handleUserSearch}
                      selectedOption={selectedUser}
                      setSelectedOption={setSelectedUser}
                      multiOptionLabel={false}
                      optionLabelKeys={[
                        "user_pan?.name",
                        "email",
                        "phone",
                        "user_pan?.pan_number",
                      ]}
                    />
                  </div>
                </Row>
              ) : null}
              {selectedUser ? (
                <React.Fragment>
                  <Row>
                    <CardTitle>
                      Investment Summary across all Projects
                    </CardTitle>
                    {userBoxData.map((item, index) => (
                      <Col lg={3} key={index}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">{item.title}</p>
                                <h5 className="mb-0">
                                  {item.key !== "investmentCount" && "₹ "}
                                  {userDashboardData && item.key
                                    ? item.key === "interestPayment" ||
                                      item.key === "principalRepayment"
                                      ? Math.round(
                                          Math.abs(
                                            eval(
                                              "userDashboardData." + item.key
                                            )
                                          ) / 100
                                        ).toLocaleString("en-IN")
                                      : item.key === "continuingExposure"
                                      ? Math.round(
                                          Math.abs(
                                            eval(
                                              "userDashboardData." + item.key
                                            )
                                          )
                                        ).toLocaleString("en-IN")
                                      : Math.round(
                                          eval("userDashboardData." + item.key)
                                        ).toLocaleString("en-IN")
                                    : 0}
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              ) : null}
              {selectedUser && investments.length ? (
                <Row className="mb-3">
                  <div className="col-md-4">
                    <label className="card-title">Select Investment</label>
                    <ReactSelect
                      users={investments}
                      transaction={true}
                      selectedOption={selectedInvestment}
                      setSelectedOption={setSelectedInvestment}
                    />
                  </div>
                </Row>
              ) : null}

              {!selectedUser && !loading ? (
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                      <Row className="justify-content-center mt-1">
                        <Col sm="4">
                          <div className="maintenance-img">
                            <img
                              src={nodata}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                      <h4 className="mt-5">
                        {users?.length || !isDist
                          ? "Please select an Investor"
                          : "No Investors Found"}
                      </h4>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {selectedUser && selectedInvestment ? (
                <React.Fragment>
                  <Row>
                    <Col lg="12">
                      <ProjectDetailCard
                        imageUrl={projectData?.imageUrl}
                        projectName={projectData?.projectName}
                        issuerName={projectData?.issuerName}
                        cityLabel={projectData?.cityLabel}
                        city={projectData?.city}
                        isinLabel={projectData?.isinLabel}
                        isinLinkText={projectData?.isinLinkText}
                        isin={projectData?.isin}
                        faceValueLabel={projectData?.faceValueLabel}
                        faceValue={projectData?.faceValue}
                        bondsLabel={projectData?.bondsLabel}
                        bonds={projectData?.bonds}
                        dateLabel={projectData?.dateLabel}
                        date={projectData?.date}
                        issueSizeLabel={projectData?.issueSizeLabel}
                        issueSize={projectData?.issueSize}
                        annualReturnLabel={projectData?.annualReturnLabel}
                        annualReturn={projectData?.annualReturn}
                        distributorNameLabel={projectData?.distributorNameLabel}
                        distributorName={projectData?.distributorName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <CardTitle>Investments</CardTitle>
                    {investmentBoxData.map((item, index) => (
                      <Col lg={3} key={index}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">{item.title}</p>
                                <h5 className="mb-0">
                                  {item.key !== "lotSize" && "₹ "}
                                  {investmentDashboardData && item.key
                                    ? item.key === "interestPaid" ||
                                      item.key === "principalRepaid" ||
                                      item.key === "outstandingPrincipal"
                                      ? Math.round(
                                          Math.abs(
                                            eval(
                                              "investmentDashboardData." +
                                                item.key
                                            )
                                          )
                                        ).toLocaleString("en-IN")
                                      : Math.round(
                                          eval(
                                            "investmentDashboardData." +
                                              item.key
                                          )
                                        ).toLocaleString("en-IN")
                                    : 0}
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    {investmentDetails?.investment?.status !== "Exited" ? (
                      <Col xl="4">
                        <Card>
                          <CardBody style={{ height: "350px" }}>
                            <CardTitle className="mb-4">
                              Upcoming Transactions
                            </CardTitle>

                            <SimpleBar style={{ maxHeight: "275px" }}>
                              <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                  <tbody>
                                    {upcomingTransaction.map(
                                      (_transaction, index) => {
                                        if (index < 2)
                                          return handleValidDate(
                                            _transaction.Date
                                          ) === "Invalid date" ? null : (
                                            <React.Fragment key={index}>
                                              {_transaction.Investment ? (
                                                <tr>
                                                  <td width="50px">
                                                    <div className="font-size-22 text-danger">
                                                      <i className="bx bx-minus-circle" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <h5 className="font-size-14 mb-1">
                                                        Investment
                                                      </h5>
                                                      <p className="text-muted mb-0">
                                                        {handleValidDate(
                                                          _transaction.Date
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <p className="text-muted mb-0">
                                                        ₹ (
                                                        {Math.abs(
                                                          Math.round(
                                                            _transaction.Investment
                                                          )
                                                        ).toLocaleString(
                                                          "en-IN"
                                                        )}
                                                        )
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {_transaction.Interest ? (
                                                <tr>
                                                  <td style={{ width: "50px" }}>
                                                    <div className="font-size-22 text-success">
                                                      <i className="bx bx-plus-circle" />
                                                    </div>
                                                  </td>

                                                  <td>
                                                    <div>
                                                      <h5 className="font-size-14 mb-1">
                                                        Interest
                                                      </h5>
                                                      <p className="text-muted mb-0">
                                                        {handleValidDate(
                                                          _transaction.Date
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <p className="text-muted mb-0">
                                                        ₹{" "}
                                                        {Math.round(
                                                          _transaction.Interest
                                                        ).toLocaleString(
                                                          "en-IN"
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {_transaction.Repayment ? (
                                                <tr>
                                                  <td style={{ width: "50px" }}>
                                                    <div className="font-size-22 text-success">
                                                      <i className="bx bx-plus-circle" />
                                                    </div>
                                                  </td>

                                                  <td>
                                                    <div>
                                                      <h5 className="font-size-14 mb-1">
                                                        Repayment
                                                      </h5>
                                                      <p className="text-muted mb-0">
                                                        {handleValidDate(
                                                          _transaction.Date
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <p className="text-muted mb-0">
                                                        ₹{" "}
                                                        {Math.round(
                                                          _transaction.Repayment
                                                        ).toLocaleString(
                                                          "en-IN"
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </React.Fragment>
                                          )
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </SimpleBar>
                          </CardBody>
                        </Card>
                      </Col>
                    ) : null}
                    <Col
                      xl={
                        investmentDetails?.investment?.status !== "Exited"
                          ? 4
                          : 3
                      }
                    >
                      <Card>
                        <CardBody
                          style={
                            investmentDetails?.investment?.status !== "Exited"
                              ? { height: "350px" }
                              : { height: "450px" }
                          }
                        >
                          <CardTitle className="mb-4">
                            Project Level Documents
                          </CardTitle>
                          <SimpleBar style={{ maxHeight: "260px" }}>
                            <div className="table-responsive">
                              <Table className="table-nowrap align-middle table-hover mb-0">
                                <tbody>
                                  {map(document, (file, i) => (
                                    <tr key={"_file_" + i}>
                                      <td width="10%">
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            handleDoc(file.url)
                                          }}
                                          className="text-dark"
                                        >
                                          <i className="bx bx-download h3 m-0" />
                                        </Link>
                                      </td>
                                      <td>
                                        <h5 className="font-size-14 mb-1">
                                          <Link to="#" className="text-dark">
                                            {file.document_name}
                                          </Link>
                                        </h5>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </SimpleBar>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="4">
                      <Card>
                        <CardBody
                          style={
                            investmentDetails?.investment?.status !== "Exited"
                              ? { height: "350px" }
                              : { height: "450px" }
                          }
                        >
                          <CardTitle className="mb-4">
                            Cashflow Summary
                          </CardTitle>
                          {/* <div>
                            <div id="donut-chart">
                              <ReactEcharts
                                option={pieChartOptions}
                                style={{ height: "320px" }}
                              />
                            </div>
                          </div> */}
                          <Col>
                            <div className="table-responsive mt-3">
                              <table className="table align-middle table-nowrap">
                                <thead>
                                  <tr>
                                    <th width="50%">Particulars</th>
                                    <th width="30%" className="text-end">
                                      INR
                                    </th>
                                    <th width="20%" className="text-end">
                                      %
                                    </th>
                                  </tr>
                                </thead>
                                {cashflowSummary && (
                                  <tbody>
                                    <tr>
                                      <td width="50">
                                        <p className="mb-0">Amount Invested</p>
                                      </td>
                                      <td width="30%">
                                        <p className="mb-0 text-end">
                                          {handleTransactionAmount(
                                            cashflowSummary.investment
                                          )}
                                        </p>
                                      </td>
                                      <td width="20%">
                                        <p className="mb-0 text-end">
                                          {getPercent(
                                            cashflowSummary,
                                            "investment"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%">
                                        <p className="mb-0">Interest Paid</p>
                                      </td>
                                      <td width="30%">
                                        <p className="mb-0 text-end">
                                          {handleTransactionAmount(
                                            cashflowSummary.interest
                                          )}
                                        </p>
                                      </td>
                                      <td width="20%">
                                        <p className="mb-0 text-end">
                                          {getPercent(
                                            cashflowSummary,
                                            "interest"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%">
                                        <p className="mb-0">TDS on Interest</p>
                                      </td>
                                      <td width="30%">
                                        <p className="mb-0 text-end">
                                          {handleTransactionAmount(
                                            cashflowSummary.tds
                                          )}
                                        </p>
                                      </td>
                                      <td width="20%">
                                        <p className="mb-0 text-end">
                                          {getPercent(cashflowSummary, "tds")}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%">
                                        <p className="mb-0">Principal Repaid</p>
                                      </td>
                                      <td width="30%">
                                        <p className="mb-0 text-end">
                                          {handleTransactionAmount(
                                            cashflowSummary.repayment
                                          )}
                                        </p>
                                      </td>
                                      <td width="20%">
                                        <p className="mb-0 text-end">
                                          {getPercent(
                                            cashflowSummary,
                                            "repayment"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%">
                                        <p className="mb-0">Net Investment</p>
                                      </td>
                                      <td width="30%">
                                        <p className="mb-0 text-end">
                                          {handleTransactionAmount(
                                            cashflowSummary.netInvestment
                                          )}
                                        </p>
                                      </td>
                                      <td width="20%">
                                        <p className="mb-0 text-end">
                                          {getPercent(
                                            cashflowSummary,
                                            "netInvestment"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </div>
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* </Row>
                  <Row> */}
                    <Col
                      xl={
                        investmentDetails?.investment?.status !== "Exited"
                          ? 6
                          : 5
                      }
                    >
                      <Col>
                        <Card>
                          <CardBody
                            style={
                              investmentDetails?.investment?.status !== "Exited"
                                ? { height: "420px" }
                                : { height: "450px" }
                            }
                          >
                            <div className="clearfix mb-3">
                              <div className="float-end">
                                <div className="input-group input-group-sm">
                                  <select
                                    className="form-select form-select-sm"
                                    value={selectedTransactionYear}
                                    onChange={e => {
                                      onChangeTransactionYear(e.target.value)
                                    }}
                                  >
                                    {allTransactionYearOptions.map(i => {
                                      return (
                                        <option value={i} key={i}>
                                          {i} - {i + 1}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  <div className="input-group-append">
                                    <label className="input-group-text">
                                      FY
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="float-end me-2">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-append">
                                    <label className="input-group-text">
                                      Select View
                                    </label>
                                  </div>
                                  <select
                                    className="form-select form-select-sm"
                                    value={transactionDisplay}
                                    onChange={e => {
                                      setTransactionDisplay(e.target.value)
                                    }}
                                  >
                                    <option value={"Table"}>Table</option>
                                    <option value={"Graph"}>Graph</option>
                                  </select>
                                </div>
                              </div>
                              <CardTitle className="mb-4">
                                Transaction Summary
                              </CardTitle>
                            </div>

                            {transactionDisplay === "Graph" ? (
                              <Row>
                                <Col lg="12">
                                  <ReactEcharts
                                    option={allTransactionsGraphOptions}
                                    style={{ height: "320px" }}
                                  />
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col lg={12}>
                                  <div className="table-responsive mt-3">
                                    <table className="table align-middle table-nowrap">
                                      <thead>
                                        <tr>
                                          <th width="60%">Particulars</th>
                                          <th width="30%">
                                            <p className="mb-0 text-end">INR</p>
                                          </th>
                                          <th width="10%"></th>
                                        </tr>
                                      </thead>
                                      {transactionSummary && (
                                        <tbody>
                                          <tr>
                                            <td width="50">
                                              <p className="mb-0">
                                                Amount Invested
                                              </p>
                                            </td>
                                            <td width="30%">
                                              <p className="mb-0 text-end">
                                                {handleTransactionAmount(
                                                  transactionSummary.investment
                                                )}
                                              </p>
                                            </td>
                                            <td width="10%"></td>
                                          </tr>
                                          <tr>
                                            <td width="60%">
                                              <p className="mb-0">
                                                Interest Paid
                                              </p>
                                            </td>
                                            <td width="30%">
                                              <p className="mb-0 text-end">
                                                {handleTransactionAmount(
                                                  transactionSummary.interest
                                                )}
                                              </p>
                                            </td>
                                            <td width="10%"></td>
                                          </tr>
                                          <tr>
                                            <td width="60%">
                                              <p className="mb-0">
                                                TDS on Interest
                                              </p>
                                            </td>
                                            <td width="30%">
                                              <p className="mb-0 text-end">
                                                {handleTransactionAmount(
                                                  transactionSummary.tds
                                                )}
                                              </p>
                                            </td>
                                            <td width="10%"></td>
                                          </tr>
                                          <tr>
                                            <td width="60%">
                                              <p className="mb-0">
                                                Principal Repaid
                                              </p>
                                            </td>
                                            <td width="30%">
                                              <p className="mb-0 text-end">
                                                {handleTransactionAmount(
                                                  transactionSummary.repayment
                                                )}
                                              </p>
                                            </td>
                                            <td width="10%"></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                    {investmentDetails?.investment?.status !== "Exited" ? (
                      <Col xl="6">
                        <Col>
                          <Card>
                            <CardBody style={{ height: "420px" }}>
                              <Row>
                                <Col className="mb-3">
                                  <div className="clearfix">
                                    <div className="float-end">
                                      <div className="input-group input-group-sm">
                                        <select
                                          className="form-select form-select-sm"
                                          value={selectedProjectionYear}
                                          onChange={e => {
                                            onChangeProjectionYear(
                                              e.target.value
                                            )
                                          }}
                                        >
                                          {projectionYearOptions.map(i => {
                                            return (
                                              <option value={i} key={i}>
                                                {i} - {i + 1}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        <div className="input-group-append">
                                          <label className="input-group-text">
                                            FY
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="float-end me-2">
                                      <div className="input-group input-group-sm">
                                        <div className="input-group-append">
                                          <label className="input-group-text">
                                            Select View
                                          </label>
                                        </div>
                                        <select
                                          className="form-select form-select-sm"
                                          value={projectionDisplay}
                                          onChange={e => {
                                            setProjectionDisplay(e.target.value)
                                          }}
                                        >
                                          <option value={"Table"}>Table</option>
                                          <option value={"Graph"}>Graph</option>
                                        </select>
                                      </div>
                                    </div>
                                    <CardTitle className="mb-4">
                                      Cashflow Projections
                                    </CardTitle>
                                  </div>
                                </Col>
                                {projectionDisplay === "Graph" ? (
                                  <Col lg="12">
                                    <ReactEcharts
                                      option={projectionGraphOptions}
                                      style={{ height: "320px" }}
                                    />
                                  </Col>
                                ) : (
                                  <Col lg={12}>
                                    <div className="table-responsive mt-3">
                                      <table className="table align-middle table-nowrap">
                                        <thead>
                                          <tr>
                                            <th width="60%">Projections</th>
                                            <th width="30%">
                                              <p className="mb-0 text-end">
                                                INR
                                              </p>
                                            </th>
                                            <th width="10%"></th>
                                          </tr>
                                        </thead>
                                        {projectionSummary && (
                                          <tbody>
                                            <tr>
                                              <td width="60%">
                                                <p className="mb-0">
                                                  Investment
                                                </p>
                                              </td>
                                              <td width="30%">
                                                <p className="mb-0 text-end">
                                                  {handleProjectionAmount(
                                                    projectionSummary.investment
                                                  )}
                                                </p>
                                              </td>
                                              <td width="10%"></td>
                                            </tr>
                                            <tr>
                                              <td width="60%">
                                                <p className="mb-0">Interest</p>
                                              </td>
                                              <td width="30%">
                                                <p className="mb-0 text-end">
                                                  {handleProjectionAmount(
                                                    projectionSummary.interest
                                                  )}
                                                </p>
                                              </td>
                                              <td width="10%"></td>
                                            </tr>
                                            <tr>
                                              <td width="60%">
                                                <p className="mb-0">
                                                  Repayment
                                                </p>
                                              </td>
                                              <td width="30%">
                                                <p className="mb-0 text-end">
                                                  {handleProjectionAmount(
                                                    projectionSummary.repayment
                                                  )}
                                                </p>
                                              </td>
                                              <td width="10%"></td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1")
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  All Transactions
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2")
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Summary
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3"
                          >
                            <TabPane tabId="1">
                              <CardTitle>All Transactions</CardTitle>
                              <Row>
                                <Col xs="12">
                                  <CardText className="mb-0">
                                    <ToolkitProvider
                                      keyField="id"
                                      data={allTransactions}
                                      columns={TransactionColumns()}
                                      bootstrap4
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row className="mb-2 mt-2">
                                            <Col sm="4">
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="bx bx-search-alt search-icon" />
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm="8">
                                              <div className="text-sm-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-rounded"
                                                  onClick={() =>
                                                    JSONToCSVConvertor(
                                                      downloadableArr,
                                                      "All-Transactions",
                                                      true
                                                    )
                                                  }
                                                >
                                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                                  Download
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xl="12">
                                              <BootstrapTable
                                                keyField="id"
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                columns={TransactionColumns()}
                                                data={allTransactions}
                                                pagination={paginationFactory(
                                                  pageOptions
                                                )}
                                                wrapperClasses={
                                                  "table-responsive mb-4"
                                                }
                                                classes={
                                                  "table align-middle table-nowrap table-check"
                                                }
                                                headerWrapperClasses={
                                                  "table-light"
                                                }
                                                {...toolkitProps.baseProps}
                                              />
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  </CardText>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Row>
                                <CardTitle>
                                  Summary{" "}
                                  <span className="text-success">
                                    {selectedInvestment.pendings.investment
                                      .status == "Exited" && xirr
                                      ? `IRR: ${xirr.rate}%`
                                      : ""}
                                  </span>
                                </CardTitle>
                                <Col sm="12">
                                  <CardText className="mb-0">
                                    <ToolkitProvider
                                      keyField="id"
                                      data={summary}
                                      columns={SummaryColumns()}
                                      bootstrap4
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row className="mb-2 mt-2">
                                            <Col sm="4">
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="bx bx-search-alt search-icon" />
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm="8">
                                              <div className="text-sm-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-rounded mx-2"
                                                  onClick={() =>
                                                    downloadSummary("excel")
                                                  }
                                                >
                                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                                  Excel
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-rounded"
                                                  onClick={() =>
                                                    downloadSummary("pdf")
                                                  }
                                                >
                                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                                  Pdf
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xl="12">
                                              <BootstrapTable
                                                keyField="id"
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                columns={SummaryColumns()}
                                                data={summary}
                                                pagination={paginationFactory(
                                                  pageOptions
                                                )}
                                                wrapperClasses={
                                                  "table-responsive mb-4"
                                                }
                                                classes={
                                                  "table align-middle table-nowrap table-check"
                                                }
                                                headerWrapperClasses={
                                                  "table-light"
                                                }
                                                {...toolkitProps.baseProps}
                                              />
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  </CardText>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
              {selectedUser && !selectedInvestment && !loading ? (
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                      <Row className="justify-content-center mt-1">
                        <Col sm="4">
                          <div className="maintenance-img">
                            <img
                              src={nodata}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                      <h4 className="mt-5">
                        {investments.length == 0
                          ? "No Completed Investment Found"
                          : "Please select a user investment"}
                      </h4>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(InvestorDashboard)
