import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import { JSONToCSVConvertor } from "common/jsontocsv"
import BootstrapTable from "react-bootstrap-table-next"
import { csvDownloadData } from "constants/common"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import moment from "moment"
const DistributorLeads = () => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `distributor-lead?$sort[updated_at]=-1`
      )
      if (response) {
        setOrders(response.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])
  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)
  const LeadsColumns = () => [
    { dataField: "email", text: "Email", sort: true },
    { dataField: "name", text: "Name", sort: true },
    { dataField: "phone", text: "Phone", sort: true },
    { dataField: "category", text: "Category", sort: true },
    { dataField: "company_name", text: "Company", sort: true },
    { dataField: "message", text: "Message", sort: true },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
    { dataField: "whtsup_tnc", text: "WhatsApp Notification", sort: true },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = LeadsColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Leads" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={LeadsColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <Col className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <div className="col-auto">
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Leads Data",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={LeadsColumns()}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorLeads
