import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { investmentData, Pagination } from "constants/common"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import Loader from "common/Loader"

const SectionHeader = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [role, setRole] = useState([])
  const [loading, setLoading] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `deal-section-headers?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [roleId, setRoleId] = useState("")
  const [data, setDatax] = useState([])

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)

  const toggleViewModal = () => setModal1(!modal1)

  const SectionHeaderColumns = toggleModal => [
    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
      // formatter: (cellContent, row) => handleSponsorName(row.role),
    },
    {
      dataField: "default_name",
      text: "Default Name",
      sort: true,
      // formatter: (cellContent, row) => handleDesignation(row.role),
    },
    {
      dataField: "nav_header_name",
      text: "Nav Header",
      sort: true,
      // formatter: (cellContent, row) => handlePermision(row.permision),
    },

    {
      dataField: "sec_header_name",
      text: "Section Header",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = []

      // update order
    } else {
      const newOrder = [
        {
          investmentId: values.project_name,
          default_name: "Overview",
          nav_header_name: values.overview_nav,
          sec_header_name: values.overview_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Location",
          nav_header_name: values.location_nav,
          sec_header_name: values.location_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Cashflows",
          nav_header_name: values.cashflows_nav,
          sec_header_name: values.cashflows_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Photos",
          nav_header_name: values.photos_nav,
          sec_header_name: values.photos_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Sponsor",
          nav_header_name: values.sponsor_nav,
          sec_header_name: values.sponsor_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Performance",
          nav_header_name: values.performance_nav,
          sec_header_name: values.performance_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "Risks",
          nav_header_name: values.risks_nav,
          sec_header_name: values.risks_sec,
        },
        {
          investmentId: values.project_name,
          default_name: "FAQs_and_Resources",
          nav_header_name: values.FAQs_and_Resources_nav,
          sec_header_name: values.FAQs_and_Resources_sec,
        },
      ]
      // save new order

      try {
        const response = await axiosInstance.post(
          `deal-section-headers`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success(" Section Headers Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setRoleId("")
    setDatax([])
    setOrderList("")

    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await investmentData()
      if (response) {
        setRole(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  const handleRoleSelection = e => {
    setRoleId(e.target.value)
  }
  useEffect(() => {
    if (roleId) {
      let newArray = orders.filter(item => item.investmentId == roleId)
      let overview = newArray.find(item => item.default_name == "Overview")

      let Cashflows = newArray.find(item => item.default_name == "Cashflows")

      let Location = newArray.find(item => item.default_name == "Location")
      let Photos = newArray.find(item => item.default_name == "Photos")
      let Sponsor = newArray.find(item => item.default_name == "Sponsor")
      let Performance = newArray.find(
        item => item.default_name == "Performance"
      )
      let Risks = newArray.find(item => item.default_name == "Risks")
      let FAQs_and_Resources = newArray.find(
        item => item.default_name == "FAQs_and_Resources"
      )
      setDatax({
        overview_nav: overview?.nav_header_name,
        overview_sec: overview?.sec_header_name,
        cashflows_nav: Cashflows?.nav_header_name,
        cashflows_sec: Cashflows?.sec_header_name,
        location_nav: Location?.nav_header_name,
        location_sec: Location?.sec_header_name,
        photos_nav: Photos?.nav_header_name,
        photos_sec: Photos?.sec_header_name,
        sponsor_nav: Sponsor?.nav_header_name,
        sponsor_sec: Sponsor?.sec_header_name,
        performance_nav: Performance?.nav_header_name,
        performance_sec: Performance?.sec_header_name,
        risks_nav: Risks?.nav_header_name,
        risks_sec: Risks?.sec_header_name,
        FAQs_and_Resources_nav: FAQs_and_Resources?.nav_header_name,
        FAQs_and_Resources_sec: FAQs_and_Resources?.sec_header_name,
      })
    }
  }, [roleId])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Deal Section Headers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={SectionHeaderColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            {(accessRestriction >= 3 ||
                              accessRestriction === "SuperAdmin") && (
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-pencil me-1" />
                                  Manage Section Headers
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={SectionHeaderColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              className="modal-lg"
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit
                                  ? "Edit Section Headers"
                                  : "Manage Section Headers"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <div className="row">
                                      <div
                                        className="col-md-12"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Selected Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.department || null}
                                            onChange={handleRoleSelection}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {role.map((item, index) => (
                                              <option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.project_name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="overview"
                                            type="text"
                                            disabled
                                            value="Overview"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="overview_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.overview_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="overview_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.overview_sec || ""}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="cashflows"
                                            type="text"
                                            disabled
                                            value="Cashflows"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="cashflows_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.cashflows_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="cashflows_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.cashflows_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="location"
                                            type="text"
                                            disabled
                                            value="Location"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="location_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.location_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="location_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.location_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="photos"
                                            type="text"
                                            disabled
                                            value="Photos"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="photos_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.photos_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="photos_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.photos_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="sponsor"
                                            type="text"
                                            disabled
                                            value="Sponsor"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="sponsor_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.sponsor_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="sponsor_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.sponsor_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="performance"
                                            type="text"
                                            disabled
                                            value="Performance"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="performance_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.performance_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="performance_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.performance_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="risks"
                                            type="text"
                                            disabled
                                            value="Risks"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="risks_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.risks_nav || ""}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="risks_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={data?.risks_sec || ""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="FAQs_and_Resources"
                                            type="text"
                                            disabled
                                            value="FAQs And Resources"
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="FAQs_and_Resources_nav"
                                            placeholder="Nav Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              data?.FAQs_and_Resources_nav || ""
                                            }
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <AvField
                                            name="FAQs_and_Resources_sec"
                                            placeholder="Section Header"
                                            type="text"
                                            errormessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              data?.FAQs_and_Resources_sec || ""
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SectionHeader.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(SectionHeader)
