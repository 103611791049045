import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import {
  countryList,
  createFullAdress,
  csvDownloadData,
  indianStates,
  kycEmailTemplate,
} from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { usersData } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId, kycStatusType } from "constants/ConstantFields"

const KycList = () => {
  const [orders, setData] = useState([])
  const [kycStatus, setKycStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [startKyc, setStartKyc] = useState(false)
  const [file, setFile] = useState(null)
  const [addressDoc, setAddressDoc] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [panName, setPanName] = useState("")
  const [errorDetail, setErrorDeatail] = useState(false)
  const [personlInfo, setPersonalInfo] = useState(false)
  const [corporateInfo, setCorporateInfo] = useState(false)
  const [hufInfo, setHufInfo] = useState(false)
  const [trustInfo, setTrustInfo] = useState(false)
  const [panValue, setPanValue] = useState("")
  const [docfile, setDocFile] = useState()
  const [addressFile, setaddressFile] = useState()
  const [dematDetails, setDematDetails] = useState(false)
  const [dematFile, setDematFile] = useState()
  const [dematDoc, setDematDoc] = useState(null)
  const [bankDetails, setBankDetails] = useState(false)
  const [accountName, setAccountName] = useState("")
  const [disableKyc, setDisableKyc] = useState(true)
  const [panType, setPanType] = useState("")
  const [dob, setDob] = useState("")
  const [address, setAddress] = useState("")
  const [country, setCountry] = useState("")
  const [panRes, setPanRes] = useState(null)
  const [zipCode, setZipCode] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")

  const [kartaPanValue, setKartaPanValue] = useState()
  const [kartaPanFile, setKartaPanFile] = useState("")
  const [kartaPanDoc, setKartaPanDoc] = useState(null)
  const [isKartaNameSame, setKartaNameSame] = useState(true)
  const [kartaVerfiDoc, setKartaVerfiDoc] = useState(null)
  const [kartaVerfiFile, setKartaVerfiFile] = useState("")

  const [trusteePanNumber, setTrusteePanNumber] = useState()
  const [trusteePanFile, setTrusteePanFile] = useState("")
  const [trusteePanDoc, setTrusteePanDoc] = useState(null)
  const [authorityResolutionFile, setAuthorityResolutionFile] = useState("")
  const [authorityResolutionDoc, setAuthorityResolutionDoc] = useState(null)

  const [mcrDoc, setMcrDoc] = useState(null)
  const [mcrFile, setMcrFile] = useState(null)
  const [brDoc, setBrDoc] = useState(null)
  const [brFile, setBrFile] = useState(null)
  const [authPanDoc, setAuthPanDoc] = useState(null)
  const [authPanFile, setAuthPanFile] = useState(null)
  const [authAadharDoc, setAuthAadharDoc] = useState(null)
  const [authAadharFile, setAuthAadharFile] = useState(null)
  const [isAuthorisedName, setIsAuthorisedName] = useState(true)
  const [authorisedName, setAuthorisedName] = useState("")
  const [editAddress, setEditAddress] = useState(false)
  const [isAddressUploadReq, setIsAddressUploadReq] = useState(false)
  const [addressConsent, setAddressConsent] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [dematId, setDematId] = useState("")
  const [ifscCode, setIfscCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [resStatus, setResStatus] = useState("")

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [kycStatusFilter, setKycStatusFilter] = useState("")
  const [total, setTotal] = useState(0)

  const [isSendEmail, setIsSendEmail] = useState(false)
  const [finalSelectedRows, setFinalSelectedRows] = useState([])

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    kycStatusFilter?.id ? `&kycStatus=${kycStatusFilter.id}` : ``
  }`

  const getUrl = `/users?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        setData(response?.data?.data || response?.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }

    setLoading(false)
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("", e)
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "kycStatus":
        setKycStatusFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, kycStatusFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.KYC)
  const KYCColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <Button
                style={{
                  backgroundColor:
                    order.kyc_status == "VERIFIED" ? "green" : "grey",
                }}
              >
                Update
              </Button>
            </Link>
          ) : (
            "NA"
          )}
        </div>
      ),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "kyc_status",
      text: "Kyc Status",
      sort: true,
    },

    {
      dataField: "res_status",
      text: "Res Status",
      sort: true,
    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
    },
    {
      dataField: "user_demat.demat_id",
      text: "Demat Id",
      sort: true,
    },
    {
      dataField: "depository.dp_name",
      text: "Dp Name",
      sort: true,
    },
    {
      dataField: "user_demat.document_file",
      text: "Demat Document",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.user_demat?.document_file)}
            >
              {order?.user_demat?.document_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_pan.name",
      text: "Name on PAN",
      sort: true,
    },
    {
      dataField: "user_pan.pan_number",
      text: "PAN Number",
      sort: true,
    },
    {
      dataField: "user_pan.pan_status",
      text: "PAN Status",
      sort: true,
    },
    {
      dataField: "user_corporate_kyc.authorised_aadhar_number",
      text: "Authorised Aadhar number",
      sort: true,
    },
    {
      dataField: "user_corporate_kyc.authorised_name",
      text: "Authorised name",
      sort: true,
    },
    {
      dataField: "user_corporate_kyc.authorised_pan_number",
      text: "Authorised PAN Number",
      sort: true,
    },
    {
      dataField: "user_corporate_kyc.authorised_aadhar_file",
      text: "Authorised aadhar file",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleDocList(order?.user_corporate_kyc?.authorised_aadhar_file)
              }
            >
              {order?.user_corporate_kyc?.authorised_aadhar_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_corporate_kyc.authorised_pan_file",
      text: "Authorised pan file",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleDocList(order?.user_corporate_kyc?.authorised_pan_file)
              }
            >
              {order?.user_corporate_kyc?.authorised_pan_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_corporate_kyc.br_file",
      text: "BR file",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.user_corporate_kyc?.br_file)}
            >
              {order?.user_corporate_kyc?.br_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_corporate_kyc.is_authorised_name",
      text: "Is authorised name",
      sort: true,
    },
    {
      dataField: "user_corporate_kyc.mcr_file",
      text: "MCR File",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.user_corporate_kyc?.mcr_file)}
            >
              {order?.user_corporate_kyc?.mcr_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_pan.pan_type",
      text: "PAN Type",
      sort: true,
    },
    {
      dataField: "user_huf_kyc.is_karta_same_as_huf",
      text: "Is karta same as huf",
      sort: true,
    },
    {
      dataField: "user_huf_kyc.karta_doc_proof",
      text: "Karta doc proof",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleDocList(order?.user_huf_kyc?.karta_doc_proof)
              }
            >
              {order?.user_huf_kyc?.karta_doc_proof}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_huf_kyc.karta_pan_file",
      text: "Karta pan file",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.user_huf_kyc.karta_pan_file)}
            >
              {order?.user_huf_kyc?.karta_pan_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_huf_kyc.karta_pan_number",
      text: "Karta pan number",
      sort: true,
    },
    {
      dataField: "user_trust_kyc.trustee_pan_number",
      text: "Trustee pan number",
      sort: true,
    },
    {
      dataField: "user_trust_kyc.trustee_pan_file",
      text: "Trustee pan file",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleDocList(order?.user_trust_kyc?.trustee_pan_file)
              }
            >
              {order?.user_trust_kyc?.trustee_pan_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_trust_kyc.authority_doc",
      text: "Authority Resolution File",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleDocList(order?.user_trust_kyc?.authority_doc)
              }
            >
              {order?.user_trust_kyc?.authority_doc}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_pan.document_file",
      text: "Pan Document",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.user_pan?.document_file)}
            >
              {order?.user_pan?.document_file}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "user_bank_account.account_name",
      text: "Account Name",
      sort: true,
    },
    {
      dataField: "user_bank_account.account_number",
      text: "Account Number",
      sort: true,
    },
    {
      dataField: "user_bank_account.bank_status",
      text: "Bank Status",
      sort: true,
    },
    {
      dataField: "user_bank_account.ifsc_code",
      text: "IFSC Code",
      sort: true,
    },

    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "address_line_1",
      text: "Address Line 1",
      sort: true,
    },
    {
      dataField: "address_line_2",
      text: "Address Line 2",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "zip_code",
      text: "Pin Code",
      sort: true,
    },

    {
      dataField: "address_file",
      text: "Address File",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.address_file)}
            >
              {order?.address_file}
            </Link>
          </div>
        </>
      ),
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.updated_at),
    },
  ]

  const addressFields = (
    <div className="row">
      <Col md="12" className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <label htmlFor="address_line_1">Address Line 1*</label>
          <div
            style={{
              color: "#34c38f",
              cursor: "pointer",
              // textAlign: "end",
            }}
            className={editAddress ? "text-danger" : ""}
            onClick={() => handleEditAddress()}
          >
            {editAddress ? "Cancel" : "Edit"}
          </div>
        </div>
        <AvField
          name="address_line_1"
          type="textarea"
          errorMessage="Please Enter valid input"
          value={addressLine1 ? addressLine1 : ""}
          validate={{
            required: {
              value: true,
            },
          }}
          onChange={e => setAddressLine1(e.target.value)}
          disabled={!editAddress}
        ></AvField>
      </Col>
      <Col md="12" className="mb-3">
        <AvField
          name="address_line_2"
          label="Address Line 2"
          type="textarea"
          value={addressLine2 ? addressLine2 : ""}
          onChange={e => setAddressLine2(e.target.value)}
          disabled={!editAddress}
        ></AvField>
      </Col>
      <Col md="6" className="mb-3">
        <AvField
          name="city"
          label="City*"
          type="text"
          errorMessage="Please Enter valid input"
          value={city ? city : ""}
          validate={{
            required: {
              value: true,
            },
          }}
          onChange={e => setCity(e.target.value)}
          disabled={!editAddress}
        ></AvField>
      </Col>
      <Col md="6" className="mb-3">
        <AvField
          name="zip_code"
          label="Pin Code*"
          type="text"
          errorMessage="Please Enter valid input"
          value={zipCode ? zipCode : ""}
          validate={{
            required: {
              value: true,
            },
          }}
          onChange={e => setZipCode(e.target.value)}
          disabled={!editAddress}
        ></AvField>
      </Col>
      <Col md="6" className="mb-3">
        <AvField
          name="state"
          label="State*"
          type="select"
          errorMessage="Please Enter valid input"
          value={state ? state : ""}
          validate={{
            required: {
              value: true,
            },
          }}
          onChange={e => setState(e.target.value)}
          disabled={!editAddress}
        >
          <option value="" disabled>
            Select
          </option>
          {indianStates.map(item => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </AvField>
      </Col>
      <Col md="6" className="mb-3">
        <AvField
          name="country"
          label="Country*"
          type="select"
          errorMessage="Please Enter valid input"
          value={country ? country : ""}
          validate={{
            required: {
              value: true,
            },
          }}
          onChange={e => setCountry(e.target.value)}
          disabled={!editAddress}
        >
          <option value="" disabled>
            Select
          </option>
          {countryList.map(item => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </AvField>
      </Col>
    </div>
  )

  const setKycUser = e => {
    if (e?.id !== selectedOption?.id) {
      setPanName("")
      setPanValue("")
      setDocFile(null)
      setaddressFile(null)
      setResStatus("")
      setDematId("")
      setDematFile(null)
      setIfscCode("")
      setAccountNumber("")
      setAccountName("")
      setSelectedOption(e)
      setMcrFile(null)
      setBrFile(null)
      setAuthAadharFile(null)
      setAuthPanFile(null)
      setAuthorisedName("")
      setIsAuthorisedName(true)
      setKartaPanValue(null)
      setKartaPanFile(null)
      setKartaVerfiFile(null)
      setEditAddress(false)
    }
  }

  const handleDocList = file => {
    handleDoc(file)
  }

  const handleKartaPanValue = e => {
    setKartaPanValue(e.target.value)
  }
  const handleTrusteePanNumber = e => {
    setTrusteePanNumber(e.target.value)
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }
  const nextKycStep = () => {
    if (
      startKyc &&
      !(personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        setCorporateInfo(true)
      } else if (panType === "Hindu Undivided Family (HUF)") {
        setHufInfo(true)
      } else if (panType === "Trust") {
        setTrustInfo(true)
      } else {
        setPersonalInfo(true)
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      setDematDetails(true)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      setBankDetails(true)
    }
  }
  const prevKycStep = () => {
    if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      dematDetails &&
      bankDetails
    ) {
      setBankDetails(false)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      setDematDetails(false)
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        setCorporateInfo(false)
      } else if (panType === "Hindu Undivided Family (HUF)") {
        setHufInfo(false)
      } else if (panType === "Trust") {
        setTrustInfo(false)
      } else {
        setPersonalInfo(false)
      }
    }
  }

  useEffect(() => {
    const fullAddress = createFullAdress(
      addressLine1,
      addressLine2,
      zipCode,
      city,
      state,
      country
    )
    setAddress(fullAddress)
  }, [addressLine1, addressLine2, zipCode, city, state, country])

  const handleEditAddress = () => {
    setAddress(panRes?.address)
    setZipCode(panRes?.zip_code)
    setCity(panRes?.city)
    setState(panRes?.state)
    setAddressLine1(panRes?.address_line_1)
    setAddressLine2(panRes?.address_line_2)
    setCountry(panRes?.country)
    setEditAddress(!editAddress)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isSendEmail) {
      try {
        const mailObj = {
          template_id: parseInt(values.templateId),
          data: finalSelectedRows.map(item => ({
            userId: item.id,
          })),
        }
        const response = await axiosInstance.post(`send-email`, mailObj)
        if (response) {
          toast.success("Email Sent Successfully.")
          setFinalSelectedRows([])
        }
      } catch (error) {
        toast.error(error)
      }
      toggle()
      setLoading(false)
      return
    }

    const updateOrder = {
      kyc_status: values.kyc_status || orderList.kyc_status,
      kyc_notes:
        values.kyc_notes ||
        (orderList.kyc_notes &&
          orderList.kyc_status &&
          values.kyc_status == "RE_SUBMIT")
          ? values.kyc_notes || orderList.kyc_notes
          : null,
    }

    // update order
    if (
      !startKyc &&
      !(personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      try {
        const response = await axiosInstance.patch(
          `users/${orderList.id}`,
          updateOrder
        )
        if (response) {
          toast.success("Successfully Updated")
        }
      } catch (error) {
        toast.error(error.message)
      }
      setKycStatus("")
      toggle()
    }
    if (!selectedOption?.id && !orderList?.id) {
      toast.error("Please select a user.")
      setLoading(false)
      return
    }
    if (
      startKyc &&
      !(personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      const userPan = {
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
        pan_number: panValue.toUpperCase(),
        pan_status: "VALID",
        pan_type: panType,
        name: panName,
        document_file: docfile,
        userId: selectedOption?.id,
      }
      const user = {
        dob: dob ? dob : undefined,
        address: createFullAdress(
          addressLine1,
          addressLine2,
          zipCode,
          city,
          state,
          country
        ),
        zip_code: zipCode,
        city: city,
        state: state,
        country: country,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_file: addressFile,
        aadhaar_consent: addressConsent,
      }
      try {
        const response = selectedOption.user_pan?.id
          ? await axiosInstance.patch(
              `user-pan/${selectedOption.user_pan.id}`,
              userPan
            )
          : await axiosInstance.post(`user-pan`, userPan)
        if (response) {
          toast.success("Successfully Updated")
          if (
            panType === "Company" ||
            panType === "Firm/ Limited Liability Partnership"
          ) {
            setCorporateInfo(true)
          } else if (panType === "Hindu Undivided Family (HUF)") {
            setHufInfo(true)
          } else if (panType === "Trust") {
            setTrustInfo(true)
          } else {
            setPersonalInfo(true)
          }
        }
      } catch (error) {
        toast.error(error.message)
      }
      if (address || dob) {
        try {
          const response = selectedOption.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (response) {
            setSelectedOption(response?.data)
            toast.success("Successfully Updated")
            // setPersonalInfo(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      !dematDetails &&
      !bankDetails
    ) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        const userCorporate = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          authorised_name: authorisedName,
          authorised_aadhar_file: authAadharFile,
          authorised_pan_file: authPanFile,
          userId: selectedOption?.id,
        }
        const userCorporateSame = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          userId: selectedOption?.id,
        }
        const user = {
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          city: city,
          zip_code: zipCode,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
        }
        try {
          const response = selectedOption.user_corporate_kyc?.id
            ? await axiosInstance.patch(
                `user-corporate-kyc/${selectedOption.user_corporate_kyc.id}`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
            : await axiosInstance.post(
                `user-corporate-kyc`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
          const res2 = selectedOption?.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (res2) {
            setSelectedOption(res2?.data)
            toast.success("Successfully Updated")
          }
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else if (panType === "Hindu Undivided Family (HUF)") {
        const userHuf = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          karta_doc_proof: kartaVerfiFile,
          userId: selectedOption?.id,
        }
        const userHufSame = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          userId: selectedOption?.id,
        }
        const user = {
          aadhaar_consent: addressConsent,
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          zip_code: zipCode,
          city: city,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_file: addressFile,
        }
        try {
          const response = selectedOption.user_huf_kyc?.id
            ? await axiosInstance.patch(
                `user-huf-kyc/${selectedOption.user_huf_kyc.id}`,
                !isKartaNameSame ? userHuf : userHufSame
              )
            : await axiosInstance.post(
                `user-huf-kyc`,
                !isKartaNameSame ? userHuf : userHufSame
              )
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
        try {
          const response = selectedOption?.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (response) {
            setSelectedOption(response?.data)
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else if (panType === "Trust") {
        const userTrust = {
          pan_type: panType,
          trustee_pan_number: trusteePanNumber.toUpperCase(),
          trustee_pan_file: trusteePanFile,
          authority_doc: authorityResolutionFile,
          userId: selectedOption?.id,
        }
        const user = {
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          city: city,
          zip_code: zipCode,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
        }
        try {
          const response = selectedOption.user_trust_kyc?.id
            ? await axiosInstance.patch(
                `user-trust-kyc/${selectedOption.user_corporate_kyc.id}`,
                userTrust
              )
            : await axiosInstance.post(`user-trust-kyc`, userTrust)
          const res2 = selectedOption?.id
            ? await axiosInstance.patch(`users/${selectedOption.id}`, user)
            : null
          if (res2) {
            setSelectedOption(res2?.data)
            toast.success("Successfully Updated")
          }
          if (response) {
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else {
        const userAddress = {
          dob: values.dob ? values.dob : undefined,
          res_status: resStatus,
          aadhaar_consent: addressConsent,
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          zip_code: zipCode,
          state: state,
          country: country,
          city: city,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_file: addressFile,
        }
        try {
          const response = await axiosInstance.patch(
            `users/${selectedOption?.id}`,
            userAddress
          )
          if (response) {
            setSelectedOption(response?.data)
            toast.success("Successfully Updated")
            setDematDetails(true)
          }
        } catch (error) {
          toast.error(error.message)
        }
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      dematDetails &&
      !bankDetails
    ) {
      const userDemat = {
        demat_id: dematId,
        document_file: dematFile,
        userId: selectedOption?.id,
      }
      try {
        const response = selectedOption.user_demat?.id
          ? await axiosInstance.patch(
              `user-demat/${selectedOption.user_demat.id}`,
              userDemat
            )
          : await axiosInstance.post(`user-demat`, userDemat)
        if (response) {
          toast.success("Successfully Updated")
          setBankDetails(true)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (
      startKyc &&
      (personlInfo || corporateInfo || hufInfo || trustInfo) &&
      dematDetails &&
      bankDetails
    ) {
      const userBank = {
        Account: accountNumber,
        IFSC: ifscCode,
        consent: "Y",
        userId: selectedOption?.id,
      }
      try {
        const response = selectedOption.user_bank_account?.id
          ? await axiosInstance.patch(
              `user-bank-account/${selectedOption.user_bank_account.id}`,
              userBank
            )
          : await axiosInstance.post(`user-bank-account`, userBank)
        if (response) {
          toast.success("Successfully Updated")
          setAccountName(response?.data?.account_name)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    masterData()
    setLoading(false)
  }
  const isValidKycData = () => {
    if (selectedOption?.id) {
      if (
        panValue &&
        panName &&
        docfile &&
        ((resStatus && dob && address) ||
          (kartaPanValue && kartaPanFile && address) ||
          (mcrFile && brFile) ||
          (trusteePanNumber && trusteePanFile && authorityResolutionFile)) &&
        dematId &&
        dematFile &&
        ifscCode &&
        accountNumber &&
        accountName
      ) {
        setDisableKyc(false)
      } else {
        setDisableKyc(true)
      }
    } else {
      setDisableKyc(true)
    }
  }
  const finalSubmit = async () => {
    if (!disableKyc) {
      const data = { kyc_status: "IN_VERIFICATION" }

      try {
        const response = await axiosInstance.patch(
          `users/${selectedOption?.id}`,
          data
        )
        if (response) {
          toast.success("KYC successfully completed")
          masterData()
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      toast.error("Fill out all details first")
    }
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    setStartKyc(false)
    const order = arg

    setOrderList({
      id: order.id,
      kyc_status: order.kyc_status,
      kyc_notes: order?.kyc_status === "RE_SUBMIT" ? order.kyc_notes : null,
    })
    setIsSendEmail(false)
    setPersonalInfo(false)
    setCorporateInfo(false)
    setHufInfo(false)
    setTrustInfo(false)
    setDematDetails(false)
    setBankDetails(false)
    setKycStatus(order.kyc_status)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downladableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = KYCColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downladableArr = csvDownloadData(csvTableHeaders, arr)
  }
  const handleInitiateKYC = async () => {
    setUsers([])
    setErrorDeatail(false)
    setSelectedOption({})
    setPanName("")
    setPanValue("")
    setDocFile(null)
    setaddressFile(null)
    setResStatus("")
    setDematId("")
    setDematFile(null)
    setIfscCode("")
    setAccountNumber("")
    setAccountName("")
    setPanType("")
    setPersonalInfo(false)
    setCorporateInfo(false)
    setHufInfo(false)
    setTrustInfo(false)
    setDematDetails(false)
    setBankDetails(false)
    setIsSendEmail(false)
    setStartKyc(true)
    setOrderList({})
    toggle()
  }

  const handleKartaPanUpload = e => {
    setKartaPanDoc(e.target.files[0])
  }
  const handleTrusteePanUpload = e => {
    setTrusteePanDoc(e.target.files[0])
  }
  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    isValidKycData()
  }, [
    panValue,
    panName,
    docfile,
    selectedOption,
    addressFile,
    dematFile,
    accountName,
  ])
  useEffect(() => {
    if (selectedOption?.id) {
      if (selectedOption.user_pan?.id) {
        setPanValue(selectedOption.user_pan.pan_number)
        setPanName(selectedOption.user_pan.name)
        setDocFile(selectedOption.user_pan.document_file)
        setPanType(selectedOption.user_pan.pan_type)
      }
      if (selectedOption.user_corporate_kyc?.id) {
        // setKartaPanFile(selectedOption.user)
        setAuthAadharFile(
          selectedOption.user_corporate_kyc.authorised_aadhar_file
        )
        setAuthorisedName(selectedOption.user_corporate_kyc.authorised_name)
        setAuthPanFile(selectedOption.user_corporate_kyc.authorised_pan_file)
        setBrFile(selectedOption.user_corporate_kyc.br_file)
        setIsAuthorisedName(
          selectedOption.user_corporate_kyc.is_authorised_name
        )
        setMcrFile(selectedOption.user_corporate_kyc.mcr_file)
      }
      if (selectedOption.user_huf_kyc?.id) {
        setKartaPanFile(selectedOption.user_huf_kyc.karta_pan_file)
        setKartaPanValue(selectedOption.user_huf_kyc.karta_pan_number)
        setKartaVerfiFile(selectedOption.user_huf_kyc.karta_doc_proof)
        setKartaNameSame(selectedOption.user_huf_kyc.is_karta_same_as_huf)
      }
      if (selectedOption.user_trust_kyc?.id) {
        setTrusteePanNumber(selectedOption.user_trust_kyc.trustee_pan_number)
        setTrusteePanFile(selectedOption.user_trust_kyc.trustee_pan_file)
        setAuthorityResolutionFile(selectedOption.user_trust_kyc.authority_doc)
      }
      setDob(
        selectedOption?.dob
          ? moment(selectedOption?.dob).format("YYYY-MM-DD")
          : null
      )
      setAddress(selectedOption?.address)
      setCountry(selectedOption?.country)
      setAddressLine1(selectedOption?.address_line_1)
      setAddressLine2(selectedOption?.address_line_2)
      setZipCode(selectedOption?.zip_code)
      setCity(selectedOption?.city)
      setState(selectedOption?.state)
      setPanRes({
        address_line_1: selectedOption?.address_line_1,
        address_line_2: selectedOption?.address_line_2,
        zip_code: selectedOption?.zip_code,
        city: selectedOption?.city,
        state: selectedOption?.state,
        address: selectedOption?.address,
        country: selectedOption?.country,
      })
      setaddressFile(selectedOption.address_file)
      if (selectedOption.res_status) setResStatus(selectedOption?.res_status)
      if (selectedOption.user_demat?.id) {
        setDematId(selectedOption?.user_demat?.demat_id)
        setDematFile(selectedOption.user_demat.document_file)
      }
      if (selectedOption.user_bank_account?.id) {
        setIfscCode(selectedOption.user_bank_account?.ifsc_code)
        setAccountNumber(selectedOption.user_bank_account?.account_number)
        setAccountName(selectedOption.user_bank_account.account_name)
      }
    }
  }, [selectedOption])

  useEffect(() => {
    if (selectedOption?.id) {
      const newData = users.find(el => el.id === selectedOption.id)
      setSelectedOption(newData)
    }
  }, [users])
  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDocFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const handlePanInput = async e => {
    setErrorDeatail(false)
    setPanName("")
    setPanType("")
    const panInput = e.target.value
    if (panInput?.length == 10) {
      setPanValue(panInput)
      const panData = {
        pan: panInput.toUpperCase(),
        userId: selectedOption?.id,
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
      }
      setLoading(true)
      try {
        const response = await axiosInstance.post(`pan-verification`, panData)
        if (response) {
          setPanName(response?.data?.name)
          setPanType(response?.data.pan_type)
          setDocFile("")
          response.data.dob
            ? setDob(
                moment(response?.data.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
              )
            : setDob()
          const fullAdress = createFullAdress(
            response?.data?.address_line_1,
            response?.data?.address_line_2,
            response?.data?.zip_code,
            response?.data?.city,
            response?.data?.state,
            response?.data?.country
          )
          setAddress(fullAdress)

          response.data.zip_code
            ? setZipCode(response?.data.zip_code)
            : setZipCode("")

          setAddressLine1(response?.data?.address_line_1 || "")
          setAddressLine2(response?.data?.address_line_2 || "")
          setCountry(response?.data?.country || "")
          response.data.city ? setCity(response?.data.city) : setCity("")

          response.data.state ? setState(response?.data.state) : setState("")

          setaddressFile("")
          setAddressConsent(false)
        }
      } catch (error) {
        setErrorDeatail(true)
      }
      setLoading(false)
    }
  }

  const handleAddressUpload = e => {
    setAddressDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (addressDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", addressDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setaddressFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [addressDoc])
  useEffect(async () => {
    if (kartaPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaPanDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaPanDoc])
  useEffect(async () => {
    if (trusteePanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", trusteePanDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setTrusteePanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [trusteePanDoc])
  useEffect(async () => {
    if (kartaVerfiDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaVerfiDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaVerfiFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaVerfiDoc])
  useEffect(async () => {
    if (mcrDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", mcrDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setMcrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [mcrDoc])

  useEffect(async () => {
    if (authorityResolutionDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authorityResolutionDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthorityResolutionFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authorityResolutionDoc])
  useEffect(async () => {
    if (brDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", brDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setBrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [brDoc])

  useEffect(async () => {
    if (authAadharDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authAadharDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthAadharFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authAadharDoc])

  useEffect(async () => {
    if (authPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authPanDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authPanDoc])

  const handleDematUpload = e => {
    setDematDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (dematDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", dematDoc)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDematFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [dematDoc])
  useEffect(() => {
    if (!modal) {
      setEditAddress(false)
    }
  }, [modal])
  console.log({ isAddressUploadReq })
  useEffect(() => {
    console.log({
      com: address == selectedOption?.address,
      old: address,
      new: selectedOption?.address,
    })

    if (selectedOption?.address) {
      if (address != selectedOption?.address) {
        setIsAddressUploadReq(true)
      } else {
        setIsAddressUploadReq(false)
      }
    } else {
      setIsAddressUploadReq(false)
    }
  }, [address])

  useEffect(() => {
    if (addressFile) {
      setIsAddressUploadReq(true)
    } else {
      setIsAddressUploadReq(false)
    }
  }, [addressFile])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const url = `/users?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = KYCColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleMultipleIds = () => {
    if (!finalSelectedRows.length) {
      toast.error("Please select atleast a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    setIsSendEmail(true)
    toggle()
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Kyc List" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleInitiateKYC}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-plus me-1" /> Add/Update
                    </Button>
                  )}
                  {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={handleMultipleIds}
                      disabled={finalSelectedRows.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Send Email
                    </Button>
                  )}

                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => downloadData("KYC Data")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={KYCColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-start">
                          <Col md={4}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Name on Pan or Pan Number`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Kyc status</label>
                              <ReactSelect
                                users={kycStatusType}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "kycStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          {/* <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}
                        </Row>{" "}
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={KYCColumns(toggle)}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isSendEmail
                                  ? "Send Email"
                                  : startKyc &&
                                    !(
                                      personlInfo ||
                                      corporateInfo ||
                                      hufInfo ||
                                      trustInfo
                                    ) &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "PAN Details"
                                  : startKyc &&
                                    personlInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "Personal Information"
                                  : startKyc &&
                                    corporateInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "Corporate Information"
                                  : startKyc &&
                                    hufInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "HUF Information"
                                  : startKyc &&
                                    trustInfo &&
                                    !dematDetails &&
                                    !bankDetails
                                  ? "Trust Information"
                                  : startKyc &&
                                    (personlInfo ||
                                      corporateInfo ||
                                      hufInfo ||
                                      trustInfo) &&
                                    dematDetails &&
                                    !bankDetails
                                  ? "Demat Details"
                                  : startKyc &&
                                    (personlInfo ||
                                      corporateInfo ||
                                      hufInfo ||
                                      trustInfo) &&
                                    dematDetails &&
                                    bankDetails
                                  ? "Bank Account Details"
                                  : orderList.kyc_status == "VERIFIED"
                                  ? "KYC Verified"
                                  : "Update KYC Status"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color:
                                        orderList.kyc_status == "VERIFIED" &&
                                        !startKyc &&
                                        !isSendEmail
                                          ? "green"
                                          : "grey",
                                    }}
                                  >
                                    {isSendEmail ? (
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="templateId"
                                            label="Select the type of email"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid email template"
                                            value={orderList.templateId || ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            style={{ width: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {kycEmailTemplate.map(
                                              (item, index) => (
                                                <option
                                                  key={item.templateName}
                                                  value={item.id}
                                                >
                                                  {item.templateName}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col className="col-12">
                                        {!startKyc && (
                                          <div className="mb-3">
                                            <AvField
                                              name="kyc_status"
                                              label="Kyc Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Status"
                                              onChange={e =>
                                                setKycStatus(e.target.value)
                                              }
                                              value={orderList.kyc_status || ""}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              style={{ widht: "100%" }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="IN_VERIFICATION">
                                                In Verification
                                              </option>
                                              <option value="VERIFIED">
                                                Verified
                                              </option>
                                              <option value="RE_SUBMIT">
                                                Re Submit
                                              </option>
                                            </AvField>
                                          </div>
                                        )}
                                        {!startKyc &&
                                          kycStatus === "RE_SUBMIT" && (
                                            <div className="mb-3">
                                              <AvField
                                                name="kyc_notes"
                                                label="Kyc Note"
                                                type="textarea"
                                                errorMessage="Invalid Note"
                                                value={
                                                  orderList.kyc_notes || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              ></AvField>
                                            </div>
                                          )}
                                        {startKyc &&
                                          !(
                                            personlInfo ||
                                            corporateInfo ||
                                            hufInfo ||
                                            trustInfo
                                          ) &&
                                          !dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="mb-3">
                                                <label>User</label>
                                                {selectedOption?.id ? (
                                                  <ReactSelect
                                                    users={users}
                                                    setSelectedOption={
                                                      setKycUser
                                                    }
                                                    selectedOption={{
                                                      user_pan: {
                                                        name: selectedOption
                                                          ?.user_pan?.name,
                                                        pan_number:
                                                          selectedOption
                                                            ?.user_pan
                                                            ?.pan_number,
                                                      },
                                                      email:
                                                        selectedOption.email,
                                                      phone:
                                                        selectedOption.phone,
                                                    }}
                                                    searchValue={searchValue}
                                                    setSearchValue={
                                                      handleUserSearch
                                                    }
                                                    multiOptionLabel={false}
                                                    optionLabelKeys={[
                                                      "user_pan?.name",
                                                      "email",
                                                      "phone",
                                                      "user_pan?.pan_number",
                                                    ]}
                                                  />
                                                ) : (
                                                  <ReactSelect
                                                    users={users}
                                                    setSelectedOption={
                                                      setKycUser
                                                    }
                                                    searchValue={searchValue}
                                                    setSearchValue={
                                                      handleUserSearch
                                                    }
                                                    multiOptionLabel={false}
                                                    optionLabelKeys={[
                                                      "user_pan?.name",
                                                      "email",
                                                      "phone",
                                                      "user_pan?.pan_number",
                                                    ]}
                                                  />
                                                )}
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="panValue"
                                                  label="Enter your PAN"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={panValue || ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  maxLength="10"
                                                  onChange={handlePanInput}
                                                ></AvField>
                                                <p style={{ color: "red" }}>
                                                  {errorDetail &&
                                                    "Please enter valid input"}
                                                </p>
                                              </div>
                                              {panType ==
                                                "Firm/ Limited Liability Partnership" ||
                                              panType == "Sole Proprietor" ||
                                              panType == "Partnership Firm" ? (
                                                <div className="mb-3">
                                                  <AvField
                                                    name="pan_type"
                                                    label="PAN Type"
                                                    type="select"
                                                    className="form-select"
                                                    errorMessage="Select valid input"
                                                    value={
                                                      panType ||
                                                      "Firm/ Limited Liability Partnership"
                                                    }
                                                    onChange={e => {
                                                      console.log(
                                                        e.target.value
                                                      )
                                                      setPanType(e.target.value)
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  >
                                                    <option disabled value="">
                                                      Select an option
                                                    </option>
                                                    <option value="Firm/ Limited Liability Partnership">
                                                      Limited Liability
                                                      Partnership
                                                    </option>
                                                    <option value="Sole Proprietor">
                                                      Sole Proprietor
                                                    </option>
                                                    <option value="Partnership Firm">
                                                      Partnership Firm
                                                    </option>
                                                  </AvField>
                                                </div>
                                              ) : null}
                                              <div className=" mb-3">
                                                <AvField
                                                  name="panName"
                                                  label="Name as per PAN"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={panName || ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  disabled
                                                ></AvField>
                                              </div>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <Label
                                                  htmlFor="formFile"
                                                  className="form-label"
                                                >
                                                  Upload PAN Card
                                                </Label>

                                                <Input
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleFileUpload}
                                                />
                                                <p className="mt-3">
                                                  {docfile
                                                    ? `File uploaded: ${docfile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                                <p className="mt-3">
                                                  Kindly upload copy of PAN card
                                                  front side in pdf/jpg/png
                                                  format (max file size 1MB)
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        {startKyc &&
                                          personlInfo &&
                                          !dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="row">
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="res_status"
                                                    label="Residential Status"
                                                    type="select"
                                                    className="form-select"
                                                    errorMessage="Select valid input"
                                                    value={
                                                      resStatus ? resStatus : ""
                                                    }
                                                    onChange={e => {
                                                      console.log(
                                                        e.target.value
                                                      )
                                                      setResStatus(
                                                        e.target.value
                                                      )
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  >
                                                    <option disabled value="">
                                                      Select an option
                                                    </option>
                                                    <option value="Resident">
                                                      Resident
                                                    </option>
                                                    <option value="NRI">
                                                      NRI
                                                    </option>
                                                    <option value="Non-Resident">
                                                      Non-Resident
                                                    </option>
                                                  </AvField>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                  <AvField
                                                    name="dob"
                                                    label="Date of Birth"
                                                    type="date"
                                                    errorMessage="Select valid input"
                                                    value={dob ? dob : ""}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={e => {
                                                      setDob(
                                                        moment(
                                                          e.target.value
                                                        ).format("YYYY-MM-DD")
                                                      )
                                                    }}
                                                  ></AvField>
                                                </div>
                                              </div>
                                              {/* <div className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label="Address"
                                                  type="textArea"
                                                  errorMessage="Please Enter valid input"
                                                  value={address ? address : ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={e =>
                                                    setAddress(e.target.value)
                                                  }
                                                  disabled={!editAddress}
                                                ></AvField>
                                                <p
                                                  style={{
                                                    color: "#34c38f",
                                                    cursor: "pointer",
                                                    textAlign: "end",
                                                  }}
                                                  onClick={() =>
                                                    handleEditAddress()
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div> */}
                                              <>{addressFields}</>
                                              {editAddress && (
                                                <div>
                                                  {loading && <Loader />}
                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value:
                                                          isAddressUploadReq
                                                            ? true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={
                                                      handleAddressUpload
                                                    }
                                                    name="addressFile"
                                                    label="Upload Address Proof"
                                                    disabled={!editAddress}
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {addressFile
                                                      ? `File uploaded: ${addressFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                              )}
                                              {isAddressUploadReq && (
                                                <div className="mb-3">
                                                  <AvField
                                                    name="addressConsent"
                                                    label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                    type="checkbox"
                                                    errorMessage="Please check this"
                                                    value={
                                                      addressConsent || false
                                                    }
                                                    validate={{
                                                      required: {
                                                        value:
                                                          isAddressUploadReq
                                                            ? true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAddressConsent(
                                                        e.target.checked
                                                      )
                                                    }
                                                  ></AvField>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        {startKyc &&
                                          corporateInfo &&
                                          !dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: mcrFile
                                                        ? false
                                                        : true,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setMcrDoc(e.target.files[0])
                                                  }
                                                  name="mcrFile"
                                                  label=" Upload Company Master Data"
                                                ></AvField>
                                                <p className="mt-3">
                                                  {mcrFile
                                                    ? `File uploaded: ${mcrFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: brFile
                                                        ? false
                                                        : true,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setBrDoc(e.target.files[0])
                                                  }
                                                  name="brFile"
                                                  label="Upload Board Resolution"
                                                ></AvField>
                                                <p className="mt-3">
                                                  {brFile
                                                    ? `File uploaded: ${brFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                              {/* <div className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label="Address"
                                                  type="textArea"
                                                  errorMessage="Please Enter valid input"
                                                  value={address ? address : ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={e =>
                                                    setAddress(e.target.value)
                                                  }
                                                  disabled={!editAddress}
                                                ></AvField>
                                                <p
                                                  style={{
                                                    color: "#34c38f",
                                                    cursor: "pointer",
                                                    textAlign: "end",
                                                  }}
                                                  onClick={() =>
                                                    handleEditAddress()
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div> */}
                                              <>{addressFields}</>
                                              <div className="mb-3">
                                                <AvField
                                                  name="isAuthorisedName"
                                                  label="Is the authorised person as per the Board Resolution same as in Company Master Data."
                                                  type="checkbox"
                                                  errorMessage="Please check this"
                                                  value={
                                                    isAuthorisedName || false
                                                  }
                                                  onChange={e =>
                                                    setIsAuthorisedName(
                                                      e.target.checked
                                                    )
                                                  }
                                                ></AvField>
                                              </div>
                                              {!isAuthorisedName && (
                                                <>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="authorisedName"
                                                      label="Authorised Person Name"
                                                      type="text"
                                                      errorMessage="Please Enter valid input"
                                                      value={
                                                        authorisedName
                                                          ? authorisedName
                                                          : ""
                                                      }
                                                      validate={{
                                                        required: {
                                                          value:
                                                            !isAuthorisedName
                                                              ? true
                                                              : false,
                                                        },
                                                      }}
                                                      onChange={e =>
                                                        setAuthorisedName(
                                                          e.target.value
                                                        )
                                                      }
                                                    ></AvField>
                                                  </div>
                                                  <div className="mt-3">
                                                    {loading && <Loader />}
                                                    <AvField
                                                      className="form-control"
                                                      type="file"
                                                      id="formFile"
                                                      errorMessage="Please Enter valid input"
                                                      validate={{
                                                        required: {
                                                          value:
                                                            !isAuthorisedName
                                                              ? authAadharFile
                                                                ? false
                                                                : true
                                                              : false,
                                                        },
                                                      }}
                                                      onChange={e =>
                                                        setAuthAadharDoc(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                      name="authAddressProof"
                                                      label="Authorised Person
                                                        Address Proof"
                                                    ></AvField>
                                                    <p className="mt-3">
                                                      {authAadharFile
                                                        ? `File uploaded: ${authAadharFile
                                                            .split("/")
                                                            .pop()}`
                                                        : null}
                                                    </p>
                                                  </div>
                                                  <div className="mt-3">
                                                    {loading && <Loader />}
                                                    <Label
                                                      htmlFor="formFile"
                                                      className="form-label"
                                                    ></Label>
                                                    <AvField
                                                      className="form-control"
                                                      type="file"
                                                      id="formFile"
                                                      errorMessage="Please Enter valid input"
                                                      validate={{
                                                        required: {
                                                          value:
                                                            !isAuthorisedName
                                                              ? authPanFile
                                                                ? false
                                                                : true
                                                              : false,
                                                        },
                                                      }}
                                                      onChange={e =>
                                                        setAuthPanDoc(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                      name="authPanCard"
                                                      label="Authorised Person PAN
                                                        Card"
                                                    ></AvField>
                                                    <p className="mt-3">
                                                      {authPanFile
                                                        ? `File uploaded: ${authPanFile
                                                            .split("/")
                                                            .pop()}`
                                                        : null}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        {startKyc &&
                                          hufInfo &&
                                          !dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="kartaPanValue"
                                                  label="Enter Karta's PAN"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={kartaPanValue || ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  maxLength="10"
                                                  onChange={handleKartaPanValue}
                                                ></AvField>
                                                <p style={{ color: "red" }}>
                                                  {errorDetail &&
                                                    "Please enter valid input"}
                                                </p>
                                              </div>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: kartaPanFile
                                                        ? false
                                                        : true,
                                                    },
                                                  }}
                                                  onChange={
                                                    handleKartaPanUpload
                                                  }
                                                  name="kartaPanFile"
                                                  label="Upload Karta PAN"
                                                ></AvField>
                                                <p className="mt-3">
                                                  {kartaPanFile
                                                    ? `File uploaded: ${kartaPanFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                                {/* <p className="mt-3">
                                                    Kindly upload copy of PAN
                                                    card front side in
                                                    pdf/jpg/png format (max file
                                                    size 1MB)
                                                  </p> */}
                                              </div>
                                              {/* <div className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label="Address"
                                                  type="textArea"
                                                  errorMessage="Please Enter valid input"
                                                  value={address ? address : ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={e =>
                                                    setAddress(e.target.value)
                                                  }
                                                  disabled={!editAddress}
                                                ></AvField>
                                                <p
                                                  style={{
                                                    color: "#34c38f",
                                                    cursor: "pointer",
                                                    textAlign: "end",
                                                  }}
                                                  onClick={() =>
                                                    handleEditAddress()
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div> */}
                                              <>{addressFields}</>
                                              {editAddress && (
                                                <div>
                                                  {loading && <Loader />}

                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value:
                                                          isAddressUploadReq
                                                            ? addressFile
                                                              ? false
                                                              : true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={
                                                      handleAddressUpload
                                                    }
                                                    name="addressFile"
                                                    label="Upload Address Proof"
                                                    disabled={!editAddress}
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {addressFile
                                                      ? `File uploaded: ${addressFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                              )}
                                              <div className="mb-3">
                                                <AvField
                                                  name="isAuthorisedName"
                                                  label="Is Karta Name same as HUF Name"
                                                  type="checkbox"
                                                  errorMessage="Please check this"
                                                  value={
                                                    isKartaNameSame || false
                                                  }
                                                  onChange={e =>
                                                    setKartaNameSame(
                                                      e.target.checked
                                                    )
                                                  }
                                                ></AvField>
                                              </div>
                                              {!isKartaNameSame && (
                                                <div className="mt-3">
                                                  {loading && <Loader />}
                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value: !isKartaNameSame
                                                          ? kartaVerfiFile
                                                            ? false
                                                            : true
                                                          : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setKartaVerfiDoc(
                                                        e.target.files[0]
                                                      )
                                                    }
                                                    name="kartVerifyDoc"
                                                    label="Karta Verification
                                                      Document"
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {kartaVerfiFile
                                                      ? `File uploaded: ${kartaVerfiFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                              )}
                                              {(!isKartaNameSame ||
                                                isAddressUploadReq) && (
                                                <div className="mb-3">
                                                  <AvField
                                                    name="addressConsent"
                                                    label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                    type="checkbox"
                                                    errorMessage="Please check this"
                                                    value={
                                                      addressConsent || false
                                                    }
                                                    validate={{
                                                      required: {
                                                        value:
                                                          !isKartaNameSame ||
                                                          isAddressUploadReq
                                                            ? true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAddressConsent(
                                                        e.target.checked
                                                      )
                                                    }
                                                  ></AvField>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        {startKyc &&
                                          trustInfo &&
                                          !dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="trusteePanNumber"
                                                  label="Enter Trustee's PAN"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={trusteePanNumber || ""}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  maxLength="10"
                                                  onChange={
                                                    handleTrusteePanNumber
                                                  }
                                                ></AvField>
                                                <p style={{ color: "red" }}>
                                                  {errorDetail &&
                                                    "Please enter valid input"}
                                                </p>
                                              </div>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value: trusteePanFile
                                                        ? false
                                                        : true,
                                                    },
                                                  }}
                                                  onChange={
                                                    handleTrusteePanUpload
                                                  }
                                                  name="trusteePanFile"
                                                  label="Upload Trustee PAN"
                                                ></AvField>
                                                <p className="mt-3">
                                                  {trusteePanFile
                                                    ? `File uploaded: ${trusteePanFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                                {/* <p className="mt-3">
                                                    Kindly upload copy of PAN
                                                    card front side in
                                                    pdf/jpg/png format (max file
                                                    size 1MB)
                                                  </p> */}
                                              </div>
                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value:
                                                        authorityResolutionFile
                                                          ? false
                                                          : true,
                                                    },
                                                  }}
                                                  onChange={e =>
                                                    setAuthorityResolutionDoc(
                                                      e.target.files[0]
                                                    )
                                                  }
                                                  name="brFile"
                                                  label="Upload Authority Resolution"
                                                ></AvField>
                                                <p className="mt-3">
                                                  {authorityResolutionFile
                                                    ? `File uploaded: ${authorityResolutionFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                              <>{addressFields}</>
                                              {editAddress && (
                                                <div>
                                                  {loading && <Loader />}
                                                  <AvField
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    errorMessage="Please Enter valid input"
                                                    validate={{
                                                      required: {
                                                        value:
                                                          isAddressUploadReq
                                                            ? true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={
                                                      handleAddressUpload
                                                    }
                                                    name="addressFile"
                                                    label="Upload Address Proof"
                                                    disabled={!editAddress}
                                                  ></AvField>
                                                  <p className="mt-3">
                                                    {addressFile
                                                      ? `File uploaded: ${addressFile
                                                          .split("/")
                                                          .pop()}`
                                                      : null}
                                                  </p>
                                                </div>
                                              )}
                                              {isAddressUploadReq && (
                                                <div className="mb-3">
                                                  <AvField
                                                    name="addressConsent"
                                                    label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                    type="checkbox"
                                                    errorMessage="Please check this"
                                                    value={
                                                      addressConsent || false
                                                    }
                                                    validate={{
                                                      required: {
                                                        value:
                                                          isAddressUploadReq
                                                            ? true
                                                            : false,
                                                      },
                                                    }}
                                                    onChange={e =>
                                                      setAddressConsent(
                                                        e.target.checked
                                                      )
                                                    }
                                                  ></AvField>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        {startKyc &&
                                          (personlInfo ||
                                            corporateInfo ||
                                            hufInfo ||
                                            trustInfo) &&
                                          dematDetails &&
                                          !bankDetails && (
                                            <>
                                              <div className="mb-3">
                                                <AvField
                                                  name="demat"
                                                  label="DEMAT ACCOUNT NUMBER"
                                                  type="text"
                                                  errorMessage="Please Enter valid input"
                                                  value={dematId ? dematId : ""}
                                                  onChange={e =>
                                                    setDematId(e.target.value)
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                ></AvField>
                                              </div>

                                              <div className="mt-3">
                                                {loading && <Loader />}
                                                <Label
                                                  htmlFor="formFile"
                                                  className="form-label"
                                                >
                                                  Upload Document
                                                </Label>

                                                <Input
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleDematUpload}
                                                />
                                                <p className="mt-3">
                                                  {dematFile
                                                    ? `File uploaded: ${dematFile
                                                        .split("/")
                                                        .pop()}`
                                                    : null}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        {startKyc &&
                                          (personlInfo ||
                                            corporateInfo ||
                                            hufInfo ||
                                            trustInfo) &&
                                          dematDetails &&
                                          bankDetails && (
                                            <>
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  name="ifsc"
                                                  label="IFSC Code"
                                                  type="text"
                                                  errorMessage="Please Enter valid input"
                                                  value={
                                                    ifscCode ? ifscCode : ""
                                                  }
                                                  onChange={e =>
                                                    setIfscCode(e.target.value)
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                ></AvField>
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="account_number"
                                                  label="Account Number"
                                                  type="text"
                                                  errorMessage="Please Enter valid input"
                                                  value={
                                                    accountNumber
                                                      ? accountNumber
                                                      : ""
                                                  }
                                                  onChange={e =>
                                                    setAccountNumber(
                                                      e.target.value
                                                    )
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                ></AvField>
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="account_name"
                                                  label="Account Name"
                                                  type="text"
                                                  disabled
                                                  value={accountName || ""}
                                                ></AvField>
                                              </div>
                                            </>
                                          )}
                                      </Col>
                                    )}
                                  </Row>
                                  {isSendEmail ? (
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Send Email
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row>
                                      {startKyc ? (
                                        <Col>
                                          <div className="text-start mt-3">
                                            <button
                                              type="button"
                                              className="btn btn-success save-user"
                                              disabled={
                                                loading ||
                                                (startKyc &&
                                                  !(
                                                    personlInfo ||
                                                    corporateInfo ||
                                                    hufInfo ||
                                                    trustInfo
                                                  ) &&
                                                  !dematDetails &&
                                                  !bankDetails)
                                                  ? true
                                                  : false
                                              }
                                              onClick={prevKycStep}
                                            >
                                              {"PREVIOUS"}
                                            </button>
                                          </div>
                                        </Col>
                                      ) : null}
                                      <Col>
                                        <div
                                          className={
                                            !startKyc
                                              ? "text-end mt-3"
                                              : "text-center mt-3"
                                          }
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              (startKyc &&
                                                (personlInfo ||
                                                  corporateInfo ||
                                                  hufInfo ||
                                                  trustInfo) &&
                                                dematDetails &&
                                                bankDetails &&
                                                accountName)
                                                ? true
                                                : false
                                            }
                                          >
                                            {!startKyc ||
                                            (startKyc &&
                                              (personlInfo ||
                                                corporateInfo ||
                                                hufInfo ||
                                                trustInfo) &&
                                              dematDetails &&
                                              bankDetails)
                                              ? "SAVE"
                                              : "SAVE & CONTINUE"}
                                          </button>
                                        </div>
                                      </Col>
                                      {startKyc &&
                                      (personlInfo ||
                                        corporateInfo ||
                                        hufInfo ||
                                        trustInfo) &&
                                      dematDetails &&
                                      bankDetails ? (
                                        <Col>
                                          <div className="text-center mt-3">
                                            <button
                                              type="button"
                                              className="btn btn-success save-user"
                                              onClick={finalSubmit}
                                              disabled={
                                                loading || disableKyc
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {"SUBMIT"}
                                            </button>
                                          </div>
                                        </Col>
                                      ) : null}
                                      {startKyc ? (
                                        <Col>
                                          <div className="text-end mt-3">
                                            <button
                                              type="button"
                                              className="btn btn-success save-user"
                                              disabled={
                                                loading ||
                                                (startKyc &&
                                                  (personlInfo ||
                                                    corporateInfo ||
                                                    hufInfo ||
                                                    trustInfo) &&
                                                  dematDetails &&
                                                  bankDetails)
                                                  ? true
                                                  : false
                                              }
                                              onClick={nextKycStep}
                                            >
                                              {"NEXT"}
                                            </button>
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  )}
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KycList
