import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { toast } from "react-toastify"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { axiosInstance } from "ConfigAxioxinstance"
import ErrorModal from "common/ErrorModal"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )

      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // Login for Admin
      const response = yield call(postFakeLogin, {
        email: user.email.toLowerCase(),
        password: user.password,
      })
      const resJson = yield response?.json()
      //localStorage.setItem("authUser", JSON.stringify(response))
      localStorage.setItem("authUser", JSON.stringify(resJson))
      localStorage.setItem("user_token", resJson.accessToken)
      localStorage.setItem("user", resJson?.user?.permissions)
      localStorage.setItem("userId", resJson?.user?.id)
      const userInfo = resJson?.user
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
      if (
        resJson?.user?.permissions !== "Super_Admin" &&
        resJson?.user?.permissions !== "Admin" &&
        resJson?.user?.is_password_expired
      ) {
        sessionStorage.setItem("authUser", JSON.stringify(resJson))
        sessionStorage.setItem("user_token", resJson.accessToken)
        sessionStorage.setItem("user", resJson?.user?.permissions)
        sessionStorage.setItem("userId", resJson?.user?.id)
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
        localStorage.clear()
      }
      yield put(loginSuccess(response))
      resJson.accessToken &&
      resJson?.user?.permissions === "OPS_USER" &&
      resJson?.user?.role?.permision?.length &&
      resJson?.user?.is_password_expired
        ? history.push("/change-password")
        : resJson?.user?.permissions == "Super_Admin" ||
          resJson?.user?.permissions == "Admin" ||
          (resJson?.user?.permissions === "OPS_USER" &&
            resJson?.user?.role?.permision?.length)
        ? history.push("/")
        : resJson?.user?.permissions === "OPS_USER" &&
          !resJson?.user?.role?.permision?.length
        ? history.push({
            pathname: "/login",
            search: "permission_undefined",
          })
        : resJson?.user?.permissions == "USER"
        ? toast.error("Invalid User")
        : history.push("/login")
      const error = resJson?.message
      if (error) {
        toast.error(error)
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

async function logoutUser({ payload: { history } }) {
  try {
    const response = await axiosInstance.delete("authentication")
    if (response) {
      // localStorage.removeItem("authUser")
      window.localStorage.clear()
      window.sessionStorage.clear()
      // if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      //   debugger

      //   const response = yield call(postFakeLogout)
      //   debugger

      //   yield put(logoutUserSuccess(response))
      // }
      history.push("/login")
    }
  } catch (error) {
    toast.error(error?.message)
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const fireBaseBackend = getFirebaseBackend()
    //   const response = yield call(
    //     fireBaseBackend.socialLoginUser,
    //     data,
    //     type,
    //   )
    //   localStorage.setItem("authUser", JSON.stringify(response))
    //   yield put(loginSuccess(response))
    // } else {
    //   const response = yield call(postSocialLogin, data)
    //   localStorage.setItem("authUser", JSON.stringify(response))
    //   yield put(loginSuccess(response))
    // }
    history.push("/my-dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
