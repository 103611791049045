import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { Pagination } from "../../constants/common"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"

const CMSListingTable = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `cms?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const accessRestriction = DealManagementAccess(AccessId?.SUPPORT)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const CMSColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "entity",
      text: "Entity",
      sort: true,
    },

    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },

    {
      dataField: "item1",
      text: "Item 1",
      sort: true,
    },

    {
      dataField: "item2",
      text: "Item 2",
      sort: true,
    },
    {
      dataField: "item3",
      text: "Item 3",
      sort: true,
    },
    {
      dataField: "item4",
      text: "Item 4",
      sort: true,
    },
    {
      dataField: "item5",
      text: "Item 5",
      sort: true,
    },
    {
      dataField: "item6",
      text: "Item 6",
      sort: true,
    },
    {
      dataField: "item7",
      text: "Item 7",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      entity: order.entity,
      disp_order: order.disp_order,
      item1: order.item1,
      file1: order.file1,
      item2: order.item2,
      item3: order.item3,
      item4: order.item4,
      item5: order.item5,
      item6: order.item6,
      item7: order.item7,
    })

    setIsEdit(true)

    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        entity: values.entity,
        disp_order: values.disp_order,
        item1: values.item1,
        file1: values.file1,
        item2: values.item2,
        item3: values.item3,
        item4: values.item4,
        item5: values.item5,
        item6: values.item6,
        item7: values.item7,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `cms/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="CMS" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={CMSColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={CMSColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit" : "Add"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="entity"
                                          label="Entity"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.entity || ""}
                                        ></AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="disp_order"
                                          label="Display Order"
                                          type="number"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.disp_order || ""}
                                        ></AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="item1"
                                          label="Item 1"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.item1 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item2"
                                          label="Item 2"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item2 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item3"
                                          label="Item 3"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item3 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item4"
                                          label="Item 4"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item4 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item5"
                                          label="Item 5"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item5 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item6"
                                          label="Item 6"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item6 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="item7"
                                          label="Item 7"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.item7 || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="file1"
                                          label="File 1"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={orderList.file1 || ""}
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CMSListingTable.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(CMSListingTable)
