import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import { statusEnum } from "constants/common"
const Kyc = () => {
  const [loading, setLoading] = useState(false)
  const [kycData, setKycData] = useState({})
  const [kycFormState, setKycFormState] = useState({})
  const [kycDisabled, setKycDisabled] = useState(false)
  const [file, setFile] = useState(null)
  const [currentFileUpload, setCurrentFileUpload] = useState("")
  const [isSaved, setIsSaved] = useState(false)

  const userId = JSON.parse(localStorage.getItem("userInfo")).id
  const getKycData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-kyc?userId=${userId}`
      )
      if (response) {
        setKycData(response.data?.data[0] || response.data[0])
        const userKyc = response.data?.data[0] || response.data[0]
        if (userKyc) {
          setKycDisabled(
            userKyc?.kyc_verification_status === "VERIFIED" ||
              userKyc?.kyc_verification_status === "IN_VERIFICATION"
          )
          const kycFormData = {
            panType: userKyc?.pan_type,
            panNumber: userKyc?.pan_number,
            panFile: userKyc?.pan_document_url,
            registeredAddress: userKyc?.registered_address,
            aadharNumber: userKyc?.aadhar_number,
            aadharFile: userKyc?.aadhar_document_url,
            gstNumber: userKyc?.gst_number,
            gstCertificateFile: userKyc?.gst_certificate_url,
            msmeRegistrationNumber: userKyc?.msme_registration_number,
            msmeDoc: userKyc?.msme_registration_doc,
            bankName: userKyc?.bank_name,
            branchName: userKyc?.branch_name,
            accountName: userKyc?.account_name,
            ifscCode: userKyc?.ifsc_code,
            accountNumber: userKyc?.account_number,
            cancelledChequeFile: userKyc?.cancelled_cheque_url,
            bankStatementFile: userKyc?.bank_statement_url,
            name: userKyc?.name,
          }
          if (
            userKyc?.pan_type == "Firm/ Limited Liability Partnership" ||
            userKyc?.pan_type == "Sole Proprietor" ||
            userKyc?.pan_type == "Partnership Firm" ||
            userKyc?.pan_type == "Company"
          ) {
            kycFormData["authorizedSignatory"] = userKyc?.signatory
          }
          if (Object.values(kycFormData).length > 0) {
            let obj = Object.keys(kycFormData).every(el => {
              if (
                el === "aadharNumber" ||
                el === "aadharFile" ||
                el === "gstNumber" ||
                el === "gstCertificateFile" ||
                el === "bankStatementFile" ||
                el === "cancelledChequeFile" ||
                el == "msmeDoc" ||
                el == "msmeRegistrationNumber"
              ) {
                return true // Since not required.
              } else {
                return kycFormData[el]
              }
            })
            if (obj) {
              setIsSaved(true)
            }
          }
          setKycFormState(kycFormData)
        } else {
          setKycFormState({})
        }
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }
  useEffect(async () => {
    getKycData()
  }, [])

  const handleOnChange = e => {
    setIsSaved(false)
    const { name, value } = e.target
    switch (name) {
      case "panType":
        if (value == "Person") {
          setKycFormState({
            ...kycFormState,
            panType: value,
            authorizedSignatory: "",
          })
        } else {
          setKycFormState({
            ...kycFormState,
            panType: value,
          })
        }
        break
      case "panNumber":
        setKycFormState({
          ...kycFormState,
          panNumber: value,
        })
        break
      case "registeredAddress":
        setKycFormState({
          ...kycFormState,
          registeredAddress: value,
        })
        break
      case "authorizedSignatory":
        setKycFormState({
          ...kycFormState,
          authorizedSignatory: value,
        })
        break
      case "aadharNumber":
        setKycFormState({
          ...kycFormState,
          aadharNumber: value,
        })
        break
      case "gstNumber":
        setKycFormState({
          ...kycFormState,
          gstNumber: value,
        })
        break
      case "bankName":
        setKycFormState({
          ...kycFormState,
          bankName: value,
        })
        break
      case "branchName":
        setKycFormState({
          ...kycFormState,
          branchName: value,
        })
        break
      case "accountName":
        setKycFormState({
          ...kycFormState,
          accountName: value,
        })
        break
      case "ifscCode":
        setKycFormState({
          ...kycFormState,
          ifscCode: value,
        })
        break
      case "accountNumber":
        setKycFormState({
          ...kycFormState,
          accountNumber: value,
        })
        break
      case "legalEntityName":
        setKycFormState({
          ...kycFormState,
          name: value,
        })
        break
      case "msmeRegistrationNumber":
        setKycFormState({
          ...kycFormState,
          msmeRegistrationNumber: value,
        })
        break
      default:
        break
    }
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
    setCurrentFileUpload(e.target.name)
  }
  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", userId)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setFileUploadUrl(currentFileUpload, response)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])
  const setFileUploadUrl = (currentFile, response) => {
    setIsSaved(false)
    switch (currentFile) {
      case "panFile":
        setKycFormState({
          ...kycFormState,
          panFile: response?.data?.[0]?.name,
        })
        break
      case "aadharFile":
        setKycFormState({
          ...kycFormState,
          aadharFile: response?.data?.[0]?.name,
        })
        break
      case "gstCertificateFile":
        setKycFormState({
          ...kycFormState,
          gstCertificateFile: response?.data?.[0]?.name,
        })
        break
      case "cancelledChequeFile":
        setKycFormState({
          ...kycFormState,
          cancelledChequeFile: response?.data?.[0]?.name,
        })
        break
      case "bankStatementFile":
        setKycFormState({
          ...kycFormState,
          bankStatementFile: response?.data?.[0]?.name,
        })
        break
      case "msmeDoc":
        setKycFormState({
          ...kycFormState,
          msmeDoc: response?.data?.[0]?.name,
        })
        break
      default:
        break
    }
  }
  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async kycStatus => {
    setLoading(true)
    const distributorKycData = {
      pan_type: kycFormState.panType,
      pan_number: kycFormState.panNumber.toUpperCase(),
      pan_document_url: kycFormState.panFile,
      registered_address: kycFormState.registeredAddress,
      signatory: kycFormState.authorizedSignatory,
      aadhar_number: kycFormState?.aadharNumber,
      aadhar_document_url: kycFormState?.aadharFile,
      gst_number: kycFormState?.gstNumber,
      gst_certificate_url: kycFormState?.gstCertificateFile,
      bank_name: kycFormState.bankName.toUpperCase(),
      branch_name: kycFormState.branchName.toUpperCase(),
      account_name: kycFormState.accountName.toUpperCase(),
      ifsc_code: kycFormState.ifscCode.toUpperCase(),
      account_number: kycFormState.accountNumber,
      cancelled_cheque_url: kycFormState.cancelledChequeFile,
      bank_statement_url: kycFormState.bankStatementFile,
      kyc_verification_status:
        kycStatus === "save" ? "NOT_SUBMIT" : "IN_VERIFICATION",
      userId: userId,
      name: kycFormState.name.toUpperCase(),
      msme_registration_number:
        kycFormState?.msmeRegistrationNumber?.toUpperCase(),
      msme_registration_doc: kycFormState.msmeDoc,
    }
    try {
      const response = kycData?.id
        ? await axiosInstance.patch(
            `distributor-kyc/${kycData?.id}`,
            distributorKycData
          )
        : await axiosInstance.post(`distributor-kyc`, distributorKycData)
      if (response) {
        getKycData()
        toast.success("Successfully Updated")
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem={
              kycData?.kyc_verification_status
                ? `My KYC (${statusEnum(kycData?.kyc_verification_status)})`
                : "My KYC"
            }
            breadcrumbRight={"My KYC"}
            color={
              kycData?.kyc_verification_status == "VERIFIED" ? "green" : ""
            }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={() => handleValidOrderSubmit("save")}>
                    <Row form>
                      <Col className="row">
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>PAN Type:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.panType}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  name="panType"
                                  label="Pan Type"
                                  type="select"
                                  className="form-select"
                                  errorMessage="Invalid Input"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={kycFormState.panType || ""}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                >
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Person">Person</option>
                                  <option value="Firm/ Limited Liability Partnership">
                                    Limited Liability Partnership
                                  </option>
                                  <option value="Sole Proprietor">
                                    Sole Proprietor
                                  </option>
                                  <option value="Partnership Firm">
                                    Partnership Firm
                                  </option>
                                  <option value="Company">Company</option>
                                </AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Legal Entity Name:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.name}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="legalEntityName"
                                  label="Enter Legal Entity Name"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.name || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>PAN Number:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.panNumber}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="panNumber"
                                  label="Enter your PAN"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.panNumber || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  maxLength="10"
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>PAN Card:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.panFile ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(kycFormState.panFile)
                                        }
                                      >
                                        {kycFormState.panFile.split("/").pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  validate={{
                                    required: {
                                      value: kycFormState.panFile
                                        ? false
                                        : true,
                                    },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="panFile"
                                  label="Upload PAN Card"
                                ></AvField>
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.panFile ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState.panFile.split("/").pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "90px" }
                              : { height: "100px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Registered Address:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState?.registeredAddress || "NA"}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  name="registeredAddress"
                                  label="Enter Registered Address"
                                  type="textarea"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState?.registeredAddress || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                  style={{ maxHeight: "56px" }}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        {kycFormState?.panType ==
                          "Firm/ Limited Liability Partnership" ||
                        kycFormState?.panType == "Sole Proprietor" ||
                        kycFormState?.panType == "Partnership Firm" ||
                        kycFormState?.panType == "Company" ? (
                          <div
                            style={
                              kycDisabled
                                ? { height: "70px" }
                                : { height: "95px" }
                            }
                            className="col-md-6"
                          >
                            <div>
                              {kycDisabled ? (
                                <>
                                  <div>Authorized Signatory:</div>
                                  <p
                                    className="fw-medium font-size-15"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    {kycFormState.authorizedSignatory}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <AvField
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                    name="authorizedSignatory"
                                    label="Enter Authorized Signatory Name"
                                    type="text"
                                    errorMessage="Enter a valid input"
                                    value={
                                      kycFormState.authorizedSignatory || ""
                                    }
                                    validate={{
                                      required: { value: true },
                                    }}
                                    disabled={kycDisabled}
                                    onChange={handleOnChange}
                                  ></AvField>
                                </>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Aadhar Number:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState?.aadharNumber || "NA"}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  name="aadharNumber"
                                  label="Enter Aadhar Number (if applicable)"
                                  type="number"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState?.aadharNumber || ""}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>Aadhar Card:</div>{" "}
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.aadharFile ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(kycFormState?.aadharFile)
                                        }
                                      >
                                        {kycFormState?.aadharFile
                                          .split("/")
                                          .pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="aadharFile"
                                  label="Upload Aadhar Card"
                                ></AvField>
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.aadharFile ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState?.aadharFile
                                        .split("/")
                                        .pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>GST Number:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState?.gstNumber || "NA"}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="gstNumber"
                                  label="Enter your GST Number (if applicable)"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState?.gstNumber || ""}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>GST Certificate:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.gstCertificateFile ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(
                                            kycFormState?.gstCertificateFile
                                          )
                                        }
                                      >
                                        {kycFormState?.gstCertificateFile
                                          .split("/")
                                          .pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="gstCertificateFile"
                                  label="Upload GST Certificate"
                                ></AvField>{" "}
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.gstCertificateFile ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState?.gstCertificateFile
                                        .split("/")
                                        .pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>MSME Registration Number:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState?.msmeRegistrationNumber || "NA"}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="msmeRegistrationNumber"
                                  label="Enter MSME Registration Number (if applicable)"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={
                                    kycFormState?.msmeRegistrationNumber || ""
                                  }
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>MSME Registration Document:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.msmeDoc ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(kycFormState?.msmeDoc)
                                        }
                                      >
                                        {kycFormState?.msmeDoc.split("/").pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="msmeDoc"
                                  label="Upload MSME Registration Document"
                                ></AvField>{" "}
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState?.msmeDoc ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState?.msmeDoc.split("/").pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Bank Name:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.bankName}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="bankName"
                                  label="Enter Bank Name"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.bankName || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Branch Name:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.branchName}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="branchName"
                                  label="Enter Branch Name"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.branchName || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Beneficiary Name:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.accountName}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="accountName"
                                  label="Enter Beneficiary Name"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.accountName || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>Bank Statement:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.bankStatementFile ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(
                                            kycFormState.bankStatementFile
                                          )
                                        }
                                      >
                                        {kycFormState.bankStatementFile
                                          .split("/")
                                          .pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="bankStatementFile"
                                  label="Upload Bank Statement"
                                ></AvField>
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.bankStatementFile ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState.bankStatementFile
                                        .split("/")
                                        .pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>Account Number:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.accountNumber}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  name="accountNumber"
                                  label="Enter Account Number"
                                  type="number"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.accountNumber || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {loading && <Loader />}
                            {kycDisabled ? (
                              <>
                                <div>Cancelled Cheque:</div>{" "}
                                <p
                                  className="fw-medium font-size-15"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.cancelledChequeFile ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() =>
                                          handleDoc(
                                            kycFormState.cancelledChequeFile
                                          )
                                        }
                                      >
                                        {kycFormState.cancelledChequeFile
                                          .split("/")
                                          .pop()}
                                      </Link>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  errorMessage="Please Enter valid input"
                                  disabled={kycDisabled}
                                  onChange={handleFileUpload}
                                  name="cancelledChequeFile"
                                  label="Upload Cancelled Cheque"
                                ></AvField>
                                <p
                                  className="p-1 text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {kycFormState.cancelledChequeFile ? (
                                    <>
                                      File uploaded:{" "}
                                      {kycFormState.cancelledChequeFile
                                        .split("/")
                                        .pop()}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={
                            kycDisabled
                              ? { height: "70px" }
                              : { height: "95px" }
                          }
                          className="col-md-6"
                        >
                          <div>
                            {kycDisabled ? (
                              <>
                                <div>IFSC Code:</div>
                                <p
                                  className="fw-medium font-size-15"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  {kycFormState.ifscCode}
                                </p>
                              </>
                            ) : (
                              <>
                                <AvField
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                  name="ifscCode"
                                  label="Enter IFSC Code"
                                  type="text"
                                  errorMessage="Enter a valid input"
                                  value={kycFormState.ifscCode || ""}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  disabled={kycDisabled}
                                  onChange={handleOnChange}
                                ></AvField>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {!kycDisabled && (
                        <Col className="d-flex gap-3 justify-content-end">
                          <div className={"text-center mt-3"}>
                            <button
                              type="submit"
                              className="btn btn-primary save-user"
                              disabled={loading || kycDisabled ? true : false}
                            >
                              SAVE
                            </button>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              type="button"
                              onClick={() =>
                                isSaved
                                  ? handleValidOrderSubmit("")
                                  : toast.warn("Please Save First!")
                              }
                              className="btn btn-success save-user"
                              disabled={loading || kycDisabled ? true : false}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Kyc
