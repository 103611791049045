import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import nodata from "../../../assets/images/nodata/no-data.svg"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData } from "constants/common"

const XirrReport = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])
  const [userFilter, setUserFilter] = useState("")
  const [downloadErrorModal, setDownloadErrorModal] = useState(false)

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }`

  const masterData = async () => {
    if (investmentFilter?.id) {
      const getUrl = `xirr-reports?$limit=${limit}&$skip=${skip}${filterUrl}`
      try {
        setLoading(true)

        const response = await axiosInstance.get(getUrl)

        if (response) {
          setTotal(response.data.total)
          setData(response.data?.data || response.data)
          let pages = Math.ceil(
            (response.data?.total || response.total) /
              (response.data?.limit || response.limit)
          )
          setTotalPage(pages)
        }
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    }
  }
  const investmentData = async () => {
    try {
      setLoading(true)
      const investmentGetUrl = `investment?$sort[created_at]=-1&status=Exited`

      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    investmentData()
  }, [])
  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.ASSET_MANAGEMENT)

  const TableColumns = () => [
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "user_name",
      text: "Investor Name",
      sort: true,
    },
    {
      dataField: "xirr",
      text: "IRR",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.xirr}</div>
      ),
    },
    {
      dataField: "lot_size",
      text: "Bonds",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.lot_size}</div>
      ),
    },
    {
      dataField: "amount",
      text: "Invested Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(cellContent)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setUserFilter("")
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const downloadData = async () => {
    try {
      setLoading(true)
      const getUrl = `xirr-reports?$sort[created_at]=-1${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      const resData = response.data.data

      let csvTableHeaders = TableColumns()
      var str = JSON.stringify(resData)
      const arr = JSON.parse(str)
      arr.forEach(item => {
        item.created_at = moment(item.created_at).format("DD/MM/YYYY")
        item.amount = handleAmount(item.amount)
      })
      const downloadableArr = csvDownloadData(csvTableHeaders, arr)

      const name = "Earnnest " + investmentFilter.project_name + " IRR reports"
      JSONToCSVConvertor(downloadableArr, name, true)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="IRR Report" />
          <Row>
            <Col xs="12">
              <Row className="mb-3 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                {investmentFilter?.id ? (
                  <div className="col-auto">
                    {(accessRestriction == "4" ||
                      accessRestriction === "SuperAdmin") && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => {
                          downloadData()
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download
                      </Button>
                    )}
                  </div>
                ) : null}
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={TableColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Select Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          {investmentFilter?.id ? (
                            <Col md={3}>
                              <div className="col-auto">
                                <label>Search User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search Name, Email, Phone`}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        {!investmentFilter?.id ? (
                          <Row>
                            <Col lg="12">
                              <div className="text-center">
                                <Row className="justify-content-center">
                                  <Col sm="4">
                                    <div className="maintenance-img">
                                      <img
                                        src={nodata}
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <h4 className="mt-5">
                                  Please select a Project
                                </h4>
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                        {investmentFilter?.id ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  columns={TableColumns()}
                                  data={data}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                            <Row className="justify-content-md-space-between justify-content-center align-items-center">
                              <Col className="col-12 col-md-auto mb-3">
                                {`Showing ${total ? skip + 1 + " to" : ""} ${
                                  limit > total || limit + skip > total
                                    ? total
                                    : limit + skip
                                } rows of ${total}
                    `}
                              </Col>
                              <Col>
                                <Row className="justify-content-md-end justify-content-center align-items-center">
                                  <Col className="col-md-auto">
                                    <div className="d-flex gap-1">
                                      <Button
                                        color="primary"
                                        onClick={() => handlePrevious(limit)}
                                        disabled={currentPage == 1}
                                      >
                                        {"<<"}
                                      </Button>
                                      <Button
                                        color="primary"
                                        onClick={() => handlePrevious(skip)}
                                        disabled={currentPage == 1}
                                      >
                                        {"<"}
                                      </Button>
                                    </div>
                                  </Col>
                                  <Col className="col-md-auto d-none d-md-block">
                                    Page{" "}
                                    <strong>{`${
                                      currentPage ? currentPage : 1
                                    } of ${totalPage ? totalPage : 1}`}</strong>
                                  </Col>
                                  <Col className="col-md-auto">
                                    <Input
                                      type="number"
                                      min={1}
                                      style={{ width: 70 }}
                                      max={total == 0 ? 1 : totalPage}
                                      value={currentPage || 1}
                                      defaultValue={1}
                                      onChange={onChangePagination}
                                      disabled={total == 0}
                                    />
                                  </Col>

                                  <Col className="col-md-auto">
                                    <div className="d-flex gap-1">
                                      <Button
                                        color="primary"
                                        onClick={() => handleNext(skip)}
                                        disabled={
                                          currentPage == totalPage || total == 0
                                        }
                                      >
                                        {">"}
                                      </Button>
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          handleNext((totalPage - 2) * limit)
                                        }
                                        disabled={
                                          currentPage == totalPage || total == 0
                                        }
                                      >
                                        {">>"}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={downloadErrorModal} toggle={toggleDownloadErrorModal}>
            <ModalHeader toggle={toggleDownloadErrorModal} tag="h4">
              Invalid Request!
            </ModalHeader>
            <ModalBody>
              <Row form>
                Download requested for more than 500 entries. Please select a
                filter before proceeding.
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-success save-user"
                      onClick={toggleDownloadErrorModal}
                    >
                      OK
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(XirrReport)
