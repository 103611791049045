import { Switch } from "react-router-dom"

export const DealManagementAccess = id => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const accessInfo = userInfo?.role?.permision
  if (accessInfo?.length) {
    const DealRights = accessInfo?.find(item => item?.id == id)
    switch (DealRights?.role_permision?.acl) {
      case "none":
        return "0"
      case "read":
        return "1"
      case "read,update":
        return "2"
      case "read,update,create":
        return "3"
      case "read,update,create,delete":
        return "4"
      default:
        return "0"
    }
  } else if (!accessInfo?.length && userInfo?.permissions === "Admin")
    return "SuperAdmin"
}
