import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { getChartColorsArray } from "constants/common"
import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import nodata from "../../assets/images/nodata/no-data.svg"
import { csvDownloadData, enumSelector, picUrl } from "constants/common"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import ReactEcharts from "echarts-for-react"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [investmentSummary, setInvestmentSummary] = useState([])
  const [distWiseSummary, setDistWiseSummary] = useState([])
  const [document, setDocument] = useState([])
  const [invSummaryType, setInvSummaryType] = useState("")
  const [chartData, setChartData] = useState([])
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const investmentIdFromUrl = queryParams.get("investmentId")
  const [projectData, setProjectData] = useState({})

  const PieEChartColors = getChartColorsArray(
    '["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'
  )

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment?$sort[created_at]=-1&status[$ne]=Draft`
      )
      if (response) {
        if (investmentIdFromUrl) {
          setSelectedProject(
            response.data?.data.find(item => item.id === investmentIdFromUrl)
          )
        }
        setProjects(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleInvestmentChange = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/admin/user-investment-report?investmentId=${selectedProject.id}`
      )
      const direct = await axiosInstance.get(
        `/admin/user-investment-report?investmentId=${selectedProject.id}&distributor=false`
      )
      const distributor = await axiosInstance.get(
        `/admin/user-investment-report?investmentId=${selectedProject.id}&distributor=true`
      )

      if (direct && distributor) {
        const direct_commitment_amount = direct.data?.data[5]?.commitment_amount
        const dist_commitment_amount =
          distributor.data?.data[5]?.commitment_amount
        setChartData([
          {
            value: direct_commitment_amount,
            name: `Direct`,
          },
          {
            value: dist_commitment_amount,
            name: `Distributor`,
          },
        ])
      }

      const distSummaryRes = await axiosInstance.get(
        `/admin/distributor-report?investmentId=${selectedProject.id}`
      )
      const documentRes = await axiosInstance.get(
        `document?investmentId=${selectedProject.id}`
      )
      if (distSummaryRes) {
        setDistWiseSummary(distSummaryRes.data?.data)
      }
      if (documentRes) {
        setDocument(
          documentRes.data?.filter(
            item => item.document_type.classification === "Deal"
          )
        )
      }
      if (response) {
        setInvSummaryType("")
        setInvestmentSummary(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleChange = async e => {
    const value = e.target.value
    if (value == "false") {
      setInvSummaryType("Direct")
    } else if (value == "true") {
      setInvSummaryType("Distributor")
    } else {
      setInvSummaryType("")
    }
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/admin/user-investment-report?investmentId=${selectedProject.id}${
          value ? "&distributor=" + value : ""
        }`
      )
      if (response) {
        setInvestmentSummary(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = Math.round(amount)
    return `₹ ${dollarIndianLocale.format(amountInRupees)} `
  }

  const handlePaisaAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount / 100))
    return `₹ ${dollarIndianLocale.format(amountInRupees)} `
  }

  const handleDoc = async file => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return (
          params.seriesName +
          "<br/>" +
          params.name +
          " : " +
          handleAmount(params.value)
        )
      },
    },
    legend: {
      orient: "horizontal",
      left: "center",
      top: 40,
      data: chartData.map(obj => obj.name),
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Commitment",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: chartData,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: function (params) {
            return handleAmount(params.value)
          },
        },
      },
    ],
  }

  useEffect(() => {
    masterData()
  }, [])

  useEffect(() => {
    if (selectedProject?.id) {
      history.replace({
        search: "?investmentId=" + selectedProject.id,
      })
      handleInvestmentChange()
      const projectData = {
        imageUrl: selectedProject?.display_pic_url,

        projectName: selectedProject?.project_name,
        issuerName: selectedProject?.registered_issuer_name,

        cityLabel: "City",
        city: selectedProject?.location,

        isinLabel: "ISIN ",
        isinLinkText: "(NSDL Link)",
        isin: selectedProject?.isin_number,

        faceValueLabel: "Face Value",
        faceValue: selectedProject?.face_value,

        bondsLabel: "Total Bonds",
        bonds: selectedProject?.total_bonds,

        dateLabel: "Expected Maturity",
        date: selectedProject?.maturity_date,

        issueSizeLabel: "Issue Size",
        issueSize: selectedProject?.issue_size,
      }
      setProjectData(projectData)
    }
  }, [selectedProject])

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const InvestmentSummaryColumn = () => [
    {
      dataField: "status",
      text: "Investment Stage",
      sort: true,
      formatter: (cellContent, row) => enumSelector(row.status),
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.count}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "lot_size",
      text: "Lot size",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.lot_size}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "commitment_amount",
      text: "Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.commitment_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "amount_paid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handlePaisaAmount(row.amount_paid)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const DistWiseSummaryColumn = () => [
    {
      dataField: "name",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Distributor Email",
      sort: true,
      formatter: (cellContent, row) => row.email || "NA",
    },
    {
      dataField: "phone",
      text: "Distributor Phone",
      sort: true,
      formatter: (cellContent, row) => row.phone || "NA",
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.count}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    // {
    //   dataField: "lot_size",
    //   text: "Lot size",
    //   sort: true,
    // },
    {
      dataField: "commitment_amount",
      text: "Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.commitment_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    // {
    //   dataField: "amount_paid",
    //   text: "Amount Paid",
    //   sort: true,
    //   formatter: (cellContent, row) => handlePaisaAmount(row.amount_paid),
    // },
  ]

  const downloadData = (data, name, csvTableHeaders) => {
    csvTableHeaders = csvTableHeaders()
    const downloadableArr = csvDownloadData(csvTableHeaders, data)
    JSONToCSVConvertor(downloadableArr, name, true)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const accessRestriction = DealManagementAccess(AccessId?.DASHBOARD)
  console.log({ accessRestriction })

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <h4>Deal Dashboard</h4>
          <hr />
          <React.Fragment>
            <Col xl="12" className="p-2 h-100">
              {projects.length ? (
                <Row className="mb-2">
                  <div className="col-md-4">
                    <label className="card-title">Select Project Name</label>
                    <ReactSelect
                      users={projects}
                      multiOptionLabel={true}
                      optionLabelKeys={["project_name"]}
                      selectedOption={selectedProject}
                      setSelectedOption={setSelectedProject}
                    />
                  </div>
                </Row>
              ) : null}
              {!selectedProject && !loading ? (
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col sm="4">
                          <div className="maintenance-img">
                            <img
                              src={nodata}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                      <h4 className="mt-5">Please select a Project</h4>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {selectedProject ? (
                <>
                  <Row className="mt-4">
                    <Col lg="12">
                      <ProjectDetailCard
                        imageUrl={projectData?.imageUrl}
                        projectName={projectData?.projectName}
                        issuerName={projectData?.issuerName}
                        cityLabel={projectData?.cityLabel}
                        city={projectData?.city}
                        isinLabel={projectData?.isinLabel}
                        isinLinkText={projectData?.isinLinkText}
                        isin={projectData?.isin}
                        faceValueLabel={projectData?.faceValueLabel}
                        faceValue={projectData?.faceValue}
                        bondsLabel={projectData?.bondsLabel}
                        bonds={projectData?.bonds}
                        dateLabel={projectData?.dateLabel}
                        date={projectData?.date}
                        issueSizeLabel={projectData?.issueSizeLabel}
                        issueSize={projectData?.issueSize}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="8">
                      <Card>
                        <CardBody style={{ minHeight: "400px" }}>
                          <ToolkitProvider
                            keyField="id"
                            data={investmentSummary}
                            columns={InvestmentSummaryColumn()}
                            bootstrap4
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <CardTitle className="mb-3 mt-1">
                                  <Row className="row justify-content-between">
                                    <div className="col">
                                      Investment summary
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                      <div className="me-3">
                                        <select
                                          className="form-select form-select-md"
                                          style={{ width: "140px" }}
                                          onChange={e => {
                                            handleChange(e)
                                          }}
                                          values={invSummaryType}
                                        >
                                          <option
                                            selected={invSummaryType === ""}
                                            value=""
                                          >
                                            All
                                          </option>
                                          <option value="false">Direct</option>
                                          <option value="true">
                                            Distributor
                                          </option>
                                        </select>
                                      </div>
                                      {(accessRestriction >= 1 ||
                                        accessRestriction === "SuperAdmin") && (
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-rounded"
                                            onClick={() => {
                                              downloadData(
                                                investmentSummary.map(obj => ({
                                                  ...obj,
                                                  status: enumSelector(
                                                    obj.status
                                                  ),
                                                  amount_paid: Math.abs(
                                                    obj.amount_paid / 100
                                                  ),
                                                })),
                                                `${
                                                  invSummaryType
                                                    ? invSummaryType + "-"
                                                    : ""
                                                }Investment-Summary`,
                                                InvestmentSummaryColumn
                                              )
                                            }}
                                          >
                                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                            Download
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </Row>
                                </CardTitle>
                                <Row>
                                  <Col xl="12">
                                    <BootstrapTable
                                      keyField="id"
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      columns={InvestmentSummaryColumn()}
                                      data={investmentSummary}
                                      wrapperClasses={"table-responsive mb-4"}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="4">
                      <Card>
                        <CardBody style={{ height: "445px" }}>
                          <CardTitle>Completed Investments</CardTitle>
                          <ReactEcharts
                            className="mt-n2"
                            style={{ height: "350px" }}
                            option={options}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="8">
                      <Card>
                        <CardBody style={{ height: "480px" }}>
                          <ToolkitProvider
                            keyField="id"
                            data={distWiseSummary}
                            columns={DistWiseSummaryColumn()}
                            bootstrap4
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-4 mt-2 row justify-content-between">
                                  <div className="col">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <CardTitle>
                                        Distributor Wise Summary
                                      </CardTitle>
                                    </div>
                                  </div>
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div className="col-auto">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={() => {
                                          downloadData(
                                            distWiseSummary,
                                            "Distrbutor-Wise-Summary",
                                            DistWiseSummaryColumn
                                          )
                                        }}
                                        style={{ marginRight: "3px" }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle me-1" />{" "}
                                        Download
                                      </Button>
                                    </div>
                                  )}
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <BootstrapTable
                                      keyField="id"
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      columns={DistWiseSummaryColumn()}
                                      data={distWiseSummary}
                                      wrapperClasses={
                                        "table-responsive body-scrollable"
                                      }
                                      classes={
                                        "table align-middle table-nowrap table-check sticky-header"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="4">
                      <Card>
                        <CardBody style={{ height: "480px" }}>
                          <CardTitle className="mb-4">
                            Project Level Documents
                          </CardTitle>
                          {document.length > 0 ? (
                            <SimpleBar style={{ maxHeight: "380px" }}>
                              <div className="table-responsive">
                                <Table className="table-nowrap align-middle table-hover mb-0">
                                  <tbody>
                                    {document.map((file, i) => (
                                      <tr key={"_file_" + i}>
                                        <td width="10%">
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              handleDoc(file.url)
                                            }}
                                            className="text-dark"
                                          >
                                            <i className="bx bx-download h3 m-0" />
                                          </Link>
                                        </td>
                                        <td>
                                          <h5 className="font-size-14 mb-1">
                                            <Link to="#" className="text-dark">
                                              {file.document_name}
                                            </Link>
                                          </h5>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </SimpleBar>
                          ) : (
                            <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center gap-2 mt-n5">
                              <i
                                className="mdi mdi-file-document-multiple-outline"
                                style={{ fontSize: "100px" }}
                              />
                              <h5>No Documents found</h5>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
