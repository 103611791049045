import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessId,
  paymentStatusForTransactions,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { csvDownloadData, usersData } from "constants/common"

const TopUpTcsTransactions = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [userInvestment, setUserInvestment] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})

  const [investmentList, setInvestmentList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [paymentStatusFilter, setPaymentStatusFilter] = useState({})

  const [users, setUsers] = useState([])
  const [searchValue, setSearchValue] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${
    paymentStatusFilter?.id ? `&payment_status=${paymentStatusFilter?.id}` : ""
  }`

  const getUrl = `admin-tcs-transactions-listing?topup=true&$limit=${limit}&$skip=${skip}&$sort[updated_at]=-1${filterUrl}`

  const investmentGetUrl = `investment?$sort[created_at]=-1`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)

      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse.data?.data || invResponse.data)
      }
      if (response) {
        setTotal(response.data.total)
        setData(response?.data?.data || response?.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }
  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        if (usersRes) {
          const users = usersRes?.data?.data || usersRes?.data
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  useEffect(async () => {
    setLoading(true)
    if (selectedUser?.id) {
      try {
        setSelectedOption()
        const userInvestmentGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&topup=true&userId=${selectedUser.id}`
        const userInvestmentData = await axiosInstance.get(userInvestmentGetUrl)
        if (userInvestmentData) {
          const inv = userInvestmentData?.data?.data || userInvestmentData?.data
          setUserInvestment(inv)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    setLoading(false)
  }, [selectedUser])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "paymentStatus":
        setPaymentStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, investmentFilter, paymentStatusFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const TransactionColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link to="#" className="text-success">
                <Button
                  disabled={
                    order.payment_type != "Online" &&
                    order.payment_status == "initiated"
                      ? false
                      : true
                  }
                  type="button"
                  color="primary"
                  className="btn"
                  onClick={() => handleOrderClick(order)}
                >
                  Update
                </Button>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },

    {
      dataField: "transaction_amount",
      text: "Amount (In Rs)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.transaction_amount),
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
      sort: true,
    },

    {
      dataField: "userInvestmentTopupId",
      text: "User Investment Topup Id",
      sort: true,
    },
    {
      dataField: "dump_id",
      text: "Transaction Dump Id",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  const handleOrderClick = arg => {
    const order = arg
    setOrderList({
      id: order.id,
    })
    setIsEdit(true)
    toggle()
  }

  const handleOrderClicks = async () => {
    setSelectedOption()
    setSearchValue()
    setSelectedUser()
    setUsers([])
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        payment_status: values.payment_status,
      }
      try {
        const response = await axiosInstance.patch(
          `tcs-transaction-topup/${orderList.id}`,
          updateOrder
        )
        if (response) {
          toast.success("Successfully Updated")
          masterData()
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    } else {
      if (!selectedOption.id) {
        toast.error("Please select a user investment")
        setLoading(false)
        return false
      }
      const newOrder = {
        userInvestmentTopupId: selectedOption?.id,
        userId: selectedOption?.userId,
        transaction_amount: values.transaction_amount,
        payment_status: values.payment_status,
        payment_type: values.payment_type,
        topup_status: "UNMERGED",
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `tcs-transaction-topup`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success("Transaction Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const url = `admin-tcs-transactions-listing?topup=true&$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = TransactionColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          const AmountInRS = item?.transaction_amount / 100
          item["transaction_amount"] = AmountInRS
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Topup TCS Transactions" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleOrderClicks}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => downloadData("Topup TCS Transaction")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={TransactionColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-start">
                          <Col md={3}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone `}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Payment Status</label>
                              <ReactSelect
                                users={paymentStatusForTransactions}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "paymentStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          {/* <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={TransactionColumns(toggle)}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEdit
                                  ? "Update Payment Status"
                                  : "Add Topup TCS Transaction"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      {isEdit ? (
                                        <>
                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Status"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                            </AvField>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="mb-3">
                                            <label>User</label>
                                            <ReactSelect
                                              users={users}
                                              searchValue={searchValue}
                                              setSearchValue={handleUserSearch}
                                              selectedOption={selectedUser}
                                              setSelectedOption={
                                                setSelectedUser
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label>User Investment Info</label>
                                            <ReactSelect
                                              isDisabled={!selectedUser?.id}
                                              users={userInvestment}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              selectedOption={selectedOption}
                                              transaction={true}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_amount"
                                              label="Transaction Amount (In Paisa)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.transaction_amount ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="payment_type"
                                              label="Payment Type"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="Bank_Transfer"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Online">
                                                Online
                                              </option>
                                              <option value="Bank_Transfer">
                                                Bank Transfer
                                              </option>
                                            </AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="initiated"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="initiated">
                                                Initiated
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                            </AvField>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}`}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TopUpTcsTransactions)
