import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import EditorToolbar, {
  modules,
  formats,
} from "../SponserOtherProjects/EditorToolbar"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import ReactHtmlParser from "react-html-parser"
import EditorTool, { moduless, formatss } from "./EditorTool"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { investmentData, Pagination } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { sortingByAlphabet } from "constants/sort"

const InvestmentRisk = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [state, setState] = useState({ value: null })
  const [detail, setDetail] = useState({ value: null })
  const [loading, setLoading] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-risk?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
  const RiskColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction >= 4 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },

    {
      dataField: "item",
      text: "Item",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => ReactHtmlParser(order.item),
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setState({ value: null })
    setDetail({ value: null })
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      item: order.item,
      disp_order: order.disp_order,
      details: order.details,
      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-risk/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
          setLoading(false)
        }
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        item: detail.value || orderList.item,
        disp_order: values.disp_order,
        details: state.value || orderList.details,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-risk/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values["project_name"],
        item: detail.value,
        disp_order: values["disp_order"],
        details: state.value,
        created_at: values["created_at"],
      }
      // save new order

      try {
        const response = await axiosInstance.post(`investment-risk`, newOrder)
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          masterData()
          toast.success(" Risk Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setState({ value: null })
    setDetail({ value: null })
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await investmentData()
      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  const handleChange = value => {
    setState({ value })
  }

  const handleItemChange = value => {
    setDetail({ value })
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Investment Risks" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={RiskColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={RiskColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit
                                  ? "Edit Investment Risk"
                                  : "Add Investment Risk"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="project_name"
                                          label="Project Name"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid Project Name"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.project_name || ""}
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          {sortingByAlphabet(sponser).map(
                                            (item, index) => (
                                              <option
                                                key={item.project_name}
                                                value={item.id}
                                              >
                                                {item.project_name}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="disp_order"
                                          label="Disp Order"
                                          type="number"
                                          errorMessage="Invalid Disp Order"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.disp_order || ""}
                                        />
                                      </div>
                                      <div
                                        className="mb-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Item
                                      </div>
                                      <div
                                        className="text-editor"
                                        style={{
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <EditorTool />
                                        <ReactQuill
                                          theme="snow"
                                          value={
                                            detail?.value ||
                                            orderList?.item ||
                                            " "
                                          }
                                          onChange={handleItemChange}
                                          placeholder={"Write item here..."}
                                          modules={moduless}
                                          formats={formatss}
                                        />
                                      </div>
                                      <div
                                        className="mb-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Details
                                      </div>
                                      <div
                                        className="text-editor"
                                        style={{
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <EditorToolbar />
                                        <ReactQuill
                                          theme="snow"
                                          value={
                                            state?.value ||
                                            orderList?.details ||
                                            " "
                                          }
                                          onChange={handleChange}
                                          placeholder={"Write details here..."}
                                          modules={modules}
                                          formats={formats}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvestmentRisk.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentRisk)
