import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/favicon-new.png"
import longLogo from "../../assets/images/earnnest-me-logo-removebg.png"
import { serverBaseUrl } from "ConfigAxioxinstance"
import { getImgUrl, humanize, picUrl } from "constants/common"
import Loader from "common/Loader"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
const ChangePasswordPage = props => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const sessionUser = sessionStorage.getItem("userInfo")
  const sessionUserData = sessionUser ? JSON.parse(sessionUser) : null

  async function handleValidSubmit(event, values) {
    try {
      setLoading(true)
      if (values.password != values.confirm_password) {
        setLoading(false)
        toast.error("Passwords do not match")
        return
      }

      const token = sessionStorage.getItem("user_token")
      if (!token) {
        history.push("/login")
      }
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": serverBaseUrl,
        Authorization: "Bearer " + token,
      }

      const userId = sessionStorage.getItem("userId")
      const payload = { userId: userId, password: values.password }
      const response = await fetch(`${serverBaseUrl}/change-password`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw await response.json()
      }

      const res = await response.json()

      if (res) {
        toast.success("Password Changed Successfully")
        const logoutRes = await fetch(`${serverBaseUrl}/authentication`, {
          method: "DELETE",
          headers: headers,
        })
        if (logoutRes) {
          sessionStorage.clear()
          localStorage.clear()
          history.push("/login")
        }
      }
    } catch (error) {
      console.error({ error })
      const msg = humanize(error?.response?.data?.message) || error?.message
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!sessionStorage.getItem("user_token")) {
      history.push("/login")
    }
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <MetaTags>
        <title>Forget Password | Earnnest-Admin</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-4 pt-sm-5">
        <Container>
          {/* <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block">
                  <img src={longLogo} alt="" height="28" className="mx-auto" />
                </Link>
                <p className=" font-size-15 mt-2">Create a new password</p>
              </div>
            </Col>
          </Row> */}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* <div className="bg-primary bg-soft bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password !</h5>
                        <p>Create a new password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                <CardBody className="p-4">
                  <Row className="d-flex justify-content-between align-items-start mt-2 mb-0">
                    <div className="col my-n2">
                      <h4 className="font-size-20 mb-1">Reset Password!</h4>
                      <p className="font-size-13 mt-0 text-muted">
                        Create a new password
                      </p>
                    </div>
                    <div className="col text-end text-muted">
                      <Link to="/">
                        <img src={logo} alt="" height="40" />
                      </Link>
                      {/* <p className="mt-3 mb-0">Create a new password</p> */}
                    </div>
                  </Row>
                  <hr />
                  <div className="my-2 w-100 d-flex">
                    {/* <img
                      src={avatar}
                      className="rounded-circle img-thumbnail avatar-md"
                      alt="thumbnail"
                    /> */}
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        {sessionUserData?.profile_picture ? (
                          <img
                            className={
                              "avatar-title custom-img-thumbnail rounded-circle"
                            }
                            src={getImgUrl(
                              picUrl,
                              sessionUserData.profile_picture
                            )}
                            style={{
                              maxWidth: "56px",
                              maxHeight: "56px",
                            }}
                          />
                        ) : (
                          <i
                            className="avatar-title custom-img-thumbnail bg-secondary rounded-circle bx bx-user text-white h1"
                            style={{ width: "56px", aspectRatio: "1/1" }}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <h5 className="font-size-15 mt-1 mb-0">
                          {sessionUserData?.user_name}
                        </h5>
                        <p className="font-size-13 text-muted mt-0">
                          {humanize(sessionUserData?.role?.department)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div>
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Enter New Password*"
                            className="form-control"
                            placeholder="Enter New Password"
                            type="password"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="confirm_password"
                            label="Confirm New Password*"
                            className="form-control"
                            placeholder="Confirm New Password"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <p>*Password should be 8-16 characters long.</p>
                        </div>
                      </div>
                      <Row>
                        <Col className="text-end">
                          <button
                            className="btn earnnest-green-bg w-md"
                            type="submit"
                          >
                            Change Password
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Earnnest</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ChangePasswordPage)
