import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import store from "./store"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./ErrorFallBack"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
