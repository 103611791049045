import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData, investmentData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import { sortingByAlphabet } from "constants/sort"

const DistributorAddendum = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [file, setFile] = useState(null)
  const [addendumFormState, setAddendumFormState] = useState({})
  const [selectedAddendum, setSelectedAddendum] = useState({})
  const [investments, setInvestments] = useState([])
  const [distributorAgreementId, setDistributorAgreementId] = useState()
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [selectedInvestment, setSelectedInvestment] = useState(null)
  const [uploadModal, setUploadModal] = useState(false)
  const [addendumDocUrl, setAddendumDocUrl] = useState("")

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-addendum?$sort[updated_at]=-1`
      )

      if (response) {
        setOrders(response.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    const usersListRes = await distributorUsers()
    const investmentRes = await investmentData("Approved", "Closed")

    const usersList = usersListRes?.data?.data || usersListRes?.data
    const investments = investmentRes?.data?.data || investmentRes?.data

    if (usersList && investments) {
      const distributors = usersList.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setInvestments(investments)
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  const toggleDeactivateModal = () => {
    setDeactivateModal(prev => !prev)
  }

  const handleDeactiveModalOpen = () => {
    setSelectedInvestment(null)
    toggleDeactivateModal()
  }
  const handleDeactiveAddendum = async () => {
    try {
      if (!selectedInvestment) {
        toast.error("select an investment")
        return
      }
      const payload = {
        investmentId: selectedInvestment.id,
      }
      setLoading(true)
      const res = await axiosInstance.post(`deactivate-bulk-addendum`, payload)
      if (res) {
        toast.success(res.data.message || "Addendum Deactivated Successfully")
        masterData()
        toggleDeactivateModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleActionClick = row => {
    setSelectedAddendum(row)
    setSelectedOption({ id: row.distributorId, ...row.distributor })
    toggleUploadModal()
  }

  const AddendumColumns = download => {
    let cols = []
    if (!download) {
      cols.push(
        {
          dataField: "action",
          isDummyField: true,
          text: "Edit",
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, order) => (
            <>
              <div className="d-flex gap-3">
                {accessRestriction >= 3 ||
                accessRestriction === "SuperAdmin" ? (
                  <Link
                    to="#"
                    className="text-success"
                    onClick={() => handleOrderClick(order)}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ) : (
                  "NA"
                )}
                {/* {accessRestriction === "SuperAdmin" ? (
              <Link
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )} */}
              </div>
            </>
          ),
        },
        {
          dataField: "",
          text: "Action",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            !row.is_addendum_signed ? (
              <Link to="#" className="text-primary">
                <Button
                  type="button"
                  color="primary"
                  outline
                  className="btn-md btn-rounded"
                  onClick={() => handleActionClick(row)}
                >
                  Upload
                </Button>
              </Link>
            ) : (
              "NA"
            ),
        }
      )
    }
    cols.push(
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      { dataField: "distributor.email", text: "Email", sort: true },
      {
        dataField: "is_addendum_signed",
        text: "Is Addendum Signed?",
        sort: true,
      },
      {
        dataField: "unsigned_doc_url",
        text: "Unsigned Addendum",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.unsigned_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.unsigned_doc_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "addendum_doc_url",
        text: "Signed Addendum",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.addendum_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.addendum_doc_url)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      { dataField: "addendum_no", text: "Addendum No.", sort: true },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: (cellContent, row) => (row.status ? "Active" : "Inactive"),
      },
      {
        dataField: "signed_on",
        text: "Signed On",
        sort: true,
        formatter: (cellContent, row) =>
          row?.signed_on ? handleValidDate(row?.signed_on) : "NA",
      },
      {
        dataField: "upfront_fee_percent",
        text: "Upfront Fee percent",
        sort: true,
      },
      {
        dataField: "upfront_fee_applicable_date",
        text: "Upfront Fee Applicable Date",
        sort: true,
        formatter: (cellContent, row) =>
          row?.upfront_fee_applicable_date
            ? handleValidDate(row?.upfront_fee_applicable_date)
            : "NA",
      },
      {
        dataField: "additional_fee_percent",
        text: "Trail Fee Percent",
        sort: true,
      },
      {
        dataField: "additional_fee_payment_frequency",
        text: "Trail Fee Payment Frequency",
        sort: true,
      },
      {
        dataField: "trail_fee_applicable_days",
        text: "Trail Fee Applicable Days",
        sort: true,
      },
      {
        dataField: "minimum_aggregate_face_value",
        text: "Minimum Business Volume",
        sort: true,
      },
      { dataField: "distributor.user_name", text: "User Name", sort: true },
      { dataField: "distributor.phone", text: "Phone", sort: true },
      {
        dataField: "signatory",
        text: "Authorised Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.signatory || "",
      },
      {
        dataField: "remarks",
        text: "Remarks",
        sort: true,
      },
      {
        dataField: "id",
        text: "Distributor Addendum Id",
        sort: true,
      }
    )

    return cols
  }

  const setAddendumUser = async e => {
    setLoading(true)
    try {
      const distributorAgreement = await axiosInstance.get(
        `/distributor-agreement?distributorId=${e.id}`
      )

      if (distributorAgreement) {
        const id = distributorAgreement?.data?.data[0]?.id
        if (id) {
          setDistributorAgreementId(id)
        } else {
          setDistributorAgreementId("null")
          toast.error("No Agreement found for this user")
        }
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
    setSelectedOption(e)
  }

  const handleOrderClick = selected => {
    setSelectedAddendum(selected)
    setSelectedOption({ id: selected.distributorId, ...selected.distributor })
    setAddendumFormState({
      upfrontFeePercent: selected?.upfront_fee_percent,
      upfrontFeeApplicableDate: selected?.upfront_fee_applicable_date,
      additionalFeePercent: selected?.additional_fee_percent,
      additionalFeePaymentFrequency: selected?.additional_fee_payment_frequency,
      minimumAggregateFaceValue: selected?.minimum_aggregate_face_value,
      signedOn: selected?.signed_on,
      isAddendumSigned: selected?.is_addendum_signed,
      distributorId: selected?.distributorId,
      investmentId: selected?.investmentId,
      distributorAgreementId: selected?.distributorAgreementId,
      trail_fee_applicable_days: selected?.trail_fee_applicable_days,
      status: selected?.status,
      remarks: selected?.remarks,
    })
    toggle()
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("select a user")
      return false
    }
    setLoading(true)
    const distributorAddendumData = {
      upfront_fee_percent: addendumFormState.upfrontFeePercent,
      upfront_fee_applicable_date: addendumFormState.upfrontFeeApplicableDate,
      additional_fee_percent: addendumFormState.additionalFeePercent,
      additional_fee_payment_frequency:
        addendumFormState.additionalFeePaymentFrequency || "NOT_APPLICABLE",
      minimum_aggregate_face_value: addendumFormState.minimumAggregateFaceValue,
      signed_on: addendumFormState.signedOn,
      is_addendum_signed: addendumFormState.isAddendumSigned,
      distributorId: selectedOption?.id,
      investmentId: addendumFormState.investmentId,
      distributorAgreementId:
        distributorAgreementId || addendumFormState.distributorAgreementId,
      trail_fee_applicable_days: addendumFormState.trail_fee_applicable_days,
      status: addendumFormState.status,
      remarks: addendumFormState.remarks,
    }
    try {
      const response = selectedAddendum?.id
        ? await axiosInstance.patch(
            `distributor-addendum/${selectedAddendum?.id}`,
            distributorAddendumData
          )
        : await axiosInstance.post(
            `distributor-addendum`,
            distributorAddendumData
          )
      if (response) {
        toast.success(
          `Addendum ${selectedAddendum?.id ? "edited" : "created"} successfully`
        )

        try {
          const pdfResponse = await axiosInstance.post(
            "/generate-distributor-pdf",
            {
              type: "addendum",
              distributorAddendumId: response?.data?.id,
            }
          )
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        } catch (error) {
          throw Error("Failed to generate PDF")
        } finally {
          setSelectedAddendum({})
          setSelectedOption({})
          masterData()
          toggle()
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
    } else {
      toast.error("something went wrong")
    }
    setLoading(false)
  }
  const toggle = () => {
    setModal(!modal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const handleUploadAddendum = async () => {
    const updateDoc = {
      addendum_doc_url: addendumDocUrl,
      is_addendum_signed: true,
    }
    try {
      const response = await axiosInstance.patch(
        `distributor-addendum/${selectedAddendum?.id}`,
        updateDoc
      )
      if (response) {
        toast.success("Successfully Updated")
        masterData()
        toggleUploadModal()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = AddendumColumns(true)
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    arr?.forEach(item => {
      item.legal_entity_name = item?.distributor?.distributor_kyc?.name || ""
      item.signatory = item?.distributor?.distributor_kyc?.signatory || ""
    })
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  // const handleDeleteOrder = async order => {
  //   setLoading(true)
  //   var r = confirm(`Are you sure want to delete this user`)
  //   if (r == true) {
  //     try {
  //       const response = await axiosInstance.delete(
  //         `distributor-addendum/${order.id}`
  //       )
  //       if (response) {
  //         masterData()
  //         toast.success("Successfully Deleted")
  //       }
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  //   }
  //   setLoading(false)
  // }

  const handleInitiateAgreement = async () => {
    setSelectedAddendum({})
    setLoading(true)
    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setSelectedOption({})
    setAddendumFormState({})
    toggle()
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "upfrontFeePercent":
        setAddendumFormState({
          ...addendumFormState,
          upfrontFeePercent: value < 0 ? Math.abs(value) : value,
        })
        break
      case "upfrontFeeApplicableDate":
        setAddendumFormState({
          ...addendumFormState,
          upfrontFeeApplicableDate: value,
        })
        break
      case "additionalFeePercent":
        setAddendumFormState({
          ...addendumFormState,
          additionalFeePercent: value < 0 ? Math.abs(value) : value,
        })
        break
      case "additionalFeePaymentFrequency":
        setAddendumFormState({
          ...addendumFormState,
          additionalFeePaymentFrequency: value,
        })
        break
      case "minimumAggregateFaceValue":
        setAddendumFormState({
          ...addendumFormState,
          minimumAggregateFaceValue: value < 0 ? Math.abs(value) : value,
        })
        break

      case "signedOn":
        setAddendumFormState({
          ...addendumFormState,
          signedOn: value,
        })
        break
      case "trail_fee_applicable_days":
        setAddendumFormState({
          ...addendumFormState,
          trail_fee_applicable_days: value,
        })
        break
      case "isAddendumSigned":
        setAddendumFormState({
          ...addendumFormState,
          isAddendumSigned: value,
        })
        break
      case "investmentId":
        setAddendumFormState({
          ...addendumFormState,
          investmentId: value,
        })
        break
      case "open_status":
        setAddendumFormState({
          ...addendumFormState,
          status: value,
        })
        break
      case "remarks":
        setAddendumFormState({
          ...addendumFormState,
          remarks: value,
        })
        break
      default:
        break
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAddendumDocUrl(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Addendum" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={AddendumColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <Col className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <div className="col-auto">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleInitiateAgreement}
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-plus me-1" /> Add
                              </Button>
                            )}
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2 me-2"
                                onClick={handleDeactiveModalOpen}
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-pencil me-1"></i>{" "}
                                Deactivate Addendum
                              </Button>
                            )}
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Addendum Data",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={AddendumColumns()}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={uploadModal}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Signed Addendum
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleUploadAddendum}>
                                  <Row form>
                                    <div className="row">
                                      <div className="mb-3">
                                        {loading && <Loader />}
                                        <AvField
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          onChange={handleFileUpload}
                                          name="addendumDocUrl"
                                          label="Upload Addendum Document"
                                        ></AvField>
                                        <p className="my-2">
                                          {addendumDocUrl
                                            ? `File uploaded: ${addendumDocUrl
                                                .split("/")
                                                .pop()}`
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-2"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={loading}
                                        >
                                          SUBMIT
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={deactivateModal}
                              toggle={toggleDeactivateModal}
                            >
                              <ModalHeader
                                toggle={toggleDeactivateModal}
                                tag="h4"
                              >
                                Bulk Deactivate Addendum
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleDeactiveAddendum}>
                                  <Row form>
                                    <div className="row">
                                      <div className="mb-3">
                                        <label>Project Name</label>
                                        <ReactSelect
                                          users={investments}
                                          setSelectedOption={
                                            setSelectedInvestment
                                          }
                                          selectedOption={selectedInvestment}
                                          multiOptionLabel={true}
                                          optionLabelKeys={["project_name"]}
                                          isClearable={true}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-3"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={loading}
                                        >
                                          SUBMIT
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              className={"modal-lg"}
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {selectedAddendum?.id
                                  ? "Edit Distributor Addendum"
                                  : "Distributor Addendum"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <label>User</label>
                                          {selectedOption.id ? (
                                            <ReactSelect
                                              users={users}
                                              selectedOption={{
                                                user_name:
                                                  selectedOption
                                                    ?.distributor_kyc?.name,
                                                email: selectedOption.email,
                                                phone: selectedOption.phone,
                                              }}
                                              isDisabled={
                                                selectedAddendum?.id
                                                  ? true
                                                  : false
                                              }
                                              setSelectedOption={
                                                setAddendumUser
                                              }
                                            />
                                          ) : (
                                            <ReactSelect
                                              users={users}
                                              setSelectedOption={
                                                setAddendumUser
                                              }
                                              isDisabled={
                                                selectedAddendum?.id
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="investmentId"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.investmentId ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId ===
                                                "null" || selectedAddendum?.id
                                            }
                                            onChange={handleOnChange}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="upfrontFeePercent"
                                            label="Upfront Fee Percent"
                                            type="number"
                                            min={0}
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.upfrontFeePercent ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="upfrontFeeApplicableDate"
                                            label="Upfront Fee Applicable Date"
                                            type="date"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState?.upfrontFeeApplicableDate ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="additionalFeePercent"
                                            label="Trail Fee Percent"
                                            type="number"
                                            errorMessage="Invalid Value"
                                            min={0}
                                            value={
                                              addendumFormState.additionalFeePercent ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="additionalFeePaymentFrequency"
                                            label="Trail Fee Payment Frequency"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            value={
                                              addendumFormState.additionalFeePaymentFrequency ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="NOT_APPLICABLE">
                                              Not Applicable
                                            </option>
                                            <option value="MONTHLY">
                                              Monthly
                                            </option>
                                            <option value="QUARTERLY">
                                              Quarterly
                                            </option>
                                            <option value="HALF_YEARLY">
                                              Half Yearly
                                            </option>
                                            <option value="ANNUALLY">
                                              Yearly
                                            </option>
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="trail_fee_applicable_days"
                                            label="Trail Fee Start Days"
                                            type="number"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            value={
                                              addendumFormState
                                                ? addendumFormState.trail_fee_applicable_days ==
                                                  0
                                                  ? "0"
                                                  : addendumFormState.trail_fee_applicable_days
                                                : ""
                                            }
                                            onChange={handleOnChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="minimumAggregateFaceValue"
                                            label="Minimum Business Volume"
                                            type="number"
                                            errorMessage="Invalid Value"
                                            min={0}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.minimumAggregateFaceValue ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="signedOn"
                                            label="Signed On"
                                            type="date"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState?.signedOn || ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="isAddendumSigned"
                                            label="Is Addendum Signed"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.isAddendumSigned?.toString() ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value={true}>TRUE</option>
                                            <option value={false}>FALSE</option>
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="open_status"
                                            label="Active Status"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.status?.toString() ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value={true}>TRUE</option>
                                            <option value={false}>FALSE</option>
                                          </AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="remarks"
                                            label="Remarks"
                                            type="text"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.remarks || ""
                                            }
                                            disabled={
                                              distributorAgreementId === "null"
                                            }
                                            onChange={handleOnChange}
                                          ></AvField>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    {!selectedOption.is_addendum_signed && (
                                      <Col>
                                        <div className={"text-center mt-2"}>
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              loading ||
                                              selectedOption.is_addendum_signed ||
                                              distributorAgreementId === "null"
                                            }
                                          >
                                            SAVE
                                          </button>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorAddendum
