import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { getChartColorsArray, humanize } from "constants/common"
import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import nodata from "../../assets/images/nodata/no-data.svg"
import { csvDownloadData, enumSelector, picUrl } from "constants/common"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import ReactEcharts from "echarts-for-react"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"

const MasterDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [investmentData, setInvestmentData] = useState([])
  const [sourceAnalysis, setSourceAnalysis] = useState([])
  const [investorBehaviorAnalysis, setInvestorBehaviorAnalysis] = useState([])
  const [userTableSummary, setUserTableSummary] = useState([])
  const [investmentSummary, setInvestmentSummary] = useState([])
  const [distWiseSummary, setDistWiseSummary] = useState([])
  const [document, setDocument] = useState([])
  const [sourceAnalysisType, setSourceAnalysisType] = useState("")
  const [investorBehaviorType, setInvestorBehaviorType] = useState("")
  const [invSummaryType, setInvSummaryType] = useState("")
  const [distributorAnalysisType, setDistributorAnalysisType] = useState("")
  const [chartData, setChartData] = useState([])
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)

  const PieEChartColors = getChartColorsArray(
    '["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'
  )
  const masterData = async () => {
    setLoading(true)
    try {
      const investmentRes = await axiosInstance.get(`investment`)
      if (investmentRes) {
        setInvestmentData(investmentRes.data?.data)
      }
      const sourceAnalysisRes = await axiosInstance.get(`source-analysis`)
      if (sourceAnalysisRes) {
        setSourceAnalysis(sourceAnalysisRes.data?.data)
      }
      const investorBehaviorAnalysisRes = await axiosInstance.get(
        `investor-behaviour-analysis`
      )
      if (investorBehaviorAnalysisRes) {
        setInvestorBehaviorAnalysis(investorBehaviorAnalysisRes.data?.data)
      }
      setLoading(false)

      const userTableSummaryRes = await axiosInstance.get(`users-summary`)
      if (userTableSummaryRes) {
        setUserTableSummary(userTableSummaryRes.data?.data)
      }

      const investmentSummaryRes = await axiosInstance.get(
        `/admin/user-investment-cumulative-report`
      )
      if (investmentSummaryRes) {
        setInvestmentSummary(investmentSummaryRes.data?.data)
        const direct_commitment_amount =
          investmentSummaryRes.data.commitment_amount.direct
        const dist_commitment_amount =
          investmentSummaryRes.data.commitment_amount.distributor
        setChartData([
          {
            value: direct_commitment_amount,
            name: `Direct`,
          },
          {
            value: dist_commitment_amount,
            name: `Distributor`,
          },
        ])
      }

      const distSummaryRes = await axiosInstance.get(
        `admin/distributor-cumulative-report`
      )
      if (distSummaryRes) {
        setDistWiseSummary(distSummaryRes.data?.data)
      }
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleFilterChange = async (e, type) => {
    const value = e.target.value
    setLoading(true)
    if (type === "SourceAnalysis") {
      try {
        setSourceAnalysisType(investmentData.find(inv => inv.id === value))
        const sourceAnalysisRes = await axiosInstance.get(
          `source-analysis${value ? "?investmentId=" + value : ""}`
        )
        if (sourceAnalysisRes) {
          setSourceAnalysis(sourceAnalysisRes.data?.data)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (type === "DistributorAnalysis") {
      try {
        setDistributorAnalysisType(investmentData.find(inv => inv.id === value))
        const distributorAnalysisRes = await axiosInstance.get(
          `admin/distributor-cumulative-report${
            value ? "?investmentId=" + value : ""
          }`
        )
        if (distributorAnalysisRes) {
          setDistWiseSummary(distributorAnalysisRes.data?.data)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (type === "InvestorBehaviorAnalysis") {
      if (value == "false") {
        setInvestorBehaviorType("Direct")
      } else if (value == "true") {
        setInvestorBehaviorType("Distributor")
      } else {
        setInvestorBehaviorType("")
      }
      try {
        const investorBehaviorAnalysisRes = await axiosInstance.get(
          `investor-behaviour-analysis${value ? "?distributor=" + value : ""}`
        )
        if (investorBehaviorAnalysisRes) {
          setInvestorBehaviorAnalysis(investorBehaviorAnalysisRes.data?.data)
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      if (value == "false") {
        setInvSummaryType("Direct")
      } else if (value == "true") {
        setInvSummaryType("Distributor")
      } else {
        setInvSummaryType("")
      }
      try {
        const response = await axiosInstance.get(
          `admin/user-investment-cumulative-report${
            value ? "?distributor=" + value : ""
          }`
        )
        if (response) {
          setInvestmentSummary(response.data?.data)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = Math.round(amount)
    return `₹ ${dollarIndianLocale.format(amountInRupees)} `
  }

  const handlePaisaAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount / 100))
    return `₹ ${dollarIndianLocale.format(amountInRupees)} `
  }

  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return (
          params.seriesName +
          "<br/>" +
          params.name +
          " : " +
          handleAmount(params.value)
        )
      },
    },
    legend: {
      orient: "horizontal",
      left: "center",
      top: 40,
      data: chartData.map(obj => obj.name),
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Commitment",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: chartData,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: function (params) {
            return handleAmount(params.value)
          },
        },
      },
    ],
  }
  useEffect(() => {
    masterData()
  }, [])

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const SourceAnalysisColumn = () => [
    {
      dataField: "type",
      text: "Particulars",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => humanize(row.type),
    },
    {
      dataField: "direct",
      text: "Direct",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.direct + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.direct
            : handleAmount(row.direct)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "distributor",
      text: "Distributor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.distributor + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.distributor
            : handleAmount(row.distributor)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.total + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.total
            : handleAmount(row.total)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const InvestorBehaviorAnalysisColumn = () => [
    {
      dataField: "type",
      text: "Particulars",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => humanize(row.type),
    },
    {
      dataField: "one_time",
      text: "One-Time",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.one_time + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.one_time
            : handleAmount(row.one_time)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "repeat",
      text: "Repeat",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.repeat + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.repeat
            : handleAmount(row.repeat)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {row.type === "% (By Inv. Value)"
            ? row.total + "%"
            : row.type === "Number of Trades" ||
              row.type === "Number of Investors" ||
              row.type == "No. of Bonds (Sold)"
            ? row.total
            : handleAmount(row.total)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const userTableSummaryColumn = () => [
    {
      dataField: "type",
      text: "Particulars",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => humanize(row.type),
    },
    {
      dataField: "direct",
      text: "Direct",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.direct}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "distributor",
      text: "Distributor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.distributor}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.total}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const InvestmentSummaryColumn = () => [
    {
      dataField: "status",
      text: "Investment Stage",
      sort: true,
      formatter: (cellContent, row) => enumSelector(row.status),
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.count}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "lot_size",
      text: "Lot size",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.lot_size}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "commitment_amount",
      text: "Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.commitment_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "amount_paid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handlePaisaAmount(row.amount_paid)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const DistWiseSummaryColumn = () => [
    {
      dataField: "name",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Distributor Email",
      sort: true,
      formatter: (cellContent, row) => row.email || "NA",
    },
    {
      dataField: "phone",
      text: "Distributor Phone",
      sort: true,
      formatter: (cellContent, row) => row.phone || "NA",
    },
    {
      dataField: "count",
      text: "Investment Count",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.count}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    // {
    //   dataField: "lot_size",
    //   text: "Lot size",
    //   sort: true,
    // },
    {
      dataField: "commitment_amount",
      text: "Investment Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.commitment_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "investment_by_investor",
      text: "Investment/Investor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleAmount(row.investment_by_investor)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    // {
    //   dataField: "amount_paid",
    //   text: "Amount Paid",
    //   sort: true,
    //   formatter: (cellContent, row) => handlePaisaAmount(row.amount_paid),
    // },
  ]

  const downloadData = (data, name, csvTableHeaders) => {
    csvTableHeaders = csvTableHeaders()
    const downloadableArr = csvDownloadData(csvTableHeaders, data)
    JSONToCSVConvertor(downloadableArr, name, true)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const accessRestriction = DealManagementAccess(AccessId?.DASHBOARD)

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <h4>Platform Dashboard</h4>
          <hr />
          <React.Fragment>
            <Col xl="12" className="p-2 h-100">
              <Row>
                <Col xl="6">
                  <Card>
                    <CardBody style={{ minHeight: "400px" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={sourceAnalysis}
                        columns={SourceAnalysisColumn()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <CardTitle className="mb-3 mt-1">
                              <Row className="row justify-content-between">
                                <div className="col">Source Analysis</div>
                                <div className="col-auto d-flex align-items-center">
                                  <div className="me-3">
                                    <select
                                      className="form-select form-select-md"
                                      style={{ width: "140px" }}
                                      onChange={e => {
                                        handleFilterChange(e, "SourceAnalysis")
                                      }}
                                    >
                                      <option value="">All</option>
                                      {investmentData?.map(inv => (
                                        <option value={inv.id} key={inv.id}>
                                          {inv.project_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-rounded p-1 px-2"
                                        onClick={() => {
                                          downloadData(
                                            sourceAnalysis,
                                            `${
                                              sourceAnalysisType?.project_name
                                                ? sourceAnalysisType?.project_name +
                                                  "-"
                                                : ""
                                            }Source-Analysis`,
                                            SourceAnalysisColumn
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle-outline font-size-18"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Row>
                            </CardTitle>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={SourceAnalysisColumn()}
                                  data={sourceAnalysis}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6">
                  <Card>
                    <CardBody style={{ minHeight: "400px" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={investorBehaviorAnalysis}
                        columns={InvestorBehaviorAnalysisColumn()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <CardTitle className="mb-3 mt-1">
                              <Row className="row justify-content-between">
                                <div className="col">
                                  Investor Behaviour Analysis
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                  <div className="me-3">
                                    <select
                                      className="form-select form-select-md"
                                      style={{ width: "140px" }}
                                      value={
                                        investorBehaviorType === ""
                                          ? ""
                                          : investorBehaviorType === "Direct"
                                          ? "false"
                                          : "true"
                                      }
                                      onChange={e => {
                                        handleFilterChange(
                                          e,
                                          "InvestorBehaviorAnalysis"
                                        )
                                      }}
                                    >
                                      <option value="">All</option>
                                      <option value="false">Direct</option>
                                      <option value="true">Distributor</option>
                                    </select>
                                  </div>
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-rounded p-1 px-2"
                                        onClick={() => {
                                          downloadData(
                                            investorBehaviorAnalysis,
                                            `${
                                              investorBehaviorType
                                                ? investorBehaviorType + "-"
                                                : ""
                                            }Investor-Behaviour-Analysis`,
                                            InvestorBehaviorAnalysisColumn
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle-outline font-size-18"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Row>
                            </CardTitle>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={InvestorBehaviorAnalysisColumn()}
                                  data={investorBehaviorAnalysis}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="6">
                  <Card>
                    <CardBody style={{ minHeight: "400px" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={userTableSummary}
                        columns={userTableSummaryColumn()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <CardTitle className="mb-3 mt-1">
                              <Row className="row justify-content-between">
                                <div className="col">User Table Summary</div>
                                <div className="col-auto d-flex align-items-center">
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-rounded p-1 px-2"
                                        onClick={() => {
                                          downloadData(
                                            userTableSummary,
                                            `User-Table-Summary`,
                                            userTableSummaryColumn
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle-outline font-size-18"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Row>
                            </CardTitle>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={userTableSummaryColumn()}
                                  data={userTableSummary}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="8">
                  <Card>
                    <CardBody style={{ minHeight: "400px" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={investmentSummary}
                        columns={InvestmentSummaryColumn()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <CardTitle className="mb-3 mt-1">
                              <Row className="row justify-content-between">
                                <div className="col">Investment summary</div>
                                <div className="col-auto d-flex align-items-center">
                                  <div className="me-3">
                                    <select
                                      className="form-select form-select-md"
                                      style={{ width: "140px" }}
                                      value={
                                        invSummaryType === ""
                                          ? ""
                                          : invSummaryType === "Direct"
                                          ? "false"
                                          : "true"
                                      }
                                      onChange={e => {
                                        handleFilterChange(e)
                                      }}
                                    >
                                      <option value="">All</option>
                                      <option value="false">Direct</option>
                                      <option value="true">Distributor</option>
                                    </select>
                                  </div>
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-rounded p-1 px-2"
                                        onClick={() => {
                                          downloadData(
                                            investmentSummary.map(obj => ({
                                              ...obj,
                                              status: enumSelector(obj.status),
                                              amount_paid: Math.abs(
                                                obj.amount_paid / 100
                                              ),
                                            })),
                                            `${
                                              invSummaryType
                                                ? invSummaryType + "-"
                                                : ""
                                            }Investment-Summary`,
                                            InvestmentSummaryColumn
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle-outline font-size-18"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Row>
                            </CardTitle>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={InvestmentSummaryColumn()}
                                  data={investmentSummary}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4">
                  <Card>
                    <CardBody style={{ height: "445px" }}>
                      <CardTitle>Completed Investments</CardTitle>
                      <div style={{ height: "350px" }}>
                        <ReactEcharts
                          className="mt-n2"
                          style={{ height: "100%" }}
                          option={options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody style={{ height: "480px" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={distWiseSummary}
                        columns={DistWiseSummaryColumn()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <CardTitle className="mb-3 mt-1">
                              <Row className="row justify-content-between">
                                <div className="col">Distributor Analysis</div>
                                <div className="col-auto d-flex align-items-center">
                                  <div className="me-3">
                                    <select
                                      className="form-select form-select-md"
                                      style={{ width: "140px" }}
                                      onChange={e => {
                                        handleFilterChange(
                                          e,
                                          "DistributorAnalysis"
                                        )
                                      }}
                                    >
                                      <option value="">All</option>
                                      {investmentData?.map(inv => (
                                        <option value={inv.id} key={inv.id}>
                                          {inv.project_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {(accessRestriction >= 1 ||
                                    accessRestriction === "SuperAdmin") && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-rounded p-1 px-2"
                                        onClick={() => {
                                          downloadData(
                                            distWiseSummary,
                                            `${
                                              distributorAnalysisType?.project_name
                                                ? distributorAnalysisType?.project_name +
                                                  "-"
                                                : ""
                                            }Distributor-Analysis`,
                                            DistWiseSummaryColumn
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-arrow-down-bold-circle-outline font-size-18"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Row>
                            </CardTitle>
                            <Row>
                              <Col xl="14">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={DistWiseSummaryColumn()}
                                  data={distWiseSummary}
                                  wrapperClasses={
                                    "table-responsive body-scrollable"
                                  }
                                  classes={
                                    "table align-middle table-nowrap table-check sticky-header"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col xl="4">
                      <Card>
                        <CardBody style={{ height: "480px" }}>
                          <CardTitle className="mb-4">Documents</CardTitle>
                          {document.length > 0 ? (
                            <SimpleBar style={{ maxHeight: "380px" }}>
                              <div className="table-responsive">
                                <Table className="table-nowrap align-middle table-hover mb-0">
                                  <tbody>
                                    {document.map((file, i) => (
                                      <tr key={"_file_" + i}>
                                        <td width="10%">
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              handleDoc(file.url)
                                            }}
                                            className="text-dark"
                                          >
                                            <i className="bx bx-download h3 m-0" />
                                          </Link>
                                        </td>
                                        <td>
                                          <h5 className="font-size-14 mb-1">
                                            <Link to="#" className="text-dark">
                                              {file.document_name}
                                            </Link>
                                          </h5>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </SimpleBar>
                          ) : (
                            <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center gap-2 mt-n5">
                              <i
                                className="mdi mdi-file-document-multiple-outline"
                                style={{ fontSize: "100px" }}
                              />
                              <h5>No Documents found</h5>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col> */}
              </Row>
            </Col>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MasterDashboard
