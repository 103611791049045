import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData } from "../../constants/common"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { JSONToCSVConvertor } from "common/jsontocsv"

const InvestorWiseExposure = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { SearchBar } = Search

  const masterData = async () => {
    setLoading(true)
    try {
      const localUser = JSON.parse(localStorage.getItem("userInfo"))
      const response = await axiosInstance.get(
        `report/investor-exposure?distributorId=${localUser.id}`
      )

      if (response) {
        setData(response.data?.data || response.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }
  const ExposureColumn = () => [
    {
      dataField: "name",
      text: "Investor's Name",
      sort: true,
    },
    {
      dataField: "amount_invested",
      text: "Amount Invested",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.amount_invested)}</div>
      ),
    },
    {
      dataField: "interest_paid",
      text: "Interest Paid (Gross)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.interest_paid)}</div>
      ),
    },
    {
      dataField: "principal_repaid",
      text: "Principal Repaid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.principal_repaid)}</div>
      ),
    },
    {
      dataField: "principal_invested",
      text: "Net Cash Invested",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.principal_invested)}</div>
      ),
    },
    {
      dataField: "outstanding_principal",
      text: "Principal Outstanding",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.outstanding_principal)}</div>
      ),
    },
  ]

  let downloadableArr = []
  let arr
  if (data) {
    let csvTableHeaders = ExposureColumn()
    var str = JSON.stringify(data)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Investor Wise Exposure" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={ExposureColumn()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <Col className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <div className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              className="btn-rounded  mb-2 me-2"
                              onClick={() =>
                                JSONToCSVConvertor(
                                  downloadableArr,
                                  "Investor Wise Exposure",
                                  true
                                )
                              }
                              style={{ marginRight: "3px" }}
                            >
                              <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                              Download
                            </Button>
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={ExposureColumn()}
                              data={data}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(InvestorWiseExposure)
