import axios from "axios"
import { showToastError } from "./utils"
import LocalStorageService from "./LocalStorageService"
const localStorageService = LocalStorageService.getService()

export const serverBaseUrl = process.env.REACT_APP_EARNNEST_DOMAIN

export const axiosInstance = axios.create({
  baseURL: serverBaseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": serverBaseUrl,
  },
})

axiosInstance.interceptors.request.use(request => requestHandler(request))
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

const requestHandler = request => {
  const token = localStorageService.getAccessToken()
  request.headers["Authorization"] = "Bearer " + token
  return request
}

const errorHandler = error => {
  let msg = error.message
  if (error.message === "Network Error" && !error.response) {
    msg = "Something went wrong! Try Again."
    showToastError(msg)
  }
  const { status, data, code, message } = error?.response || {}
  if (status === 404) {
    msg = data?.message || "Not found"
    showToastError(msg)
  }
  if (status === 400 || status === 412) {
    msg = data?.message || data?.msg || data?.error
    showToastError(msg)
  }
  if (status === 500) {
    msg = data?.message
    showToastError(msg)
  }
  if (status === 401) {
    msg = data?.message
    showToastError(msg)
    return window.location.assign(`${window.location.origin}/login`)
  }
  return Promise.reject(error)
}

const successHandler = response => {
  return response
}
