import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import classnames from "classnames"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import PropTypes from "prop-types"
import {
  countryList,
  createFullAdress,
  getOptionLabel,
  indianStates,
} from "constants/common"
import { axiosInstance } from "ConfigAxioxinstance"
import moment from "moment"
import Loader from "common/Loader"
import { toast } from "react-toastify"
import { useHistory, useParams } from "react-router-dom"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
const DistributorUserKyc = () => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [addressDoc, setAddressDoc] = useState(null)
  const [selectedUser, setSelectedUser] = useState([])
  const [panName, setPanName] = useState("")
  const [errorDetail, setErrorDeatail] = useState(false)
  const [personlInfo, setPersonalInfo] = useState(false)
  const [corporateInfo, setCorporateInfo] = useState(false)
  const [hufInfo, setHufInfo] = useState(false)
  const [panValue, setPanValue] = useState("")
  const [docfile, setDocFile] = useState()
  const [addressFile, setaddressFile] = useState()
  const [dematFile, setDematFile] = useState()
  const [dematDoc, setDematDoc] = useState(null)
  const [accountName, setAccountName] = useState("")
  const [panType, setPanType] = useState("")
  const [dob, setDob] = useState("")
  const [address, setAddress] = useState("")
  const [panRes, setPanRes] = useState(null)
  const [zipCode, setZipCode] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [panAddress, setPanAddress] = useState("")
  const [kartaPanValue, setKartaPanValue] = useState()
  const [kartaPanFile, setKartaPanFile] = useState("")
  const [kartaPanDoc, setKartaPanDoc] = useState(null)
  const [isKartaNameSame, setKartaNameSame] = useState(true)
  const [kartaVerfiDoc, setKartaVerfiDoc] = useState(null)
  const [kartaVerfiFile, setKartaVerfiFile] = useState("")
  const [mcrDoc, setMcrDoc] = useState(null)
  const [mcrFile, setMcrFile] = useState(null)
  const [brDoc, setBrDoc] = useState(null)
  const [brFile, setBrFile] = useState(null)
  const [authPanDoc, setAuthPanDoc] = useState(null)
  const [authPanFile, setAuthPanFile] = useState(null)
  const [authAadharDoc, setAuthAadharDoc] = useState(null)
  const [authAadharFile, setAuthAadharFile] = useState(null)
  const [isAuthorisedName, setIsAuthorisedName] = useState(true)
  const [authorisedName, setAuthorisedName] = useState("")
  const [editAddress, setEditAddress] = useState(false)
  const [isAddressUploadReq, setIsAddressUploadReq] = useState(false)
  const [addressConsent, setAddressConsent] = useState(false)
  const [submitConsent, setSubmitConsent] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const [addressModal, setAddressModal] = useState(false)
  const [kartaVerifyModal, setKartaVerifyModal] = useState(false)
  const [mcrModal, setMcrModal] = useState(false)

  const [dematModal, setDematModal] = useState(false)

  const { userId } = useParams()
  const history = useHistory()

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department === "Distributor"

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`/users/${userId}`)

      if (response) {
        setSelectedUser(response?.data || response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  useEffect(() => {
    const fullAddress = createFullAdress(
      addressLine1,
      addressLine2,
      zipCode,
      city,
      state,
      country
    )
    setAddress(fullAddress)
  }, [addressLine1, addressLine2, zipCode, city, state, country])

  useEffect(() => {
    if (selectedUser?.id) {
      if (
        selectedUser.kyc_status === "VERIFIED" ||
        selectedUser.kyc_status === "IN_VERIFICATION"
      ) {
        toast.error("Investor kyc already submitted")
        history.replace(isDist ? "/distributor-users" : "/rm-users")
      }
      if (selectedUser.user_pan?.id) {
        setPanValue(selectedUser.user_pan.pan_number)
        setPanName(selectedUser.user_pan.name)
        setDocFile(selectedUser.user_pan.document_file)
        setPanType(selectedUser.user_pan.pan_type)
      }
      if (selectedUser.user_corporate_kyc?.id) {
        setAuthAadharFile(
          selectedUser.user_corporate_kyc.authorised_aadhar_file
        )
        setAuthorisedName(selectedUser.user_corporate_kyc.authorised_name)
        setAuthPanFile(selectedUser.user_corporate_kyc.authorised_pan_file)
        setBrFile(selectedUser.user_corporate_kyc.br_file)
        setIsAuthorisedName(selectedUser.user_corporate_kyc.is_authorised_name)
        setMcrFile(selectedUser.user_corporate_kyc.mcr_file)
      }
      if (selectedUser.user_huf_kyc?.id) {
        setKartaPanFile(selectedUser.user_huf_kyc.karta_pan_file)
        setKartaPanValue(selectedUser.user_huf_kyc.karta_pan_number)
        setKartaVerfiFile(selectedUser.user_huf_kyc.karta_doc_proof)
        setKartaNameSame(selectedUser.user_huf_kyc.is_karta_same_as_huf)
      }
      if (selectedUser?.dob) {
        setDob(moment(selectedUser?.dob).format("YYYY-MM-DD"))
      }
      setAddress(selectedUser?.address)
      setZipCode(selectedUser?.zip_code)
      setCity(selectedUser?.city)
      setState(selectedUser?.state)
      setCountry(selectedUser?.country)
      setAddressLine1(selectedUser?.address_line_1)
      setAddressLine2(selectedUser?.address_line_2)
      setPanRes({
        address_line_1: selectedUser?.address_line_1,
        address_line_2: selectedUser?.address_line_2,
        zip_code: selectedUser?.zip_code,
        city: selectedUser?.city,
        state: selectedUser?.state,
        address: selectedUser?.address,
        country: selectedUser?.country,
      })
      setAddressConsent(selectedUser?.aadhaar_consent)
      setaddressFile(selectedUser.address_file)
      if (selectedUser.user_demat?.id) {
        setDematFile(selectedUser.user_demat.document_file)
      }
      if (selectedUser.user_bank_account?.id) {
        setAccountName(selectedUser.user_bank_account.account_name)
      }
    }
  }, [selectedUser])

  const handleKartaPanValue = e => {
    setKartaPanValue(e.target.value)
  }

  const prevKycStep = () => {
    toggleTabVertical(activeTabVartical - 1)
  }
  const handleEditAddress = () => {
    if (editAddress) {
      setAddress(panAddress || selectedUser?.address)
      setZipCode(zipCode || selectedUser?.zip_code)
      setCity(city || selectedUser?.city)
      setState(state || selectedUser?.state)
      setAddressLine1(addressLine1 || selectedUser?.address_line_1)
      setAddressLine2(addressLine2 || selectedUser?.address_line_2)
      setCountry(country || selectedUser?.country)
    } else {
      setAddress(panRes?.address)
      setZipCode(panRes?.zip_code)
      setCity(panRes?.city)
      setState(panRes?.state)
      setAddressLine1(panRes?.address_line_1)
      setAddressLine2(panRes?.address_line_2)
      setCountry(panRes?.country)
    }
    setEditAddress(!editAddress)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (activeTabVartical === 1) {
      const userPan = {
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
        pan_number: panValue.toUpperCase(),
        pan_status: "VALID",
        pan_type: panType,
        name: panName,
        document_file: docfile,
        userId: selectedUser?.id,
      }
      const user = {
        dob: dob ? dob : undefined,
        address: createFullAdress(
          addressLine1,
          addressLine2,
          zipCode,
          city,
          state,
          country
        ),
        city: city,
        zip_code: zipCode,
        state: state,
        country: country,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_file: addressFile,
        aadhaar_consent: addressConsent,
      }
      try {
        const response = selectedUser.user_pan?.id
          ? await axiosInstance.patch(
              `user-pan/${selectedUser.user_pan.id}`,
              userPan
            )
          : await axiosInstance.post(`user-pan`, userPan)
        if (response) {
          toast.success("Successfully Updated")
          if (
            panType === "Company" ||
            panType === "Firm/ Limited Liability Partnership"
          ) {
            setCorporateInfo(true)
            setHufInfo(false)
            setPersonalInfo(false)
          } else if (panType === "Hindu Undivided Family (HUF)") {
            setHufInfo(true)
            setPersonalInfo(false)
            setCorporateInfo(false)
          } else {
            setPersonalInfo(true)
            setCorporateInfo(false)
            setHufInfo(false)
          }
          setEditAddress(false)
          setIsAddressUploadReq(false)
        }
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
        return
      }
      if (address || dob) {
        try {
          const response = selectedUser.id
            ? await axiosInstance.patch(`users/${selectedUser.id}`, user)
            : null
          if (response) {
            toast.success("Successfully Updated")
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          return
        }
      }
      toggleTabVertical(activeTabVartical + 1)
    } else if (activeTabVartical === 2) {
      if (
        panType === "Company" ||
        panType === "Firm/ Limited Liability Partnership"
      ) {
        const userCorporate = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          authorised_name: authorisedName,
          authorised_aadhar_file: authAadharFile,
          authorised_pan_file: authPanFile,
          userId: selectedUser?.id,
        }
        const userCorporateSame = {
          pan_type: panType,
          mcr_file: mcrFile,
          br_file: brFile,
          is_authorised_name: isAuthorisedName,
          userId: selectedUser?.id,
        }
        const userAddress = {
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          city: city,
          zip_code: zipCode,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
        }
        try {
          const response = selectedUser.user_corporate_kyc?.id
            ? await axiosInstance.patch(
                `user-corporate-kyc/${selectedUser.user_corporate_kyc.id}`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
            : await axiosInstance.post(
                `user-corporate-kyc`,
                isAuthorisedName ? userCorporateSame : userCorporate
              )
          const res2 = await axiosInstance.patch(
            `users/${selectedUser?.id}`,
            userAddress
          )
          if (response) {
            toast.success("Successfully Updated")
          }
          if (res2) {
            toast.success("Successfully Updated")
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          return
        }
      } else if (panType === "Hindu Undivided Family (HUF)") {
        const userHuf = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          karta_doc_proof: kartaVerfiFile,
          userId: selectedUser?.id,
        }
        const userHufSame = {
          pan_type: panType,
          karta_pan_number: kartaPanValue.toUpperCase(),
          karta_pan_file: kartaPanFile,
          is_karta_same_as_huf: isKartaNameSame,
          userId: selectedUser?.id,
        }
        const user = {
          aadhaar_consent: addressConsent,
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          city: city,
          zip_code: zipCode,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_file: addressFile,
        }
        try {
          const response = selectedUser.user_huf_kyc?.id
            ? await axiosInstance.patch(
                `user-huf-kyc/${selectedUser.user_huf_kyc.id}`,
                !isKartaNameSame ? userHuf : userHufSame
              )
            : await axiosInstance.post(
                `user-huf-kyc`,
                !isKartaNameSame ? userHuf : userHufSame
              )
          if (response) {
            toast.success("Successfully Updated")
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          return
        }
        try {
          const response = selectedUser?.id
            ? await axiosInstance.patch(`users/${selectedUser.id}`, user)
            : null
          if (response) {
            toast.success("Successfully Updated")
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          return
        }
      } else {
        const userAddress = {
          dob: dob ? values.dob : values.dob,
          res_status: values.res_status,
          aadhaar_consent: addressConsent,
          address: createFullAdress(
            addressLine1,
            addressLine2,
            zipCode,
            city,
            state,
            country
          ),
          city: city,
          zip_code: zipCode,
          state: state,
          country: country,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_file: addressFile,
        }
        try {
          const response = await axiosInstance.patch(
            `users/${selectedUser?.id}`,
            userAddress
          )
          if (response) {
            toast.success("Successfully Updated")
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          return
        }
      }
      toggleTabVertical(activeTabVartical + 1)
    } else if (activeTabVartical === 3) {
      const userDemat = {
        demat_id: values.demat,
        document_file: dematFile,
        userId: selectedUser?.id,
      }
      try {
        const response = selectedUser.user_demat?.id
          ? await axiosInstance.patch(
              `user-demat/${selectedUser.user_demat.id}`,
              userDemat
            )
          : await axiosInstance.post(`user-demat`, userDemat)
        if (response) {
          toast.success("Successfully Updated")
        }
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
        return
      }
      toggleTabVertical(activeTabVartical + 1)
    } else if (activeTabVartical === 4) {
      const userBank = {
        Account: values.account_number,
        IFSC: values.ifsc,
        consent: "Y",
        userId: selectedUser?.id,
      }
      try {
        let response = {}
        if (selectedUser.user_bank_account?.id) {
          if (
            userBank.Account ===
              selectedUser.user_bank_account.account_number &&
            userBank.IFSC === selectedUser.user_bank_account.ifsc_code &&
            selectedUser.user_bank_account.bank_status === "VERIFIED"
          ) {
            response = {
              data: selectedUser.user_bank_account,
            }
          } else {
            response = await axiosInstance.patch(
              `user-bank-account/${selectedUser.user_bank_account.id}`,
              userBank
            )
          }
        } else {
          response = await axiosInstance.post(`user-bank-account`, userBank)
        }
        if (response?.data) {
          setAccountName(response?.data?.account_name)
          toast.success("Successfully Updated")
          setLoading(false)
          setButtonLoading(true)
          setTimeout(() => {
            setButtonLoading(false)
            toggleTabVertical(activeTabVartical + 1)
          }, 2000)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    masterData()
    setLoading(false)
  }

  const addressFields = (
    <>
      <div className="d-flex gap-2 align-items-center pb-2">
        <b>Address</b>
        <div
          style={{
            // color: "#34c38f",
            cursor: "pointer",
          }}
          className={editAddress ? "text-danger" : "text-primary"}
          onClick={() => handleEditAddress()}
        >
          {editAddress ? "Cancel" : "Edit"}
        </div>
      </div>
      {editAddress ? (
        <>
          <Col md="6" className="mb-3">
            <AvField
              name="address_line_1"
              label="Address Line 1*"
              type="textarea"
              errorMessage="Please Enter valid input"
              value={addressLine1 ? addressLine1 : ""}
              validate={{
                required: {
                  value: true,
                },
              }}
              onChange={e => setAddressLine1(e.target.value)}
              disabled={!editAddress}
            ></AvField>
          </Col>
          <Col md="6" className="mb-3">
            <AvField
              name="address_line_2"
              label="Address Line 2"
              type="textarea"
              value={addressLine2 ? addressLine2 : ""}
              onChange={e => setAddressLine2(e.target.value)}
              disabled={!editAddress}
            ></AvField>
          </Col>
          <Col md="6" className="mb-3">
            <AvField
              name="city"
              label="City*"
              type="text"
              errorMessage="Please Enter valid input"
              value={city ? city : ""}
              validate={{
                required: {
                  value: true,
                },
              }}
              onChange={e => setCity(e.target.value)}
              disabled={!editAddress}
            ></AvField>
          </Col>
          <Col md="6" className="mb-3">
            <AvField
              name="zip_code"
              label="Pin Code*"
              type="text"
              errorMessage="Please Enter valid input"
              value={zipCode ? zipCode : ""}
              validate={{
                required: {
                  value: true,
                },
              }}
              onChange={e => setZipCode(e.target.value)}
              disabled={!editAddress}
            ></AvField>
          </Col>
          <Col className="col-6">
            <AvField
              name="state"
              label="State*"
              type="select"
              errorMessage="Please Enter valid input"
              value={state ? state : ""}
              validate={{
                required: {
                  value: true,
                },
              }}
              onChange={e => setState(e.target.value)}
              disabled={!editAddress}
            >
              <option value="" disabled>
                Select
              </option>
              {indianStates.map(item => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </AvField>
          </Col>
          <Col className="col-6">
            <AvField
              name="country"
              label="Country*"
              type="select"
              errorMessage="Please Enter valid input"
              value={country ? country : ""}
              validate={{
                required: {
                  value: true,
                },
              }}
              onChange={e => setCountry(e.target.value)}
              disabled={!editAddress}
            >
              <option value="" disabled>
                Select
              </option>
              {countryList.map(item => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </AvField>
          </Col>
        </>
      ) : (
        <>
          <AvField
            name="address"
            type="textarea"
            value={panRes?.address ? panRes.address : ""}
            disabled={true}
            style={{ resize: "none" }}
            validate={{
              required: {
                value: true,
              },
            }}
            errorMessage="Please Edit Address"
          ></AvField>
        </>
      )}
    </>
  )

  const isValidKycData = () => {
    if (selectedUser?.id) {
      if (
        // PAN Details
        selectedUser.user_pan &&
        selectedUser.user_pan.id &&
        selectedUser.user_pan?.pan_number &&
        selectedUser.user_pan?.name &&
        selectedUser.user_pan?.document_file &&
        // KYC Details
        (panType === "Hindu Undivided Family (HUF)"
          ? selectedUser.user_huf_kyc &&
            selectedUser.user_huf_kyc.id &&
            selectedUser.user_huf_kyc.karta_pan_number &&
            selectedUser.user_huf_kyc.karta_pan_file &&
            (selectedUser.user_huf_kyc.is_karta_same_as_huf ||
              selectedUser.user_huf_kyc.karta_doc_proof)
          : panType === "Company" ||
            panType === "Firm/ Limited Liability Partnership"
          ? selectedUser.user_corporate_kyc &&
            selectedUser.user_corporate_kyc.id &&
            selectedUser.user_corporate_kyc.mcr_file &&
            selectedUser.user_corporate_kyc.br_file
          : selectedUser.res_status &&
            selectedUser.dob &&
            selectedUser.address) &&
        // Demat Details
        selectedUser.user_demat &&
        selectedUser.user_demat.demat_id &&
        selectedUser.user_demat.document_file &&
        // Bank Account Details
        selectedUser.user_bank_account &&
        selectedUser.user_bank_account.id &&
        selectedUser.user_bank_account.account_name &&
        selectedUser.user_bank_account?.account_number &&
        selectedUser.user_bank_account?.ifsc_code
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const finalSubmit = async () => {
    if (isValidKycData()) {
      const data = { kyc_status: "IN_VERIFICATION" }

      try {
        const response = await axiosInstance.patch(
          `users/${selectedUser?.id}`,
          data
        )
        if (response) {
          toast.success("KYC successfully completed")
          masterData()
          history.replace(isDist ? "/distributor-users" : "/rm-users")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      isValidKycData()
      toast.error("Fill out all details first")
    }
  }

  const handleKartaPanUpload = e => {
    setKartaPanDoc(e.target.files[0])
  }
  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDocFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const handlePanInput = async e => {
    setErrorDeatail(false)
    setPanName("")
    setPanType("")
    const panInput = e.target.value
    if (panInput?.length == 10) {
      setPanValue(panInput)
      const panData = {
        pan: panInput.toUpperCase(),
        userId: selectedUser?.id,
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
      }
      setLoading(true)
      try {
        const response = await axiosInstance.post(`pan-verification`, panData)
        if (response) {
          setPanName(response?.data?.name)
          setPanType(response?.data.pan_type)
          setDocFile("")
          response.data.dob
            ? setDob(
                moment(response?.data.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
              )
            : setDob()

          const fullAdress = createFullAdress(
            response?.data?.address_line_1,
            response?.data?.address_line_2,
            response?.data?.zip_code,
            response?.data?.city,
            response?.data?.state,
            response?.data?.country
          )
          setAddress(fullAdress)

          response.data.zip_code
            ? setZipCode(response?.data.zip_code)
            : setZipCode("")

          response.data.city ? setCity(response?.data.city) : setCity("")
          setAddressLine1(response?.data?.address_line_1 || "")
          setAddressLine2(response?.data?.address_line_2 || "")
          setCountry(response?.data?.country)

          response.data.state ? setState(response?.data.state) : setState("")
          setaddressFile("")
          setAddressConsent(false)
          setPanAddress(response?.data.address)
        }
      } catch (error) {
        setErrorDeatail(true)
      }
      setLoading(false)
    }
  }

  const handleAddressUpload = e => {
    setAddressDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (addressDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", addressDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setaddressFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [addressDoc])
  useEffect(async () => {
    if (kartaPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaPanDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaPanDoc])
  useEffect(async () => {
    if (kartaVerfiDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", kartaVerfiDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setKartaVerfiFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [kartaVerfiDoc])
  useEffect(async () => {
    if (mcrDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", mcrDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setMcrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [mcrDoc])

  useEffect(async () => {
    if (brDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", brDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setBrFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [brDoc])

  useEffect(async () => {
    if (authAadharDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authAadharDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthAadharFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authAadharDoc])

  useEffect(async () => {
    if (authPanDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", authPanDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setAuthPanFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [authPanDoc])

  const handleDematUpload = e => {
    setDematDoc(e.target.files[0])
  }
  useEffect(async () => {
    if (dematDoc) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", dematDoc)
      formData.append("userId", selectedUser?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setDematFile(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [dematDoc])

  useEffect(() => {
    if (selectedUser?.address) {
      if (address != selectedUser?.address) {
        setIsAddressUploadReq(true)
      } else {
        setIsAddressUploadReq(false)
      }
    } else {
      setIsAddressUploadReq(false)
    }
  }, [address])

  useEffect(() => {
    if (addressFile) {
      setIsAddressUploadReq(true)
    } else {
      setIsAddressUploadReq(false)
    }
  }, [addressFile])

  // Library Code
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 5) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Earnnest-Admin</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs breadcrumbItem="Investor KYC" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Please submit KYC details</h4>
                <AvForm onValidSubmit={handleValidOrderSubmit}>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1.</span> PAN Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Other Information</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 3,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            <span className="number">3.</span> Demat Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 4,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 4,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(4)
                            }}
                            disabled={!(passedStepsVertical || []).includes(4)}
                          >
                            <span className="number">4.</span> Bank Account
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 5,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 5,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(5)
                            }}
                            disabled={!(passedStepsVertical || []).includes(5)}
                          >
                            <span className="number">5.</span> Submit KYC
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>

                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          {activeTabVartical === 1 && (
                            <>
                              <h3 className="fw-bold mb-3">PAN Details</h3>
                              <>
                                <Row>
                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        id="user"
                                        name="user"
                                        type="text"
                                        className="form-control"
                                        label="Investor"
                                        value={getOptionLabel({
                                          option: selectedUser,
                                        })}
                                        disabled
                                      />
                                    </div>
                                  </Col>

                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        style={{
                                          textTransform: "uppercase",
                                        }}
                                        name="panValue"
                                        label="Enter Investor's PAN"
                                        type="text"
                                        errorMessage="Enter a valid input"
                                        value={panValue || ""}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        maxLength="10"
                                        onChange={handlePanInput}
                                        disabled={
                                          selectedUser.kyc_status == "VERIFIED"
                                            ? true
                                            : false
                                        }
                                      ></AvField>
                                      <p style={{ color: "red" }}>
                                        {errorDetail &&
                                          "Please enter valid input"}
                                      </p>
                                    </div>
                                  </Col>
                                  {panType ==
                                    "Firm/ Limited Liability Partnership" ||
                                  panType == "Sole Proprietor" || panType == "Partnership Firm"? (
                                    <Col md="6">
                                      <div className="mb-3">
                                        <AvField
                                          name="pan_type"
                                          label="PAN Type"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Select valid input"
                                          value={
                                            panType ||
                                            "Firm/ Limited Liability Partnership"
                                          }
                                          onChange={e => {
                                            console.log(e.target.value)
                                            setPanType(e.target.value)
                                          }}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        >
                                          <option disabled value="">
                                            Select an option
                                          </option>
                                          <option value="Firm/ Limited Liability Partnership">
                                            Limited Liability Partnership
                                          </option>
                                          <option value="Sole Proprietor">
                                            Sole Proprietor
                                          </option>
                                          <option value="Partnership Firm">
                                            Partnership Firm
                                          </option>
                                        </AvField>
                                      </div>
                                    </Col>
                                  ) : null}
                                  <Col md="6">
                                    <div className=" mb-3">
                                      <AvField
                                        name="panName"
                                        label="Name as per PAN"
                                        type="text"
                                        errorMessage="Enter a valid input"
                                        value={panName || ""}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        disabled
                                      ></AvField>
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="mb-3">
                                      {loading && <Loader />}
                                      <AvField
                                        label="Upload PAN Card"
                                        className="form-control"
                                        type="file"
                                        name="panCard"
                                        id="formFile"
                                        errorMessage="Please Enter valid input"
                                        validate={{
                                          required: {
                                            value: docfile ? false : true,
                                          },
                                        }}
                                        onChange={handleFileUpload}
                                        disabled={
                                          selectedUser.kyc_status == "VERIFIED"
                                            ? true
                                            : false
                                        }
                                        required={true}
                                      ></AvField>
                                      <p className="mt-3">
                                        {docfile
                                          ? `File uploaded: ${docfile
                                              .split("/")
                                              .pop()}`
                                          : ""}
                                      </p>
                                      <p className="mt-3">
                                        Kindly upload copy of PAN card front
                                        side in pdf/jpg/png format (max file
                                        size 1MB)
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId={2}>
                          {activeTabVartical === 2 && (
                            <>
                              <h3 className="fw-bold mb-3">
                                {personlInfo
                                  ? "Personal Information"
                                  : corporateInfo
                                  ? "Corporate Information"
                                  : hufInfo
                                  ? "HUF Information"
                                  : ""}
                              </h3>
                              {personlInfo && (
                                <>
                                  <Row>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <AvField
                                          name="res_status"
                                          label="Residential Status"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Select valid input"
                                          value={
                                            selectedUser?.res_status
                                              ? selectedUser?.res_status
                                              : ""
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option disabled value="">
                                            Select an option
                                          </option>
                                          <option value="Resident">
                                            Resident
                                          </option>
                                          <option value="NRI">NRI</option>
                                          <option value="Non-Resident">
                                            Non-Resident
                                          </option>
                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <AvField
                                          name="dob"
                                          label="Date of Birth"
                                          type="date"
                                          errorMessage="Select valid input"
                                          value={dob ? dob : ""}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {/* <Col md="6">
                                      <div className="mb-3">
                                        <Label htmlFor="address">Address</Label>
                                        <div className="input-group">
                                          <AvInput
                                            name="address"
                                            id="address"
                                            type="textArea"
                                            value={address ? address : ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={e =>
                                              setAddress(e.target.value)
                                            }
                                            disabled={
                                              !editAddress ||
                                              selectedUser.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          />
                                          <Button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => handleEditAddress()}
                                          >
                                            {editAddress ? "Cancel" : "Edit"}
                                          </Button>
                                        </div>
                                      </div>
                                    </Col> */}
                                    <>{addressFields}</>
                                    {(editAddress || addressFile) && (
                                      <Col>
                                        <div className="mb-3 mt-2">
                                          {loading && <Loader />}
                                          <Modal
                                            isOpen={addressModal}
                                            toggle={() => {
                                              setAddressModal(!addressModal)
                                            }}
                                          >
                                            <ModalHeader
                                              toggle={() => {
                                                setAddressModal(!addressModal)
                                              }}
                                              tag="h3"
                                            >
                                              Document List for Address Proof
                                            </ModalHeader>
                                            <ModalBody className="text-muted">
                                              <p> a) Aadhar Card </p>
                                              <p>b) Passport Copy</p>
                                              <p>c) Driving License</p>
                                              <p> d) Voter ID </p>
                                              <p>
                                                e) Electricity or Telephone Bill
                                              </p>
                                              <p>
                                                f) Last three months Bank
                                                Account Statement
                                              </p>
                                            </ModalBody>
                                          </Modal>
                                          <Label>
                                            Upload Address Proof{" "}
                                            <i
                                              className="fas fa-info-circle"
                                              id="TooltipTop"
                                              onClick={() =>
                                                setAddressModal(true)
                                              }
                                            ></i>
                                          </Label>
                                          <AvField
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            errorMessage="Please Enter valid input"
                                            validate={{
                                              required: {
                                                value:
                                                  isAddressUploadReq &&
                                                  !addressFile
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            onChange={handleAddressUpload}
                                            name="addressFile"
                                            disabled={
                                              !editAddress ||
                                              selectedUser.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          ></AvField>
                                          <p className="mt-3">
                                            {addressFile
                                              ? `File uploaded: ${addressFile
                                                  .split("/")
                                                  .pop()}`
                                              : null}
                                          </p>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row>
                                    {(isAddressUploadReq || addressFile) && (
                                      <Col>
                                        <div className="mb-3">
                                          <AvField
                                            name="addressConsent"
                                            label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                            type="checkbox"
                                            errorMessage="Please check this"
                                            value={addressConsent || false}
                                            disabled={
                                              selectedUser.kyc_status ==
                                              "VERIFIED"
                                                ? true
                                                : false
                                            }
                                            validate={{
                                              required: {
                                                value:
                                                  isAddressUploadReq ||
                                                  addressFile
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            onChange={e =>
                                              setAddressConsent(
                                                e.target.checked
                                              )
                                            }
                                          ></AvField>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </>
                              )}
                              {corporateInfo && (
                                <>
                                  <Row>
                                    <Col md="6">
                                      {" "}
                                      <div className="mb-3">
                                        {loading && <Loader />}
                                        <Modal
                                          isOpen={mcrModal}
                                          toggle={() => {
                                            setMcrModal(!mcrModal)
                                          }}
                                        >
                                          <ModalHeader
                                            toggle={() => {
                                              setMcrModal(!mcrModal)
                                            }}
                                            tag="h3"
                                          >
                                            How to get Company Master Data
                                          </ModalHeader>
                                          <ModalBody className="text-muted">
                                            <p>
                                              Visit:
                                              <br />
                                              <a
                                                href="https://www.mca.gov.in/mcafoportal/viewCompanyMasterData.do"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                https://www.mca.gov.in/mcafoportal/viewCompanyMasterData.do
                                              </a>
                                              <br />
                                              and enter your Company / LLP Name
                                              and Company and
                                              CIN/FCRN/LLPIN/FLLPIN
                                            </p>
                                          </ModalBody>
                                        </Modal>
                                        <Label>
                                          Upload Company Master Data{" "}
                                          <i
                                            className="fas fa-info-circle"
                                            id="TooltipTop"
                                            onClick={() => setMcrModal(true)}
                                          ></i>
                                        </Label>
                                        <AvField
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          validate={{
                                            required: {
                                              value: mcrFile ? false : true,
                                            },
                                          }}
                                          onChange={e =>
                                            setMcrDoc(e.target.files[0])
                                          }
                                          name="mcrFile"
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                        <p className="mt-3">
                                          {mcrFile
                                            ? `File uploaded: ${mcrFile
                                                .split("/")
                                                .pop()}`
                                            : null}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        {loading && <Loader />}
                                        <AvField
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          validate={{
                                            required: {
                                              value: brFile ? false : true,
                                            },
                                          }}
                                          onChange={e =>
                                            setBrDoc(e.target.files[0])
                                          }
                                          name="brFile"
                                          label="Upload Board Resolution"
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                        <p className="mt-3">
                                          {brFile
                                            ? `File uploaded: ${brFile
                                                .split("/")
                                                .pop()}`
                                            : null}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                    <Col>
                                      <div className="mb-3">
                                        <Label htmlFor="address">Address</Label>
                                        <div className="input-group">
                                          <AvInput
                                            name="address"
                                            id="address"
                                            type="textArea"
                                            value={address ? address : ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={e =>
                                              setAddress(e.target.value)
                                            }
                                            disabled={
                                              !editAddress ||
                                              selectedUser.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          />
                                          <Button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => handleEditAddress()}
                                          >
                                            {editAddress ? "Cancel" : "Edit"}
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row> */}
                                  <Row>{addressFields}</Row>

                                  <Row>
                                    <Col>
                                      <div className="mb-3 mt-2">
                                        <AvField
                                          name="isAuthorisedName"
                                          label="Is the authorised person as per the Board Resolution same as in Company Master Data."
                                          type="checkbox"
                                          errorMessage="Please check this"
                                          value={isAuthorisedName || false}
                                          onChange={e =>
                                            setIsAuthorisedName(
                                              e.target.checked
                                            )
                                          }
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  {!isAuthorisedName && (
                                    <>
                                      <Row>
                                        <Col md="6">
                                          <div className="mb-3">
                                            <AvField
                                              name="authorisedName"
                                              label="Authorised Person Name"
                                              type="text"
                                              errorMessage="Please Enter valid input"
                                              value={
                                                authorisedName
                                                  ? authorisedName
                                                  : ""
                                              }
                                              validate={{
                                                required: {
                                                  value: !isAuthorisedName
                                                    ? true
                                                    : false,
                                                },
                                              }}
                                              onChange={e =>
                                                setAuthorisedName(
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                selectedUser.kyc_status ==
                                                "VERIFIED"
                                                  ? true
                                                  : false
                                              }
                                            ></AvField>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md="6">
                                          <div className="mb-3">
                                            {loading && <Loader />}
                                            <Modal
                                              isOpen={addressModal}
                                              toggle={() => {
                                                setAddressModal(!addressModal)
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setAddressModal(!addressModal)
                                                }}
                                                tag="h3"
                                              >
                                                Document List for Address Proof
                                              </ModalHeader>
                                              <ModalBody className="text-muted">
                                                <p> a) Aadhar Card </p>
                                                <p>b) Passport Copy</p>
                                                <p>c) Driving License</p>
                                                <p> d) Voter ID </p>
                                                <p>
                                                  e) Electricity or Telephone
                                                  Bill
                                                </p>
                                                <p>
                                                  f) Last three months Bank
                                                  Account Statement
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              Authorised Person Address Proof{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() =>
                                                  setAddressModal(true)
                                                }
                                              ></i>
                                            </Label>
                                            <AvField
                                              className="form-control"
                                              type="file"
                                              id="formFile"
                                              errorMessage="Please Enter valid input"
                                              validate={{
                                                required: {
                                                  value: !isAuthorisedName
                                                    ? authAadharFile
                                                      ? false
                                                      : true
                                                    : false,
                                                },
                                              }}
                                              onChange={e =>
                                                setAuthAadharDoc(
                                                  e.target.files[0]
                                                )
                                              }
                                              name="authAddressProof"
                                              disabled={
                                                selectedUser.kyc_status ==
                                                "VERIFIED"
                                                  ? true
                                                  : false
                                              }
                                            ></AvField>
                                            <p className="mt-3">
                                              {authAadharFile
                                                ? `File uploaded: ${authAadharFile
                                                    .split("/")
                                                    .pop()}`
                                                : null}
                                            </p>
                                          </div>
                                        </Col>

                                        <Col md="6">
                                          <div className="mb-3">
                                            {loading && <Loader />}
                                            <AvField
                                              className="form-control"
                                              type="file"
                                              id="formFile"
                                              errorMessage="Please Enter valid input"
                                              validate={{
                                                required: {
                                                  value: !isAuthorisedName
                                                    ? authPanFile
                                                      ? false
                                                      : true
                                                    : false,
                                                },
                                              }}
                                              onChange={e =>
                                                setAuthPanDoc(e.target.files[0])
                                              }
                                              name="authPanCard"
                                              label="Authorised Person PAN
                                                        Card"
                                              disabled={
                                                selectedUser.kyc_status ==
                                                "VERIFIED"
                                                  ? true
                                                  : false
                                              }
                                            ></AvField>
                                            <p className="mt-3">
                                              {authPanFile
                                                ? `File uploaded: ${authPanFile
                                                    .split("/")
                                                    .pop()}`
                                                : null}
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {!isAuthorisedName && (
                                            <div className="mb-3 mt-2">
                                              <AvField
                                                name="addressConsent"
                                                label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                                type="checkbox"
                                                errorMessage="Please check this"
                                                value={addressConsent || false}
                                                validate={{
                                                  required: {
                                                    value: !isAuthorisedName
                                                      ? true
                                                      : false,
                                                  },
                                                }}
                                                onChange={e =>
                                                  setAddressConsent(
                                                    e.target.checked
                                                  )
                                                }
                                                disabled={
                                                  selectedUser.kyc_status ==
                                                  "VERIFIED"
                                                    ? true
                                                    : false
                                                }
                                              ></AvField>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </>
                              )}
                              {hufInfo && (
                                <>
                                  <Row>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <AvField
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                          name="kartaPanValue"
                                          label="Enter Karta's PAN"
                                          type="text"
                                          errorMessage="Enter a valid input"
                                          value={kartaPanValue || ""}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          maxLength="10"
                                          onChange={handleKartaPanValue}
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                        <p style={{ color: "red" }}>
                                          {errorDetail &&
                                            "Please enter valid input"}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        {loading && <Loader />}
                                        <AvField
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          validate={{
                                            required: {
                                              value: kartaPanFile
                                                ? false
                                                : true,
                                            },
                                          }}
                                          onChange={handleKartaPanUpload}
                                          name="kartaPanFile"
                                          label="Upload Karta PAN"
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                        <p className="mt-3">
                                          {kartaPanFile
                                            ? `File uploaded: ${kartaPanFile
                                                .split("/")
                                                .pop()}`
                                            : ""}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {/* <Col md="6">
                                      <div className="mb-3">
                                        <Label htmlFor="address">Address</Label>
                                        <div className="input-group">
                                          <AvInput
                                            name="address"
                                            id="address"
                                            type="textArea"
                                            errorMessage="Please Enter valid input"
                                            value={address ? address : ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={e =>
                                              setAddress(e.target.value)
                                            }
                                            disabled={
                                              !editAddress ||
                                              selectedUser.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          />
                                          <Button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => handleEditAddress()}
                                          >
                                            {editAddress ? "Cancel" : "Edit"}
                                          </Button>
                                        </div>
                                      </div>
                                    </Col> */}
                                    <>{addressFields}</>
                                    {(editAddress || addressFile) && (
                                      <Col>
                                        <div className="mb-3">
                                          {loading && <Loader />}
                                          <Modal
                                            isOpen={addressModal}
                                            toggle={() => {
                                              setAddressModal(!addressModal)
                                            }}
                                          >
                                            <ModalHeader
                                              toggle={() => {
                                                setAddressModal(!addressModal)
                                              }}
                                              tag="h3"
                                            >
                                              Document List for Address Proof
                                            </ModalHeader>
                                            <ModalBody className="text-muted">
                                              <p> a) Aadhar Card </p>
                                              <p>b) Passport Copy</p>
                                              <p>c) Driving License</p>
                                              <p> d) Voter ID </p>
                                              <p>
                                                e) Electricity or Telephone Bill
                                              </p>
                                              <p>
                                                f) Last three months Bank
                                                Account Statement
                                              </p>
                                            </ModalBody>
                                          </Modal>
                                          <Label>
                                            Upload Address Proof{" "}
                                            <i
                                              className="fas fa-info-circle"
                                              id="TooltipTop"
                                              onClick={() =>
                                                setAddressModal(true)
                                              }
                                            ></i>
                                          </Label>
                                          <AvField
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            errorMessage="Please Enter valid input"
                                            validate={{
                                              required: {
                                                value:
                                                  isAddressUploadReq &&
                                                  !addressFile
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            onChange={handleAddressUpload}
                                            name="addressFile"
                                            disabled={
                                              !editAddress ||
                                              selectedUser.kyc_status ==
                                                "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          ></AvField>
                                          <p className="mt-3">
                                            {addressFile
                                              ? `File uploaded: ${addressFile
                                                  .split("/")
                                                  .pop()}`
                                              : null}
                                          </p>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="mb-3">
                                        <AvField
                                          name="isAuthorisedName"
                                          label="Is Karta Name same as HUF Name"
                                          type="checkbox"
                                          errorMessage="Please check this"
                                          value={isKartaNameSame || false}
                                          onChange={e =>
                                            setKartaNameSame(e.target.checked)
                                          }
                                          disabled={
                                            selectedUser.kyc_status ==
                                            "VERIFIED"
                                              ? true
                                              : false
                                          }
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="6">
                                      {!isKartaNameSame && (
                                        <div className="mb-3">
                                          {loading && <Loader />}
                                          {
                                            <Modal
                                              isOpen={kartaVerifyModal}
                                              toggle={() => {
                                                setKartaVerifyModal(
                                                  !kartaVerifyModal
                                                )
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setKartaVerifyModal(
                                                    !kartaVerifyModal
                                                  )
                                                }}
                                                tag="h3"
                                              >
                                                About Karta Verification
                                                Document
                                              </ModalHeader>
                                              <ModalBody className="text-muted">
                                                <p>
                                                  In a situation where HUF name
                                                  is different from Karta name,
                                                  we need a document to verify
                                                  the Karta for the HUF. The
                                                  options are:{" "}
                                                </p>
                                                <p>
                                                  a. Bank&apos;s Signature
                                                  Verification letter
                                                </p>
                                                <p>
                                                  b. Income Tax Return
                                                  Acknowledgment Copy
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                          }
                                          <Label>
                                            Karta Verification Document{" "}
                                            <i
                                              className="fas fa-info-circle"
                                              id="TooltipTop"
                                              onClick={() =>
                                                setKartaVerifyModal(true)
                                              }
                                            ></i>
                                          </Label>
                                          <AvField
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            errorMessage="Please Enter valid input"
                                            validate={{
                                              required: {
                                                value: !isKartaNameSame
                                                  ? kartaVerfiFile
                                                    ? false
                                                    : true
                                                  : false,
                                              },
                                            }}
                                            onChange={e =>
                                              setKartaVerfiDoc(
                                                e.target.files[0]
                                              )
                                            }
                                            name="kartVerifyDoc"
                                            disabled={
                                              selectedUser.kyc_status ==
                                              "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          ></AvField>
                                          <p className="mt-3">
                                            {kartaVerfiFile
                                              ? `File uploaded: ${kartaVerfiFile
                                                  .split("/")
                                                  .pop()}`
                                              : null}
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {(!isKartaNameSame ||
                                        isAddressUploadReq ||
                                        addressFile) && (
                                        <div className="mb-3">
                                          <AvField
                                            name="addressConsent"
                                            label="I hereby voluntarily give my consent to Certus Investment Management Pvt. Ltd. and its affiliates to use the documents uploaded above as my address proof for KYC purposes only. I also confirm that these documents belong to me."
                                            type="checkbox"
                                            errorMessage="Please check this"
                                            value={addressConsent || false}
                                            validate={{
                                              required: {
                                                value:
                                                  !isKartaNameSame ||
                                                  isAddressUploadReq ||
                                                  addressFile
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            onChange={e =>
                                              setAddressConsent(
                                                e.target.checked
                                              )
                                            }
                                            disabled={
                                              selectedUser.kyc_status ==
                                              "VERIFIED"
                                                ? true
                                                : false
                                            }
                                          ></AvField>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId={3}>
                          {activeTabVartical === 3 && (
                            <>
                              <h3 className="fw-bold mb-3">Demat Details</h3>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Modal
                                      isOpen={dematModal}
                                      toggle={() => {
                                        setDematModal(!dematModal)
                                      }}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          setDematModal(!dematModal)
                                        }}
                                        tag="h3"
                                      >
                                        About Demat Account
                                      </ModalHeader>
                                      <ModalBody className="text-muted">
                                        <h4 className="mt-2">
                                          Why do we need your Demat Account
                                          details?
                                        </h4>
                                        <p>
                                          After you invest through the Earnnest
                                          platform, we transfer units of the
                                          underlying instrument to your demat
                                          account. This transfer of units
                                          requires your demat account details.
                                        </p>
                                        <h4 className="mt-2">
                                          What is a Demat Account Number?
                                        </h4>
                                        <p>
                                          A Demat account number (also known as
                                          BO ID [Beneficiary Owner]) is a
                                          16-digit unique number assigned by the
                                          Depository Participant to the Demat
                                          holder in order to carry out trade
                                          through electronic mode.
                                        </p>
                                        <p>
                                          Currently, there are two Depository
                                          Participants operational in India,
                                          namely National Securities Depository
                                          Limited (NSDL) and Central Depository
                                          Services Limited (CDSL)
                                        </p>
                                        <h4 className="mt-2">
                                          How to find your Demat Account Number?
                                        </h4>
                                        <p>
                                          You would usually find the Demat
                                          Account Number under the ‘Profile’
                                          section of your broker account&apos;s
                                          portal.
                                        </p>
                                        <p>
                                          Demat accounts held with CDSL have 16
                                          numeric digits in them and accounts
                                          held with NSDL have two alpha numeric
                                          digits ‘IN’ followed by 14 numeric
                                          digits
                                        </p>
                                        <p>
                                          {" "}
                                          Examples of Demat IDs with few
                                          brokers:{" "}
                                        </p>
                                        <p>
                                          <ul>
                                            <li>
                                              With traditional broker houses you
                                              may find the DP ID (8 digits) &
                                              Client ID (8 digits). Your Demat
                                              ID will then be the DP ID followed
                                              by Client ID. E.g.
                                              IN300757XXXXXXXX{" "}
                                            </li>
                                            <li>Zerodha: 12081600XXXXXXXX </li>
                                            <li>Upstox: 12081800XXXXXXXX</li>
                                          </ul>
                                        </p>
                                      </ModalBody>
                                    </Modal>
                                    <Label>
                                      DEMAT ACCOUNT NUMBER{" "}
                                      <i
                                        className="fas fa-question-circle"
                                        id="TooltipTop"
                                        onClick={() => setDematModal(true)}
                                      ></i>
                                    </Label>
                                    <AvField
                                      name="demat"
                                      type="text"
                                      errorMessage="Please Enter valid input"
                                      value={
                                        selectedUser?.user_demat?.demat_id
                                          ? selectedUser?.user_demat?.demat_id
                                          : ""
                                      }
                                      validate={{
                                        required: { value: true },
                                      }}
                                      disabled={
                                        selectedUser.kyc_status == "VERIFIED"
                                          ? true
                                          : false
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    {loading && <Loader />}
                                    <AvField
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                      name="dematFile"
                                      label="Upload Document"
                                      errorMessage="Please Enter valid input"
                                      validate={{
                                        required: {
                                          value: dematFile ? false : true,
                                        },
                                      }}
                                      onChange={handleDematUpload}
                                      disabled={
                                        selectedUser.kyc_status == "VERIFIED"
                                          ? true
                                          : false
                                      }
                                    ></AvField>
                                    <p className="mt-3">
                                      {dematFile
                                        ? `File uploaded: ${dematFile
                                            .split("/")
                                            .pop()}`
                                        : null}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p className="mt-3">
                                    Kindly upload any one of the below copies in
                                    pdf/jpg/png format (max file size 5MB)
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p className="mt-3">
                                    Screenshot of your{" "}
                                    <strong>Demat profile</strong> OR{" "}
                                    <strong>Demat CMR copy</strong> (Client
                                    Master Report) either of which contain basic
                                    Demat details like Name, PAN, Bank account
                                    details, Demat ID, DP details etc.
                                  </p>
                                </Col>
                              </Row>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId={4}>
                          {activeTabVartical === 4 && (
                            <>
                              <h3 className="fw-bold mb-3">
                                Bank Account Details
                              </h3>
                              <>
                                <Row>
                                  <Col md="6">
                                    <div className="mb-3">
                                      {loading && <Loader />}
                                      <AvField
                                        name="ifsc"
                                        label="IFSC Code"
                                        type="text"
                                        errorMessage="Please Enter valid input"
                                        value={
                                          selectedUser?.user_bank_account
                                            ?.ifsc_code
                                            ? selectedUser?.user_bank_account
                                                ?.ifsc_code
                                            : ""
                                        }
                                        validate={{
                                          required: { value: true },
                                        }}
                                        disabled={
                                          selectedUser.kyc_status == "VERIFIED"
                                            ? true
                                            : false
                                        }
                                      ></AvField>
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="account_number"
                                        label="Account Number"
                                        type="text"
                                        errorMessage="Please Enter valid input"
                                        value={
                                          selectedUser?.user_bank_account
                                            ?.account_number
                                            ? selectedUser?.user_bank_account
                                                ?.account_number
                                            : ""
                                        }
                                        validate={{
                                          required: { value: true },
                                        }}
                                        disabled={
                                          selectedUser.kyc_status == "VERIFIED"
                                            ? true
                                            : false
                                        }
                                      ></AvField>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="account_name"
                                        label="Account Name (Autofilled on pressing Save)"
                                        type="text"
                                        disabled
                                        value={accountName || ""}
                                      ></AvField>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId={5}>
                          {activeTabVartical === 5 && (
                            <>
                              <h3 className="fw-bold mb-3">Submit KYC</h3>
                              <p>
                                Please review all the details you have provided
                                before you submit
                              </p>
                              <Col>
                                <div className="mb-3">
                                  <AvField
                                    name="submitConsent"
                                    label="I acknowledge all information filled is correct and up to date."
                                    type="checkbox"
                                    errorMessage="Please accept the terms"
                                    value={submitConsent || false}
                                    disabled={
                                      selectedUser.kyc_status == "VERIFIED"
                                        ? true
                                        : false
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                    }}
                                    onChange={e =>
                                      setSubmitConsent(e.target.checked)
                                    }
                                  ></AvField>
                                </div>
                              </Col>
                            </>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix d-flex justify-content-end mt-3">
                      <div className="text-start me-3">
                        <button
                          type="button"
                          className="btn btn-success save-user"
                          disabled={
                            loading || buttonLoading || activeTabVartical === 1
                              ? true
                              : false
                          }
                          onClick={prevKycStep}
                        >
                          PREVIOUS
                        </button>
                      </div>
                      {activeTabVartical !== 5 &&
                        selectedUser.kyc_status != "VERIFIED" && (
                          <div
                            className={
                              !activeTabVartical === 1
                                ? "text-end"
                                : "text-center"
                            }
                          >
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              disabled={
                                loading ||
                                buttonLoading ||
                                selectedUser.kyc_status == "VERIFIED"
                                  ? true
                                  : false
                              }
                            >
                              {buttonLoading ? (
                                <Spinner size="sm" color="light" />
                              ) : (
                                "SAVE & CONTINUE"
                              )}
                            </button>
                          </div>
                        )}
                      {activeTabVartical === 5 &&
                        selectedUser.kyc_status != "VERIFIED" && (
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-success save-user"
                              onClick={finalSubmit}
                              disabled={
                                loading ||
                                !isValidKycData() ||
                                selectedUser.kyc_status == "VERIFIED" ||
                                !submitConsent
                                  ? true
                                  : false
                              }
                            >
                              SUBMIT
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DistributorUserKyc

DistributorUserKyc.propTypes = {
  onCloseClick: PropTypes.func,
  user: PropTypes.object,
}
