import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"

const Reports = () => {
  const accessRestriction = DealManagementAccess(AccessId?.REPORTS)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"

  const options = [
    {
      id: 1,
      name: "Investment Summary",
      links: [
        {
          id: 1,
          name: "Completed Investments",
          route: "/reports/completed-investments",
          viewAccess:
            isDist ||
            accessRestriction >= 1 ||
            accessRestriction == "SuperAdmin",
        },
        {
          id: 2,
          name: "Investor Count Summary",
          route: "/reports/investor-count-summary",
          viewAccess:
            isDist ||
            accessRestriction >= 1 ||
            accessRestriction == "SuperAdmin",
        },
      ],
    },
    {
      id: 2,
      name: "Transactions",
      links: [
        {
          id: 1,
          name: "Investment Transactions",
          route: "/reports/investment-transactions",
          viewAccess:
            isDist ||
            accessRestriction >= 1 ||
            accessRestriction == "SuperAdmin",
        },
        {
          id: 2,
          name: "Interest & Repayments",
          route: "/reports/interest-and-repayments",
          viewAccess:
            isDist ||
            accessRestriction >= 1 ||
            accessRestriction == "SuperAdmin",
        },
      ],
    },
  ]

  const filteredOptions = options.map(subOptions => ({
    ...subOptions,
    links: subOptions.links.filter(obj => obj.viewAccess),
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Reports" />
          <Row>
            {filteredOptions.map(option => (
              <>
                {option.links.length ? (
                  <Col key={option.id} xl={3} md={6}>
                    <div className="card jobs-categories h-100">
                      <div className="card-body">
                        <h4 className="card-title mb-3">{option.name}</h4>
                        {option.links.map(item => (
                          <Link
                            key={item.id}
                            to={item.route}
                            className="px-2 py-2 rounded d-block"
                          >
                            <i className="mdi mdi-chevron-right"></i>
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </Col>
                ) : null}
              </>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Reports)
