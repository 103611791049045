import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import {
  Pagination,
  csvDownloadData,
  getAuthorizedSignatory,
  handleValidDate,
  humanize,
  distributorInvData,
  picUrl,
  usersData,
} from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"
import ReactSelect from "constants/ReactSelect"
import { sortingByAlphabet } from "constants/sort"
import { AccessId, stfStatus } from "constants/ConstantFields"

const RmStfStatus = props => {
  const [loading, setLoading] = useState(false)

  const [stfDocumentsData, setStfDocumentsData] = useState([])

  const [investmentList, setInvestmentList] = useState([])
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({})

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const masterData = async () => {
    setLoading(true)

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
    }${selectedStatus?.id ? `&status=${selectedStatus?.id}` : ""}`

    try {
      const stfResponse = await axiosInstance.get(
        `stf-document?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}&relManagerId=${localUser.id}`
      )

      if (stfResponse) {
        setStfDocumentsData(stfResponse?.data?.data)
        setTotal(stfResponse?.data?.total)
        let pages = Math.ceil(
          (stfResponse.data?.total || stfResponse.total) /
            (stfResponse.data?.limit || stfResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      const invResponse = await distributorInvData()
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "stfStatus":
        setSelectedStatus(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, selectedStatus, investmentFilter])

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const stfDocumentColumns = (download = false) => {
    const cols = [
      {
        dataField: "user_investment_topup.investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.user_name",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: "user_investment_topup.lot_size",
        text: "Bonds",
        sort: true,
      },
      {
        dataField: "user_investment_topup.amount",
        text: "Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(Math.floor(row.user_investment_topup.amount)),
      },
      {
        dataField: "user_investment_topup.created_at",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          handleValidDate(order.user_investment_topup.created_at),
      },
      // {
      //   dataField: "sl_no",
      //   text: "Serial Number",
      //   sort: true,
      // },
      {
        dataField: "status",
        text: "STF Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => humanize(order.status),
      },
      // {
      //   dataField: "unsigned_doc_url",
      //   text: "Unsigned Doc",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) =>
      //     order?.unsigned_doc_url ? (
      //       <>
      //         <div className="d-flex gap-3">
      //           <Link
      //             to="#"
      //             className="text-primary"
      //             onClick={() => window.open(picUrl + order?.unsigned_doc_url)}
      //           >
      //             <i className="fa fa-file-pdf font-size-24"></i>
      //           </Link>
      //         </div>
      //       </>
      //     ) : (
      //       <>NA</>
      //     ),
      // },
      // {
      //   dataField: "signed_doc_url",
      //   text: "Signed Doc",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) =>
      //     order?.signed_doc_url ? (
      //       <>
      //         <div className="d-flex gap-3">
      //           <Link
      //             to="#"
      //             className="text-primary"
      //             onClick={() => handleDoc(order?.signed_doc_url)}
      //           >
      //             <i className="fa fa-file-signature font-size-24"></i>
      //           </Link>
      //         </div>
      //       </>
      //     ) : (
      //       <>NA</>
      //     ),
      // },
      {
        dataField: "",
        text: "Investor E-sign Link",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            (accessRestriction >= 3 || accessRestriction == "SuperAdmin") &&
            row.signing_request_data?.id &&
            (row.webhook_data?.payload?.document?.signing_parties?.length ||
              row.signing_request_data?.signing_parties?.length)
          ) {
            return (
              <a
                href={`${process.env.REACT_APP_DIGIO_BASE_URL}/${row.signing_request_data.id}/earnnest/${row?.user_investment_topup?.user?.email}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
              >
                <i className="mdi mdi-link" style={{ fontSize: "28px" }}></i>
              </a>
            )
          } else {
            return "NA"
          }
        },
      },
      {
        dataField: download ? "signer1" : "",
        text: "Signer 1",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            !row.webhook_data &&
            row.signing_request_data?.signing_parties?.length
          ) {
            return row.signing_request_data?.signing_parties[0].name
          }
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties[0].name
          }
          return ""
        },
      },
      {
        dataField: download ? "signer1Status" : "",
        text: "Signer 1 status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            !row.webhook_data &&
            row.signing_request_data?.signing_parties?.length
          ) {
            return humanize(row.signing_request_data?.signing_parties[0].status)
          }
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return humanize(
              row.webhook_data?.payload.document.signing_parties[0].status
            )
          }
          return ""
        },
      },
      {
        dataField: download ? "signer2" : "",
        text: "Signer 2",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            !row.webhook_data &&
            row.signing_request_data?.signing_parties?.length
          ) {
            return row.signing_request_data?.signing_parties[1].name
          }
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties[1].name
          }
          return ""
        },
      },
      {
        dataField: download ? "signer2Status" : "",
        text: "Signer 2 status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            !row.webhook_data &&
            row.signing_request_data?.signing_parties?.length
          ) {
            return humanize(row.signing_request_data?.signing_parties[1].status)
          }
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return humanize(
              row.webhook_data?.payload.document.signing_parties[1].status
            )
          }
          return ""
        },
      },
      // {
      //   dataField: download
      //     ? "lastSignedBy"
      //     : "webhook_data.payload.document.others.last_signed_by",
      //   text: "Last Signed By",
      //   sort: true,
      // },
      // {
      //   dataField: download ? "lastSignedOn" : "",
      //   text: "Last Signed On",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, row) =>
      //     row.webhook_data
      //       ? moment(row.webhook_data?.created_at).format("DD MMM Y hh:mm a")
      //       : null,
      // },
      // {
      //   dataField: "execution_date",
      //   text: "Execution Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) =>
      //     handleValidDate(order.execution_date),
      // },
      // {
      //   dataField: "trade_date",
      //   text: "Trade Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) => handleValidDate(order.trade_date),
      // },
      // {
      //   dataField: "transferor.transferor_name",
      //   text: "Transferor Name",
      //   sort: true,
      // },
      // {
      //   dataField: "transferee_designation",
      //   text: "Transferee Designation",
      //   sort: true,
      // },
      // {
      //   dataField: "guardian_name",
      //   text: "Guardian Name",
      //   sort: true,
      // },
      {
        dataField: "settlement_days",
        text: "Settlement Days",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.email",
        text: "Investor Email",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.phone",
        text: "Investor Phone",
        sort: true,
      },
      {
        dataField: download
          ? "panName"
          : "user_investment_topup.user.user_pan.name",
        text: "Name as per PAN",
        sort: true,
      },
      // {
      //   dataField: download
      //     ? "panNumber"
      //     : "user_investment_topup.user.user_pan.pan_number",
      //   text: "Investor PAN",
      //   sort: true,
      // },
      // {
      //   dataField: download
      //     ? "dematId"
      //     : "user_investment_topup.user.user_demat.demat_id",
      //   text: "Investor Demat Id",
      //   sort: true,
      // },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => handleValidDate(order.created_at),
      },
    ]
    return cols
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(
    AccessId?.SALES_INVESTOR_SERVICING
  )

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const downloadData = async fileName => {
    try {
      setLoading(true)

      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        selectedStatus?.id ? `&status=${selectedStatus?.id}` : ""
      }`

      const res = await axiosInstance.get(
        `stf-document?$sort[created_at]=-1${filterUrl}&relManagerId=${localUser.id}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = stfDocumentColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item.user_investment_topup.amount = handleAmount(
            Math.floor(item.user_investment_topup.amount)
          )

          item.user_investment_topup.created_at = handleValidDate(
            item.user_investment_topup.created_at
          )

          item.status = humanize(item.status)

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.signer1 = item.signing_request_data?.signing_parties[0].name
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.signer1 =
              item.webhook_data?.payload?.document?.signing_parties[0].name
          } else {
            item.signer1 = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.signer1Status = humanize(
              item.signing_request_data?.signing_parties[0].status
            )
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.signer1Status = humanize(
              item.webhook_data?.payload?.document?.signing_parties[0].status
            )
          } else {
            item.signer1Status = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.signer2 = item.signing_request_data?.signing_parties[1].name
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.signer2 =
              item.webhook_data?.payload?.document?.signing_parties[1].name
          } else {
            item.signer2 = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.signer2Status = humanize(
              item.signing_request_data?.signing_parties[1].status
            )
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.signer2Status = humanize(
              item.webhook_data?.payload?.document?.signing_parties[1].status
            )
          } else {
            item.signer2Status = ""
          }

          item.lastSignedBy =
            item.webhook_data?.payload?.document?.others?.last_signed_by

          item.lastSignedOn = item.webhook_data
            ? moment(item.webhook_data?.created_at).format("DD MMM Y hh:mm a")
            : null

          item.execution_date = handleValidDate(item.execution_date)

          item.trade_date = handleValidDate(item.trade_date)

          item.panName = item.user_investment_topup?.user?.user_pan?.name

          item.panNumber =
            item.user_investment_topup?.user?.user_pan?.pan_number

          item.dematId = item.user_investment_topup?.user?.user_demat?.demat_id

          item.created_at = handleValidDate(item.created_at)
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="STF Status" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                {/* <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={() => downloadData("STF Status")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div> */}
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={stfDocumentsData}
                    columns={stfDocumentColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>STF Status</label>
                              <ReactSelect
                                users={stfStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "stfStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={stfDocumentColumns()}
                              data={stfDocumentsData}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RmStfStatus.propTypes = {
  stfDocumentsData: PropTypes.array,
}

export default withRouter(RmStfStatus)
