import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessId } from "constants/ConstantFields"
import { Pagination } from "../../constants/common"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"

const PublishingApprovalQueue = props => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)
  const [Status, setStatus] = useState("ALL")
  const [approvalInput, setApprovalInput] = useState("")
  const userInfo = localStorage.getItem("userInfo")
  const parsedUserInfo = JSON.parse(userInfo)

  const approvalLevel =
    parsedUserInfo?.investment_publishing_workflows[0]?.approval_level || 0
  const userId = parsedUserInfo?.id

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    let response
    try {
      if (approvalLevel > 0) {
        response = await axiosInstance.get(
          Status == "FOR APPROVAL"
            ? `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&creation_status=Approved&publishing_level=${approvalLevel}&publishing_status=Pending&investment_status=Sent_for_Approval`
            : Status == "UPCOMING"
            ? `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&creation_status=Approved&publishing_level[$lt]=${approvalLevel}&publishing_status=Pending&investment_status=Sent_for_Approval`
            : Status == "APPROVED"
            ? `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&creation_status=Approved&$or[0][publishing_level][$gt]=${approvalLevel}&$or[1][publishing_status]=Approved&investment_status=Sent_for_Approval`
            : `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&creation_status=Approved&investment_status=Sent_for_Approval`
        )
      } else {
        response = await axiosInstance.get(
          `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&creation_status=Approved&publishing_level=${approvalLevel}&publishing_status=Pending&investment_status=Sent_for_Approval`
        )
      }

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip, Status])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
  const PublishingApprovalColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link to="#" className="text-success">
                <Button
                  style={{
                    backgroundColor: "#556ee6",
                  }}
                  disabled={
                    order.publishing_status == "Approved" ||
                    order.publishing_level != approvalLevel
                      ? true
                      : false
                  }
                  onClick={() => handleOrderClick(order)}
                >
                  Update
                </Button>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "user",
      text: "Created By",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleDepartment(row.user),
    },

    {
      dataField: "investment_request_name",
      text: "Creation Request Name",
      sort: true,
    },
    {
      dataField: "investment_type",
      text: "Investment Type",
      sort: true,
    },
    {
      dataField: "investment_amount",
      text: "Investment Amount",
      sort: true,
    },

    {
      dataField: "publishing_level",
      text: "publishing Level",
      sort: true,
    },

    {
      dataField: "publishing_status",
      text: "Publishing Status",
      sort: true,
    },

    {
      dataField: "investment_desc",
      text: "Investment Description",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleDepartment = overview => {
    const overviewSorted = overview?.user_name
    return overviewSorted
  }
  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setApprovalInput("")

    setOrderList({
      id: order.id,
    })

    setIsEdit(true)

    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (isEdit) {
      const updateOrder = {
        userId: userId,
        investmentCreationRequestId: orderList.id,
        approval_level: approvalLevel,
        status: approvalInput == "Reject" ? "Reject" : "Approved",
        comment: values.comment,
      }

      // update order

      try {
        const response = await axiosInstance.post(
          `investment-publishing-workflow-tracking`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Updated")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Publishing Approval Queue" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={PublishingApprovalColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between align-items-center">
                          <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 d-flex justify-content-end">
                            <h3
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                marginTop: "10px",
                              }}
                            >
                              Filter By Status
                            </h3>
                            <select
                              className="form-select"
                              label="Filter By Status"
                              required=""
                              name="status"
                              style={{
                                display: "inline-block",
                                maxWidth: "200px",
                                margin: "0 10px 10px 10px",
                              }}
                              onChange={e => setStatus(e.target.value)}
                              value={Status}
                            >
                              <option defaultValue="ALL" value="ALL">
                                ALL
                              </option>
                              <option value="UPCOMING">UPCOMING</option>
                              <option value="FOR APPROVAL">FOR APPROVAL</option>
                              <option value="APPROVED">APPROVED</option>
                            </select>
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={PublishingApprovalColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit
                                  ? "Update Publishing Approval Queue"
                                  : "Add Creation Request"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="approval_level"
                                          label="Approval"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid Approval"
                                          onChange={e =>
                                            setApprovalInput(e.target.value)
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.approval_level || ""}
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          <option value="Approve">
                                            Approve
                                          </option>
                                          <option value="Reject">Reject</option>
                                        </AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="comment"
                                          label="Comment"
                                          type="textarea"
                                          errorMessage="Invalid Comment"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.comment || ""}
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PublishingApprovalQueue.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(PublishingApprovalQueue)
