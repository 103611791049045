import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { validateEmail } from "../../constants/common"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { Pagination } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
const SponserPromoter = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `sponsor-promoter?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
  const SpnsorPromoterColumns = () => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction >= 4 || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "sponsor_master",
      text: "Sponsor Name",
      sort: true,
      formatter: (cellContent, row) => handleSponsorName(row.sponsor_master),
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "contact",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "networth",
      text: "Networth",
      sort: true,
    },
    {
      dataField: "networth_date",
      text: "Networth Date",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleSponsorName = overview => {
    const overviewSorted = overview?.sponsor_name
    return overviewSorted
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      sponsor_name: order.sponsorMasterId,
      name: order.name,
      email: order.email,
      contact: order.contact,
      address: order.address,
      networth: order.networth,
      networth_date: order.networth_date,

      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete ${order.name}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `sponsor-promoter/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (!validateEmail(values.email)) {
      toast.error("Please enter valid email id")
      return false
    }
    if (values.contact.length < 9) {
      toast.error("Please enter valid phone number")
      return false
    }
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        sponsorMasterId: values.sponsor_name,
        name: values.name,
        email: values.email,
        contact: values.contact,
        address: values.address,
        networth: values.networth,
        networth_date: values.networth_date,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `sponsor-promoter/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        sponsorMasterId: values["sponsor_name"],
        name: values["name"],
        email: values["email"],
        contact: values["contact"],
        address: values["address"],
        networth: values["networth"],
        networth_date: values["networth_date"],
        created_at: values["created_at"],
      }

      try {
        const response = await axiosInstance.post(`sponsor-promoter`, newOrder)
        if (response) {
          masterData()
          toast.success(" Project Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `sponsor-master?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Sponsor Promoter" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={SpnsorPromoterColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            {(accessRestriction >= 2 ||
                              accessRestriction === "SuperAdmin") && (
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={SpnsorPromoterColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Promoter" : "Add Promoter"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="sponsor_name"
                                          label="Sponsor Master"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid Sponsor Master"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.sponsor_name || ""}
                                          style={{ widht: "100%" }}
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          {sponser
                                            .sort((a, b) =>
                                              a.sponsor_name?.toLowerCase() >
                                              b.sponsor_name?.toLowerCase()
                                                ? 1
                                                : -1
                                            )
                                            .map((item, index) => (
                                              <option
                                                key={item.sponsor_name}
                                                value={item.id}
                                              >
                                                {item.sponsor_name}
                                              </option>
                                            ))}
                                        </AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="name"
                                          label="Name"
                                          type="text"
                                          errorMessage="Invalid Name"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.name || ""}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="email"
                                          label="Email"
                                          type="text"
                                          errorMessage="Invalid Email"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.email || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="contact"
                                          label="Contact"
                                          type="number"
                                          errorMessage="Invalid Contact"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.contact || ""}
                                          style={{ widht: "100%" }}
                                        ></AvField>
                                      </div>

                                      <div className="mb-3">
                                        <AvField
                                          name="address"
                                          label="Address"
                                          type="text"
                                          errorMessage="Invalid Address"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.address || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="networth"
                                          label="Networth"
                                          type="number"
                                          errorMessage="Invalid Networth"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.networth}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="networth_date"
                                          label="Networth Date "
                                          type="date"
                                          errorMessage="Invalid Networth Date"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={orderList.networth_date || ""}
                                        ></AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SponserPromoter.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(SponserPromoter)
